import { createAction } from '@reduxjs/toolkit';

import { customerService } from '../services';

export const createFeedbackTopic = createAction(
  'FEEDBACK/CREATE_FEEDBACK_TOPIC'
);
export const resetFeedbackTopic = createAction('FEEDBACK/RESET_FEEDBACK_TOPIC');
export const submitFeedback = (body) => async (dispatch) => {
  try {
    const result = await customerService.postFeedback(body);
    console.log('feedback sent', result.data);
  } catch (e) {
    console.log(e);
  }
};
