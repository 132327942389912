import { useSelector } from 'react-redux';
import React from 'react';
import { WorkInProgressImage } from '../../assets/images/';
import { PageWithImage } from '../../components';
import {useHistory} from "react-router-dom";

const WorkInProgress = () => {
  const coffeeLover = useSelector((state) => state.orderDetail.customer.name);
    const history = useHistory();

  const footer1Action = () => {
      history.push('/profile');
  };

  return (
    <PageWithImage
      image={WorkInProgressImage}
      title={`Hey ${coffeeLover} we're still working on this.`}
      text={
        "You're currently unable to add additional delivery addresses. If you'd like to get your coffee delivered to a different address, please make an edit."
      }
      footer1Text={'Go to my profile page'}
      footer1Action = {footer1Action}
    />
  );
};

export default WorkInProgress;
