import React, {useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';

import {CircleChecked, CircleX} from '../assets/icons';
import {useDispatch} from 'react-redux';

const Input = styled('input')`
  height: 100%;
  padding: 25px 23px;
  border: none;
  background-color: ${(props) => props.background};
  outline: none;
  width: 100%;
  border-radius: 3px 0 0 3px;
  &::placeholder {
    color: var(--mocha);
  }
`;

const InputContainer = styled('div')`
  height: 100%;
  position: relative;
`;

const Button = styled('div')`
  padding: 25px 23px;
  background-color: var(--aut-lait-bgd);
  border: none;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
`;

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: 3px;
`;

const Feedback = styled('p')`
  margin-top: 10px;
`;

const CodeInput = ({
                     placeholder = '[Placeholder]',
                     button = '[Button]',
                     removeButton = '[Remove]',
                     removeAction = () => console.log('removeAction clicked'),
                     background = 'white',
                     error,
                     message,
                     firstMonth,
                     action = (e) => console.log('CodeInput clicked', e),
                     total,
                       nextMonth,
    toggleCoffee,
    setCoffeeChanged
}) => {
  const dispatch = useDispatch();
  const myRef = useRef();
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const handleSubmit = (e) => {
    setDisabled(true);
    dispatch(action(e.current.value.trim(), firstMonth, total , nextMonth));
  };
  const handleTyping = (e) => {
    setValue(e.target.value.toUpperCase().trim());
  };

  useEffect(() => {
      if(toggleCoffee) {
          setDisabled(false);
          setValue('');
          dispatch(removeAction({ firstMonth , nextMonth}));
          setCoffeeChanged(false)
      }
      //eslint-disable-next-line
  } , [toggleCoffee])

  const handleRemove = () => {
    setDisabled(false);
    setValue('');
    dispatch(removeAction({ firstMonth , nextMonth}));
  };

  return (
    <>
      <Container>
        <InputContainer>
          <Input
            disabled={disabled}
            tabIndex='1'
            background={background}
            ref={myRef}
            className='noto-body-15'
            type='text'
            placeholder={placeholder}
            onChange={handleTyping}
            value={value}
          />
        </InputContainer>
        {!disabled && (
          <Button
            tabIndex='2'
            onClick={() => handleSubmit(myRef)}
            className='inter-body-15-sb'
          >
            {button}
          </Button>
        )}
        {disabled && (
          <Button
            tabIndex='2'
            onClick={handleRemove}
            className='inter-body-15-sb'
          >
            {removeButton}
          </Button>
        )}
        {(error || message) && (
          <Feedback className='noto-caption-13'>
            <img
              style={{ color: error ? 'red' : 'black' }}
              src={error ? CircleX : CircleChecked}
              alt='feedback'
            />
            &nbsp;{error || message}
          </Feedback>
        )}
      </Container>
    </>
  );
};

export default CodeInput;
