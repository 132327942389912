import React from 'react';

import { useDispatch } from 'react-redux';
import { quizSetHotOrIced, quizGotoNext } from '../../actions/Quiz';
import {
  StyledVerticalOption,
  StyledVerticalMenu,
  Title,
  Content
} from '../../components';
import { Hot, Iced, Heart } from '../../assets/icons';
import ReactPixel from "react-facebook-pixel";

const HotOrIced = (props) => {
  const dispatch = useDispatch();

  const selectHandler = (e) => {
    ReactPixel.trackCustom("HotOrIced", {type: e.target.value})
    dispatch(quizSetHotOrIced(e.target.value));
    dispatch(quizGotoNext());
  };

  React.useEffect(() => {ReactPixel.pageView()}, [])

  return (
    <>
      <Title title='Do you prefer hot or iced drinks?' />
      <Content style={{ marginTop: '20px' }}>
        <StyledVerticalMenu displayCheckbox={false} onChange={selectHandler}>
          <StyledVerticalOption value={'hot'}>
            <img src={Hot} alt='selection-option' />
            Hot Drinks
          </StyledVerticalOption>
          <StyledVerticalOption value={'cold'}>
            <img src={Iced} alt='selection-option' />
            Iced Drinks
          </StyledVerticalOption>
          <StyledVerticalOption value={'no-preference'}>
            <img src={Heart} alt='selection-option' />I like them all
          </StyledVerticalOption>
        </StyledVerticalMenu>
      </Content>
    </>
  );
};

export default HotOrIced;
