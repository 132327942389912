import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {PageWithImage, Wrapper} from '../../components';
import {PersonWithBook} from '../../assets/images';
import {ArrowRight} from '../../assets/icons';
import {useDispatch} from "react-redux";
import {cleanQuizStore} from "../../actions/User";
import ReactPixel from "react-facebook-pixel";

const ForgotPasswordConfirm = () => {
  const history = useHistory();
  const dispatch = useDispatch()

  const goToQuiz = () => {
    history.push('/quiz');
  };

  useEffect(() => {
    dispatch(cleanQuizStore())
  }, [dispatch])

  const skipQuiz = () => {
    history.push('/coffee-type');
  };

  React.useEffect(() => {ReactPixel.pageView()}, [])

  return (
      <>
        <Wrapper style={{backgroundColor: 'white'}}>
          <PageWithImage
              image={PersonWithBook}
          title='Hello! We’d like to get to know you a little better.'
          text='Tell us your drink preferences and we’ll find the right plan for you.'
          footer1Icon={ArrowRight}
          footer1Action={goToQuiz}
          footer1Text='Let’s get started'
          footer1ActionLeft={skipQuiz}
          footer1TextLeft='Skip'
        />
      </Wrapper>
    </>
  );
};

export default ForgotPasswordConfirm;
