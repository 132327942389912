import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/styles/styles.css';
import { DeliveryTimeslot } from '../../components';
import { changeDeliveryTimeSlot } from '../../actions/Order';
import ReactPixel from "react-facebook-pixel";

const SelectDeliveryTimeSlot = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const timeSlot = useSelector(
    (state) => state.orderDetail.order.deliveryTimeslot
  );

  const changeHandler = (e) => {
    ReactPixel.trackCustom("ChangeDeliveryTimeSlots", {value: e.target.value})
    dispatch(changeDeliveryTimeSlot(e.target.value));
  };

  React.useEffect(() => {ReactPixel.pageView()}, [])

  const handleContinueClick = () => history.push('/manage/confirm-change');

  return (
    <DeliveryTimeslot
      titleText='CHOOSE BETWEEN A MORNING OR AFTERNOON SLOT FOR DELIVERY.'
      onContinueClick={handleContinueClick}
      onTimeslotChange={changeHandler}
      defaultValue={timeSlot}
      disabled={timeSlot === undefined}
    />
  );
};

export default SelectDeliveryTimeSlot;
