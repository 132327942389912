import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import { Bookmarks, ChevronRight } from '../assets/icons';

const Container = styled('div')`
  display: grid;
  padding: 20px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 3px;
  margin: 20px 0;
  grid-template-columns: 1fr 8fr 1fr;
  cursor: pointer;
`;

const LeftIcon = styled('img')`
  margin-right: 17px;
`;

const RightIcon = styled('img')`
  opacity: 43%;
`;

const CTAButton = (
  {
    text = '[Text]',
    leftIcon = {},
    rightIcon = {},
    color = 'var(--bettr-yellow)',
    action = () => console.log('CTAButton clicked')
  },
  ref
) => {
  return (
    <Container backgroundColor={color} ref={ref} onClick={action}>
      <LeftIcon
        src={leftIcon.img || Bookmarks}
        alt={leftIcon.alt || 'left-icon'}
      />
      <div className='inter-body-15-sb'>{text}</div>
      <RightIcon
        src={rightIcon.img || ChevronRight}
        alt={rightIcon.alt || 'right-icon'}
      />
    </Container>
  );
};

export default forwardRef(CTAButton);
