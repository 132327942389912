import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Footer, FooterButton, Title, Content, Notification } from '../../../components';
import AddressCard from '../components/AddressCard';
import { CheckMark } from '../../../assets/icons';
import { addressResetGoto } from '../../../actions/Address';
import { getCustomer, editCustomer } from '../../../actions/Order';

const ConfirmAddressSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getCustomer());
    };
    fetchData();
      //eslint-disable-next-line
  }, []);

  const editHandler = () => {
    dispatch(addressResetGoto());
    history.push('/coffee-handle');
  };

  const address = useSelector((state) => state.addressDetail.address);
  const customer = useSelector((state) => state.orderDetail.customer);
  const [notification, setNotification] = useState(true);

  const confirmHandler = async () => {
    if (customer.id && customer.subscriptions.data.length !== 0) {
      await dispatch(
        editCustomer({
          customerId: customer.id,
          addressDescription: address.addressDescription,
          streetName: address.streetname,
          unitNo: address.unitNo,
          postalCode: address.postalCode
        })
      );

      history.push('/manage/saved-plan-updates');
    } else {
      history.push('/select-delivery-day');
    }
  };

  return (
    <div>
      <Title
        className='large-title'
        title='Kindly confirm that this address is correct.'
      />
      <Content>
        <AddressCard
          addressString={address.addressString}
          addressDescription={address.addressDescription}
          action={editHandler}
        />
      </Content>
      { notification && customer.id && customer.subscriptions.data.length !== 0 && (
        <Notification
          text={"Any changes to your delivery address made before each renewal will be implemented in your next monthly subscription."}
          type={'update'}
          action={() => setNotification(false)}
        />
      )
      }
      <Footer>
        <FooterButton
          style={{ backgroundColor: 'var(--bettr-yellow' }}
          icon={CheckMark}
          textRight='Confirm'
          actionRight={confirmHandler}
        />
      </Footer>
    </div>
  );
};

export default ConfirmAddressSection;
