import moment from 'moment';

export const keepTwoDecimals = (number) =>
  (Math.round(number * 100) / 100).toFixed(2);

export const validateMobileNumber = (number) => {
  const re = /^(6|8|9)\d{7}$/;
  return re.test(number);
};

export const capitaliseCase = (text) => {
  const textArr = text.split(' ');
  const capitalised = textArr.map((e) => {
    let word = '';
    word += e[0].toUpperCase();
    word += e.slice(1);
    return word;
  });
  return capitalised.join(' ');
};

export const changeProductItem = (state, action) => {
  for (let pdtLine of state.order.recommendation.products) {
    if (
      pdtLine.productLine === action.payload.productLine &&
      pdtLine.productLine === 'Subscription Plan'
    ) {
      for (let pdt of pdtLine.items) {
        if (pdt.planId === action.payload.planId) {
          // Radio logic
          pdt.isSelected = true;
        } else {
          pdt.isSelected = false;
        }
      }
    } else if (
      pdtLine.productLine === action.payload.productLine &&
      pdtLine.productLine !== 'Subscription Plan'
    ) {
      for (let pdt of pdtLine.items) {
        if (pdt.planId === action.payload.planId) {
          // Toggle logic
          pdt.isSelected = !pdt.isSelected;
        } else {
          pdt.isSelected = false;
        }
      }
    }
  }
};

export const createSummary = (state, action) => {
  const { firstMonth } = action.payload;
  let discountPercentage;
  if (state.order.discount.percent) {
    discountPercentage = state.order.discount.percent;
  }
  let discountAmount;
  if (state.order.discount.amount) {
    discountAmount = state.order.discount.amount;
  }
  let discountError;
  if (state.order.discount.error) {
    discountError = state.order.discount.error;
  }
  let totalMonthlyDeliveries;
  let originalUnitPrice;
  let originalPlanPrice;
  let discountedUnitPrice;
  let discountedPlanPrice;
  let discount;

  // Standard (default)
  state.order.summary = [
    {
      field: 'Total Monthly Deliveries',
      value: null,
      raw: null
    },
    {
      field: 'Subscription Plan',
      value: null,
      planId: null
    },
    {
      field: 'Price per Drink',
      value: null,
      otherValue: null,
      raw: null
    }
  ];
  // ProductLines
  if (state.order.recommendation !== null) {
    state.order.recommendation.products.forEach((pdtLine) => {
      if (pdtLine.productLine === 'Subscription Plan') {
        pdtLine.items.forEach((item) => {
          if (item.isSelected) {
            // Set all price variables
            totalMonthlyDeliveries = +item.priceId.split('-')[0];
            discountedUnitPrice = +item.unitPrice;
            discountedPlanPrice = +item.price;
            discount = +item.discount;
            originalPlanPrice = +(discountedPlanPrice / (1 - discount)).toFixed(
              2
            );
            originalUnitPrice = +(
              originalPlanPrice / totalMonthlyDeliveries
            ).toFixed(2);

            // Total Monthly Deliveries
            state.order.summary[0].value = totalMonthlyDeliveries;
            state.order.summary[0].raw = totalMonthlyDeliveries;

            // Subscription Plan
            state.order.summary[1].value = `${item.description} (-${(
              discount * 100
            ).toFixed(0)}%)`;
            state.order.summary[1].planId = item.planId;

            // Price Per Drink
            state.order.summary[2].otherValue = `$${keepTwoDecimals(
              originalUnitPrice
            )}`;
            state.order.summary[2].value = `$${keepTwoDecimals(
              discountedUnitPrice
            )}`;
            state.order.summary[2].raw = discountedUnitPrice;
          }
        });
        // Snacks
      } else if (pdtLine.productLine === 'Snacks') {
        state.order.snacks.type = '';
        state.order.snacks.list = [];
        pdtLine.items.forEach((snack) => {
          if (snack.isSelected) {
            state.order.snacks.type = snack.description.split(' ')[0];
            if (snack.additional) {
              state.order.snacks.list = snack.additional.split(', ');
            }
            state.order.summary.push({
              field: `${snack.description} (${snack.priceDesc})`,
              value: `$${keepTwoDecimals(
                totalMonthlyDeliveries * +snack.price
              )}`,
              planId: snack.planId
            });
          }
        });
      }
      // Other Add Ons
      else {
        pdtLine.items.forEach((addOn) => {
          if (addOn.isSelected) {
            state.order.summary.push({
              field: `${addOn.description} (${addOn.priceDesc})`,
              value: `$${keepTwoDecimals(
                totalMonthlyDeliveries * +addOn.price
              )}`,
              planId: addOn.planId
            });
          }
        });
      }
    });
  }

  // Delivery
  state.order.deliveryFee?.price &&
    state.order.orderType !== 'Pickup' &&
    state.order.summary.push({
      field: 'Delivery Fee',
      value: `$${(
        totalMonthlyDeliveries * state.order.deliveryFee.price
      ).toFixed(2)}`,
      planId: state.order.deliveryFee.planId
    });

  // First Month / Total Monthly
  if (state.order.recommendation !== null) {
    const totalAddOn = state.order.summary
      .slice(3)
      .reduce((acc, curr) => acc + +curr.value.slice(1), 0);



    if(state.order.discount.minimumAmount){
      discountError = (discountedPlanPrice + totalAddOn) < (state.order.discount.minimumAmount / 100) ?
          `The minimal amount for this coupon is $${state.order.discount.minimumAmount / 100}` : null
    }

    // If no discount error
    if (!discountError) {
      // If it's a discount percentage
      if (discountPercentage) {
        state.order.summary.push({
          field: `${firstMonth ? 'First Month' : 'Total Monthly'}`,
          value: `$${keepTwoDecimals(
            (discountedPlanPrice + totalAddOn) * (1 - discountPercentage / 100)
          )}`,
          otherValue: `${
              !discountError
              ? `$${keepTwoDecimals(discountedPlanPrice + totalAddOn)}`
              : ''
          }`,
          raw:
            +(discountedPlanPrice + totalAddOn) * (1 - discountPercentage / 100)
        });
        // If it's a discount amount
      } else if (discountAmount) {
        state.order.summary.push({
          field: `${firstMonth ? 'First Month' : 'Total Monthly'}`,
          value: `$${
            (discountedPlanPrice + totalAddOn - discountAmount / 100).toFixed(
              2
            ) < 0
              ? 0
              : (
                  discountedPlanPrice +
                  totalAddOn -
                  discountAmount / 100
                ).toFixed(2)
          }`,
          otherValue: `${
            firstMonth ? `$${keepTwoDecimals(discountedPlanPrice + totalAddOn)}` : ''
          }`,
          raw: +`${
            keepTwoDecimals(
              discountedPlanPrice + totalAddOn - discountAmount / 100
            ) < 0
              ? 0
              : keepTwoDecimals(
                  discountedPlanPrice + totalAddOn - discountAmount / 100
                )
          }`
        });
        // If no discount coupon is applied
      } else {
        state.order.summary.push({
          field: `${firstMonth ? 'First Month' : 'Total Monthly'}`,
          value: `$${keepTwoDecimals(discountedPlanPrice + totalAddOn)}`,
          otherValue: '',
          raw: +keepTwoDecimals(discountedPlanPrice + totalAddOn)
        });
      }
      // If there's a discount error
    } else {
      state.order.summary.push({
        field: `${firstMonth ? 'First Month' : 'Total Monthly'}`,
        value: `$${keepTwoDecimals(discountedPlanPrice + totalAddOn)}`,
        otherValue: '',
        raw: +keepTwoDecimals(discountedPlanPrice + totalAddOn)
      });
    }
  }

  state.order.numberOfDeliveryDaysAllowed = state.order.summary[0].value / 4;
};

export const createCustomer = (state, action) => {
  state.customer = action.payload.data;

  const data = action.payload.data.subscriptions.data[0];
  if (data) {
    const { items: customerSavedProducts, metadata } = data;
    state.order.orderType = metadata.COFFEE_RECEIVE_MODE
    state.order.deliveryDaySelection = metadata.DAYS_DRINKS_DELIVERED.split(
      ','
    ).map((n) => +n);
    state.order.deliveryTimeslot = metadata.SERVICE_TIME === '8:30AM' ? 1 : 2;
    const dt = customerSavedProducts.data.map((d) => d.price.id);
    state.order.savedData = dt;

    if (state.order.recommendation !== null) {
      for (let pdtLine of state.order.recommendation.products) {
        for (let pdt of pdtLine.items) {
          for (let d of dt) {
            if (pdt.planId === d) {
              pdt.isSelected = true;
              break;
            } else {
              pdt.isSelected = false;
            }
          }
        }
      }
    }
  }
};

const deriveDeliveryPlanDay = (selection) => {
  const weekdays = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday'
  };

  let days = '';
  for (let i = 0; i < selection.length; i++) {
    if (selection.length === 0) {
      break;
    } else if (selection.length === 1) {
      days = `${weekdays[selection[i]]}`;
    } else if (i === selection.length - 1) {
      days += `& ${weekdays[selection[i]]}`;
    } else if (i === selection.length - 2) {
      days += `${weekdays[selection[i]]} `;
    } else {
      days += `${weekdays[selection[i]]}, `;
    }
  }

  return days;
};

const isDelivery = (state) => state.order.orderType === 'Delivery';

export const isAmOrPm = (hour) =>
  ['8', '9', '10', '11'].includes(hour) ? 'AM' : 'PM';

const deliverySlotInterval = ({ hour, time }) => {
  let startString = hour + time + isAmOrPm(hour);
  let start = moment(startString, 'hmma').format('LT');
  let end = moment(startString, 'hmma').add(15, 'minute').format('LT');
  return '(' + start + ' to ' + end + ')';
};

const deriveDeliveryPlanDeliverySlot = (state) => {
  if (isDelivery(state)) {
    return state.order.deliveryTimeslot === 1
      ? 'AM (8:30am to 11:30am)'
      : 'PM (2:00pm to 4:30pm)';
  } else {
    return deliverySlotInterval(state.order.pickupTimeslot);
  }
};

export const createMyPlanSummary = (state) => {
  state.order.myPlanSummary = [
    {
      field: 'Frequency',
      value: null
    },
    {
      field: 'Drink choice',
      value: null
    }
  ];

  // Retrieve all the index
  const subscriptionPlanIdx = state.order.summary.findIndex(
    (i) => i.field === 'Subscription Plan'
  );
  const freqFieldIdx = state.order.myPlanSummary.findIndex(
    (i) => i.field === 'Frequency'
  );
  const drinkChoiceIdx = state.order.myPlanSummary.findIndex(
    (i) => i.field === 'Drink choice'
  );
  const deliveryPlanDayIdx = state.order.deliveryPlan.findIndex(
    (i) => i.field === 'Days'
  );
  const deliveryPlanTimeSlotIdx = state.order.deliveryPlan.findIndex(
    (i) => i.field === 'Time Slot'
  );

  // Frequency
  const freq = state.order.summary[subscriptionPlanIdx]?.value;
  if (freq) {
    // Find e.g. 'every weekday' from 'every weekday (-13%)' in state.order.summary
    const freqValueIdx = freq.indexOf('(') - 1;
    state.order.myPlanSummary[freqFieldIdx].value = freq.slice(0, freqValueIdx);
  }
  // Drink Choice
  state.order.myPlanSummary[drinkChoiceIdx].value =
    state.order.recommendation.selectedCoffee.name;

  // Add Ons
  state.order.recommendation.products.forEach((pdtLine) => {
    if (pdtLine.productLine !== 'Subscription Plan') {
      pdtLine.items.forEach((item) => {
        if (item.isSelected) {
          state.order.myPlanSummary.push({
            field: pdtLine.productLine,
            value: item.description
          });
        }
      });
    }
  });

  // a lot of side-effects on the state - defeats redux purpose
  state.order.deliveryPlan[deliveryPlanDayIdx].value = deriveDeliveryPlanDay(
    state.order.deliveryDaySelection.sort((a, b) => a - b)
  );
  state.order.deliveryPlan[
    deliveryPlanTimeSlotIdx
  ].value = deriveDeliveryPlanDeliverySlot(state);
};

export const validatePostalCode = async (number) => {
  if (!number || number.length !== 6) return false;

  return await fetch(
    `https://developers.onemap.sg/commonapi/search?searchVal=${number}&returnGeom=Y&getAddrDetails=Y&pageNum=1`
  )
    .then((res) => res.json())
    .then((result) => result.found > 0);
};

export const validateDeliveryDays = (
  numOfDaysSelected,
  totalWeeklyDeliveries
) => {
  return numOfDaysSelected === totalWeeklyDeliveries;
};

export const validateDays = (values, err, order, setDisabled, setErrorMessage) => {
  if (!validateDeliveryDays(values.length, order.numberOfDeliveryDaysAllowed)) {
    setDisabled(true);
    setErrorMessage(err);
  } else {
    setDisabled(false);
    setErrorMessage();
  }
};

export const mq = (n) => {
  const breakpoints = {
    galaxyFold: 280,
    iphone5: 320,
    iphoneX: 375,
    sm: 500,
    ipad: 768,
    lg: 1200
  };

  return `@media (min-width: ${breakpoints[n]}px)`;
};
