import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import {
  Header,
  Footer,
  Title,
  Content,
  SummaryTable,
  FooterButton
} from '../../components';
import { ArrowRight } from '../../assets/icons/';
import { setConfirmChange } from '../../actions/Order';
import { updateSubscription } from './../../actions/Payments';
import { mq, validateDeliveryDays } from '../../helpers/utils';

const CustomWrapper = styled('div')`
  margin: 0 auto;

  ${mq('lg')} {
    overflow: visible;
    max-width: 404px;
    margin: 0 auto;
  }
`;

const Container = styled('div')`
  background-color: white;
  padding-bottom: 100px;

  ${mq('lg')} {
    padding-bottom: 180px;
  }
`;

const Error = styled('div')`
  color: #c23d4b;
`;

const ConfirmChange = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const order = useSelector((state) => state.orderDetail);
  const address = useSelector((state) => state.addressDetail.address);

  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const customer = useSelector((state) => state.orderDetail.customer);
  const {
    summary,
    myPlanSummary,
    deliveryPlan,
    deliveryDaySelection,
    numberOfDeliveryDaysAllowed
  } = useSelector((state) => state.orderDetail.order);

  const validateDays = () => {
    if (
      !validateDeliveryDays(
        deliveryDaySelection.length,
        numberOfDeliveryDaysAllowed
      )
    ) {
      setDisabled(true);
      setErrorMessage('Plan frequency does not match days selected!');
    } else {
      setDisabled(false);
      setErrorMessage();
    }
  };

  const handleContinue = async () => {
    validateDays();
    await dispatch(
      updateSubscription(
        order.customer.subscriptions.data[0].id,
        order.order,
        address
      )
    );
    history.push('/manage/saved-plan-updates');
  };

  const coffeeReceiveMode = useSelector(
    (state) =>
      state.orderDetail.customer.subscriptions.data[0]?.metadata
        .COFFEE_RECEIVE_MODE
  );

  const handlePlanEdit = () => history.push('/manage/change-plan');
  const handleDeliveryEdit = () => {
    if (coffeeReceiveMode === 'Delivery') {
      history.push('/manage/change-delivery-day');
    } else {
      history.push('/manage/change-pickup-day');
    }
  };

  useEffect(() => {
    dispatch(setConfirmChange());
    validateDays();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Container>
        <CustomWrapper>
          <Header />
          <Title
            style={{ paddingBottom: '33px' }}
            title={`${
              customer?.name ? `${customer?.name}, ` : ''
            }please check if all the information is correct.`}
          />
        </CustomWrapper>
        <Content style={{ paddingBottom: '0', marginBottom: '33px' }}>
          <CustomWrapper>
            <SummaryTable
              title='My Plan Summary'
              list={myPlanSummary}
              edit
              action={handlePlanEdit}
            />
            <SummaryTable
              title={`${order.order.orderType || 'Delivery'} Plan`}
              list={deliveryPlan}
              edit
              action={handleDeliveryEdit}
            />
            <Error>{errorMessage}</Error>
          </CustomWrapper>
        </Content>
        <Content
          style={{
            backgroundColor: 'var(--au-lait)',
            paddingTop: '43px'
          }}
        >
          <CustomWrapper>
            <SummaryTable
              title='Order Summary'
              list={summary}
              summary
              firstMonth
            />
          </CustomWrapper>
        </Content>
      </Container>
      <CustomWrapper>
        <Footer>
          <FooterButton
            icon={ArrowRight}
            textRight='Continue'
            actionRight={handleContinue}
            disabled={disabled}
          />
        </Footer>
      </CustomWrapper>
    </>
  );
};

export default ConfirmChange;
