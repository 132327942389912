import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import { PageWithImage } from '../../components';
import { PersonWithScroll } from '../../assets/images';
import {useDispatch, useSelector} from "react-redux";
import TagManager from "react-gtm-module";
import {cleanQuizStore} from "../../actions/User";

const PaymentSuccess = (props) => {
  const history = useHistory();
  const handleGoToProfile = () => history.push('/profile');
  const handleGetInTouch = () => history.push('/feedback');
  const dispatch = useDispatch()

  const customerName = useSelector(state => state.orderDetail.customer?.name)

  useEffect(() => {
    dispatch(cleanQuizStore())
    const total = props.history.location.state.total.replace('$' , "");
    const tagManagerArgs = {
      dataLayer: {
        totalPurchase: total,
        userProject: 'bettr-app',
        page: 'PaymentSuccess'
      },
      dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)
  } , [])

  return (
    <PageWithImage
      CTAText='Get in touch'
      CTAAction={handleGetInTouch}
      fullWidthText
      image={PersonWithScroll}
      title={`${customerName}, YOU'RE ON YOUR WAY TO RECEIVING YOUR SUBSCRIPTION! `}
      text="We have sent you an email confirmation of your order. If you have any questions about your order, please contact us."
      footer1Text='Go to my profile page'
      footer1Action={handleGoToProfile}
    />
  );
};

export default PaymentSuccess;
