import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import '../../assets/styles/styles.css';
import { changeBestDeliveryDays } from '../../actions/Order';
import PickupDay from '../../components/delivery-options/PickupDay';
import { validateDays } from '../../helpers/utils';
import ReactPixel from "react-facebook-pixel";

const ChangePickupDay = () => {
  const pickupStore = useSelector(
    (state) =>
      state.addressDetail.pickupStores.filter(
        ({ streetName }) =>
          streetName === state.orderDetail.customer.shipping?.address.line1
      )[0]
  );

  const order = useSelector((state) => state.orderDetail.order);
  const dispatch = useDispatch();
  const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const changeHandler = (values) => {
    validateDays(values, 'Plan frequency does not match days selected!', order, setDisabled, setErrorMessage);
    ReactPixel.trackCustom("ChangePickupDays", { values })
    dispatch(changeBestDeliveryDays(values));
  };

  const handleContinueClick = () => {
    history.push('/manage/change-pickup-timeslot');
  };

  const handleBackAction = () => history.push('/manage/change-plan');

  useEffect(() => {
    validateDays(order.deliveryDaySelection, '', order, setDisabled, setErrorMessage);
    ReactPixel.pageView()
    //eslint-disable-next-line
  }, []);

  return (
    <PickupDay
      order={order}
      pickupStore={pickupStore}
      titleText={
        'Which day(s) would you like to pick up your drink from our store?'
      }
      onDaySelect={changeHandler}
      onContinueClick={handleContinueClick}
      backAction={handleBackAction}
      disabled={disabled}
      errorMessage={errorMessage}
    />
  );
};
export default ChangePickupDay;
