import React, { useRef, useState } from 'react';
import { Radio as AntRadio } from 'antd';
import styled from '@emotion/styled';

const StyledRadio = styled.div`
  .ant-radio-group {
    display: flex;
  }

  .ant-radio-wrapper {
    display: ${(props) => (props.displayCheckbox ? 'block' : 'flex')};
    justify-content: center;
    width: 308px;
    height: 95px;
    background: #ffffff;
    font-family: Noto Serif;
    font-size: 15px;
    border-radius: 6px;
  }

  .ant-radio-wrapper-checked {
    background: #ffc200;
  }

  .ant-radio-wrapper span {
    &:last-child {
      margin: 37px 50px;
      position: absolute;
    }
  }

  .ant-radio-wrapper span img {
    &:last-child {
      padding-right: 9px;
    }
  }

  .ant-radio {
    position: relative;
    display: ${(props) => (props.displayCheckbox ? 'block' : 'none')};
    right: 89px;
    float: right;
  }

  .ant-radio-inner {
    background: #f9f6e8;
    border: none;
    width: 26px;
    height: 26px;
    &:after {
      position: relative;
      top: 7px;
      left: 7px;
      background: #ffc200;
      width: 12px;
      height: 12px;
    }
  }
`;

const StyledHorizontalMenu = (props, ref) => {
  const node = useRef();

  const [value, setValue] = useState('');

  const onChange = (e) => {
    props.onChange(e);
    setValue(e.target.value);
  };

  return (
    <StyledRadio displayCheckbox={props.displayCheckbox} ref={node}>
      <AntRadio.Group onChange={onChange} value={value}>
        {props.children}
      </AntRadio.Group>
    </StyledRadio>
  );
};

export default StyledHorizontalMenu;
