import React from 'react';
import PageWithImage from '../../../components/PageWithImage';
import SeeYouAtStoreImage from '../../../assets/images/SeeYouAtStoreImage.svg';
import { ArrowRight } from '../../../assets/icons';
import { useHistory } from 'react-router';

const SeeYouAtStore = () => {
  const history = useHistory();
  return (
    <PageWithImage
      noBack
      image={SeeYouAtStoreImage}
      title={'Awesome! We look forward to seeing you at our store.'}
      footer1Text={'Continue'}
      footer1Icon={ArrowRight}
      footer1Action={() => {
        history.push('/purchase-review');
      }}
    />
  );
};

export default SeeYouAtStore;
