import React, {forwardRef, useEffect} from 'react';
import styled from '@emotion/styled';
import TagManager from "react-gtm-module";

const Row = styled('tr')`
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.43);
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr auto;
`;

const StrikeThrough = styled('span')`
  text-decoration: line-through;
  opacity: 50%;
`;

const SummaryTableRow = (
  { field = '[Field]', value = '[Value]', otherValue, firstMonth , discount , summary },
  ref
) => {

    useEffect(() => {
        if(field === "Total Monthly"){
            const tagManagerArgs = {
                dataLayer: {
                    totalMonthly: otherValue ? otherValue.replace('$' , "") : value.replace('$' , ""),
                    totalMonthlyWithDiscount: value.replace('$' , ""),
                    userProject: 'bettr-app',
                    page: 'Summary'
                },
                dataLayerName: 'PageDataLayer'
            }
            TagManager.dataLayer(tagManagerArgs)
        }
        //eslint-disable-next-line
    } , [value , otherValue])

  return (
    <Row ref={ref}>
      <td>{field}</td>
      <td
        className='inter-body-15-sb'
        style={{
          color: 'var(--molasses)',
          textTransform: 'capitalize',
          maxWidth: '200px',
          textAlign: 'right'
        }}
      >
        {(firstMonth || field === 'Price per Coffee' || discount) && (
          <StrikeThrough>{otherValue}</StrikeThrough>
        )}
        {(firstMonth || field === 'Price per Coffee') &&
          ' '.charAt(0).concat(' '.charAt(0))}
          &nbsp;{value}
      </td>
    </Row>
  );
};

export default forwardRef(SummaryTableRow);
