import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {_ErrorMessages as msg} from '../../constants/ErrorMessages';
import {quizSetConsentGiven} from '../../actions/Quiz';
import {ArrowRight} from '../../assets/icons';
import {createEdmContact, signupGotoNext, signupSetEmail} from '../../actions/User';
import {ConfirmationButton, Content, Footer, FooterButton, Input, Title} from '../../components';
import validator from 'validator';
import styled from '@emotion/styled';
import {mq} from '../../helpers/utils';

const MobileConfirmation = styled('div')`
  display: block;
  ${mq('lg')} {
    display: none;
  }
`;
const DesktopConfirmation = styled('div')`
  display: none;
  ${mq('lg')} {
    display: block;
  }
`;


const EmailSection = () => {
  const currentUser = useSelector((state) => state.userDetail.user);
  const quiz = useSelector((state) => state.quizInput.quiz);
  const consentGiven = useSelector(
    (state) => state.quizInput.quiz.consentGiven
  );
  const [emailErrMsg, setEmailErrMsg] = useState('');

  useEffect(() => {
    dispatch(signupSetEmail(quiz.email));
      //eslint-disable-next-line
  }, []);

  const validationsHandler = () => {
    if (!validator.isEmail(currentUser.email)) {
      setEmailErrMsg(msg.validEmail);
    } else {
      dispatch(createEdmContact({ email: currentUser.email.trim(), name: currentUser.name}));
      dispatch(signupGotoNext());
      setEmailErrMsg(null);
    }
  };

  const dispatch = useDispatch();

  const toggleConsent = () => {
    dispatch(quizSetConsentGiven(!consentGiven));
  };

  return (
    <div>
      <Title title='what is the best email address to reach you at?' />
      <Content style={{ marginTop: '20px' }}>
        <Input
          onChange={(e) => {
            dispatch(signupSetEmail(e.target.value));
          }}
          placeholder='Email Address'
          type='email'
          value={currentUser.email}
          errMsg={emailErrMsg || currentUser.error}
          name='email'
          label='Email Address'
        />
      </Content>
      <MobileConfirmation>
          <ConfirmationButton
            action={() => toggleConsent()}
            checked={consentGiven}
            text={`I'd like to be kept up to date on news and exclusive offers.`}
          />
      </MobileConfirmation>
      <Footer>
      <DesktopConfirmation>
          <ConfirmationButton
            action={() => toggleConsent()}
            checked={consentGiven}
            text={`I'd like to be kept up to date on news and exclusive offers.`}
          />
        </DesktopConfirmation>
        <FooterButton
          icon={ArrowRight}
          textRight='Continue'
          actionRight={() => {
            validationsHandler();
          }}
        />
      </Footer>
    </div>
  );
};

export default EmailSection;
