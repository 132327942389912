import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { mq } from '../../helpers/utils';

const Image = styled('img')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 65px;

  ${mq('lg')} {
    width: 96px;
  }
`;

const Circle = styled('div')`
  position: relative;
  background-color: ${(props) =>
    props.selected ? 'var(--bettr-yellow)' : 'white'};
  width: 130px;
  height: 130px;
  border-radius: 100%;

  ${mq('lg')} {
    width: 192px;
    height: 192px;
  }
`;

const CoffeeItemContainer = styled('div')`
  display: grid;
  grid-template-rows: 130px auto;
  row-gap: 10px;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;

  ${mq('lg')} {
    grid-template-rows: 192px auto;
    margin: 10px 10px 10px 0;
  }
`;

const CoffeeItem = (
  { isSelected, imageSrc, drinkName, style, action },
  ref
) => {
  return (
    <CoffeeItemContainer ref={ref} style={style} onClick={action}>
      <Circle selected={isSelected}>
        <Image src={imageSrc} alt='coffee-item' />
      </Circle>
      <p className='noto-caption-13'>{drinkName}</p>
    </CoffeeItemContainer>
  );
};

export default forwardRef(CoffeeItem);
