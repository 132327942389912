import { createReducer } from '@reduxjs/toolkit';

import {
  setRecommendation,
  setAddOn,
  setDeliveryFee,
  setDiscount,
  setDiscountError,
  removeDiscount,
  setCustomer,
  setStripeCustomer,
  setConfirmChange,
  setPurchaseReview,
  setSpecialInstructions,
  changeBestDeliveryDays,
  changeDeliveryTimeSlot,
  setNextDelivery,
  changePickupTimeSlot,
  setOrderType,
  setDefaults, initOrderDetails, clearOriginalPlanValues, setOriginalPlans
} from '../actions/Order';
import {
  changeProductItem,
  createSummary,
  createMyPlanSummary,
  createCustomer
} from '../helpers/utils';

const initialState = {
  order: {
    deliveryDaySelection: [],
    numberOfDeliveryDaysAllowed: 3,
    deliveryTimeslot: undefined,
    pickupTimeslot: { hour: undefined, time: undefined },
    orderType: undefined,
    recommendation: null,
    originalSummary: [],
    originalDeliveryPlan:[],
    summary: [
      {
        field: 'Total Monthly Deliveries',
        value: null,
        raw: null
      },
      {
        field: 'Subscription Plan',
        value: null,
        raw: null
      },
      {
        field: 'Price per Coffee',
        value: null,
        otherValue: null,
        raw: null
      }
    ],
    snacks: {
      list: [],
      type: ''
    },
    savedData: [],
    deliveryFee: undefined,
    discount: {
      id: '',
      coupon: '',
      percent: '',
      amount: '',
      error: '',
      minimumAmount:0
    },
    myPlanSummary: [
      {
        field: 'Frequency',
        value: null
      },
      {
        field: 'Drink choice',
        value: null
      }
    ],
    deliveryPlan: [
      {
        field: 'Days',
        value: null
      },
      {
        field: 'Time Slot',
        value: null
      }
    ],
    nextDeliveryObj: {
      nextDelivery: {
        streetAddress: undefined,
        day: undefined,
        date: undefined,
        time: undefined
      },
      remainingDeliveryCount: 0
    },
    specialInstructions: ''
  },
  customer: {
    name: 'there',
    subscriptions: {
      data: []
    }
  }
};

export default createReducer(initialState, {
  [setAddOn](state, action) {
    changeProductItem(state, action);
    createSummary(state, action);
  },
  [setOriginalPlans](state, action) {
    state.order.originalSummary = state.order.myPlanSummary
    state.order.originalDeliveryPlan = state.order.deliveryPlan
  },
  [setRecommendation](state, action) {
    state.order.recommendation = action.payload.data;
    if (state.order.originalSummary.length === 0) {
      createSummary(state, action);
      createMyPlanSummary(state , action)
      state.order.originalSummary = state.order.myPlanSummary
      state.order.originalDeliveryPlan = state.order.deliveryPlan
    } else {
      createSummary(state, action);
    }
  },
  [clearOriginalPlanValues](state , action){
    state.order.originalSummary = []
    state.order.originalDeliveryPlan = []
  },
  // TODO: To have the backend send a isDefault attribute for each product
  [setDefaults](state, action) {
    const { dietaryRestriction, snackType } = action.payload;
    if (state.order.recommendation !== null) {
      state.order.recommendation.products.forEach((pdtLine) => {
        // Check for dietary restrictions
        if (pdtLine.productLine === 'Milk Options') {
          if (
            dietaryRestriction === 'Lactose-intolerant' ||
            dietaryRestriction === 'Vegetarian'
          ) {
            pdtLine.items.forEach((milkOption) => {
              if (milkOption.description === 'Soy Milk') {
                milkOption.isSelected = true;
              }
            });
          } else {
            pdtLine.items.forEach((milkOption) => {
              if (milkOption.description === 'Regular Milk') {
                milkOption.isSelected = true;
              }
            });
          }
          // Check for snack preferences
        } else if (pdtLine.productLine === 'Snacks') {
          // if there's a snackType chosen in the quiz
          if (snackType) {
            if (snackType === 'Surprise me!') {
              const randNum = () =>
                Math.floor(Math.random() * pdtLine.items.length);
              let selectedSnackIdx = randNum();
              const noPastryIdx = pdtLine.items.findIndex(
                (snack) => snack.description.toLowerCase() === 'no pastry'
              );
              while (selectedSnackIdx === noPastryIdx) {
                selectedSnackIdx = randNum();
              }
              pdtLine.items.forEach((snackOption, idx) => {
                if (idx === selectedSnackIdx) {
                  snackOption.isSelected = true;
                }
              });
            } else {
              pdtLine.items.forEach((snackOption) => {
                const snack = snackOption.description.split(' ')[0];
                if (snack === snackType) {
                  snackOption.isSelected = true;
                }
              });
            }
            // if there isn't a snackType chosen in the quiz
          } else {
            pdtLine.items.forEach((snackOption) => {
              if (snackOption.description.toLowerCase() === 'no pastry') {
                snackOption.isSelected = true;
              }
            });
          }
        } else if (
          pdtLine.productLine === 'Strength' ||
          pdtLine.productLine === 'Sweetness'
        ) {
          pdtLine.items.forEach((snackOption) => {
            if (snackOption.description.toLowerCase() === 'normal') {
              snackOption.isSelected = true;
            }
          });
        } else if (pdtLine.productLine === 'Extra Shot') {
          pdtLine.items.forEach((snackOption) => {
            if (snackOption.description.toLowerCase() === 'regular') {
              snackOption.isSelected = true;
            }
          });
        }
      });
    }
    createSummary(state, action);
  },
  [setDeliveryFee](state, action) {
    state.order.deliveryFee = action.payload;
    createSummary(state, {
      payload: { firstMonth: state.customer.subscriptions.data.length === 0 }
    });
  },
  [setDiscount](state, action) {
    const { firstMonth, couponCode, data , nextMonth } = action.payload

    // reset
    state.order.discount.error = '';
    state.order.discount.percent = '';
    state.order.discount.amount = '';
    state.order.discount.coupon = '';
    state.order.discount.id = '';
    // set
    state.order.discount.coupon = couponCode;
    state.order.discount.id = data.id;
    state.order.discount.percent = data.coupon.percent_off;
    state.order.discount.amount = data.coupon.amount_off;
    state.order.discount.minimumAmount = data.minimum_amount;
    createSummary(state, { payload: { firstMonth , nextMonth } });
  },
  [setDiscountError](state, action) {
    const {firstMonth , nextMonth} = action.payload
    state.order.discount.error = action.payload.message;
    state.order.discount.percent = '';
    state.order.discount.amount = '';
    state.order.discount.coupon = '';
    state.order.discount.id = '';
    createSummary(state, { payload: { firstMonth , nextMonth } });
  },
  [removeDiscount](state, action) {
    const { firstMonth = true , nextMonth } = action.payload

    state.order.discount.error = '';
    state.order.discount.percent = '';
    state.order.discount.amount = '';
    state.order.discount.coupon = '';
    state.order.discount.id = '';
    createSummary(state, { payload: { firstMonth , nextMonth } });
  },
  [changeBestDeliveryDays](state, action) {
    state.order.deliveryDaySelection = action.payload;
  },
  [changeDeliveryTimeSlot](state, action) {
    state.order.deliveryTimeslot = action.payload;
  },
  [changePickupTimeSlot](state, action) {
    state.order.pickupTimeslot = action.payload;
  },
  [setCustomer](state, action) {
    createCustomer(state, action);
    createSummary(state, action);
  },
  [setStripeCustomer](state, action) {
    createCustomer(state, action);
  },
  [setConfirmChange](state, action) {
    createMyPlanSummary(state);
  },
  [setNextDelivery](state, action) {
    state.order.nextDeliveryObj = action.payload;
  },
  [setPurchaseReview](state, action) {
    createMyPlanSummary(state);
  },
  [setSpecialInstructions](state, action) {
    state.order.specialInstructions = action.payload;
  },
  [setOrderType](state, action) {
    state.order.orderType = action.payload;
  },
  [initOrderDetails](state , action) {
    state.order = {...initialState.order}
  }
});
