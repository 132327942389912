import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { changeBestDeliveryDays } from '../../../actions/Order';
import PickupDay from '../../../components/delivery-options/PickupDay';
import { validateDeliveryDays } from '../../../helpers/utils';

const SelectPickupDay = () => {
  const order = useSelector((state) => state.orderDetail.order);

  const dispatch = useDispatch();
  const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const pickupStore = useSelector(
    (state) =>
      state.addressDetail.pickupStores.filter(
        ({ addressDescription }) =>
          addressDescription === state.addressDetail.address.addressDescription
      )[0]
  );

  const validateDays = (values, err) => {
    if (
      !validateDeliveryDays(values.length, order.numberOfDeliveryDaysAllowed)
    ) {
      setDisabled(true);
      setErrorMessage(err);
    } else {
      setDisabled(false);
      setErrorMessage();
    }
  };

  const changeHandler = (values) => {
    validateDays(values, 'Plan frequency does not match days selected!');
    dispatch(changeBestDeliveryDays(values));
  };

  const handleContinueClick = () => {
    history.push('/select-pickup-time');
  };

  useEffect(() => {
    if (order.numberOfDeliveryDaysAllowed === 5) {
      dispatch(changeBestDeliveryDays([1, 2, 3, 4, 5]));
      handleContinueClick();
    }
    validateDays(order.deliveryDaySelection, '');
    //eslint-disable-next-line
  }, []);

  return (
    <PickupDay
      order={order}
      pickupStore={pickupStore}
      titleText={
        'Which day(s) would you like to pick up your drink from our store?'
      }
      onDaySelect={changeHandler}
      onContinueClick={handleContinueClick}
      disabled={disabled}
      errorMessage={errorMessage}
    />
  );
};

export default SelectPickupDay;
