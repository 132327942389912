import React, { useState } from 'react';
import styled from '@emotion/styled';
import validator from 'validator';
import { useHistory } from 'react-router-dom';

import { ArrowLeft, ArrowRight } from '../assets/icons';
import '../assets/styles/styles.css';
import { Input, Header, FooterButton, Footer, Wrapper } from '../components';
import { changePassword } from '../auth0';
import { _ErrorMessages as msg } from '../constants/ErrorMessages';
import { mq } from '../helpers/utils';

const LayoutStyled = styled('div')`
  display: grid;
  grid-template-areas:
    'header'
    'title'
    'content'
    'footer';
  grid-template-rows: auto auto 1fr auto;
  background-color: var(--au-lait);
  height: 100vh;
  width: 100%;
  row-gap: 40px;
`;

const ContentStyled = styled('div')`
  grid-area: content;
  padding: 0 33px;

  ${mq('lg')} {
    padding: 0;
  }
`;

const TitleStyled = styled('div')`
  grid-area: title;
  padding: 0 33px;
  line-height: 1;
  max-width: 340px;
  > br {
    display: block;
  }

  ${mq('lg')} {
    padding: 0;
    max-width: 404px;

    > br {
      display: none;
    }
  }
`;

const ForgotPassword = () => {
  const [loginEmail, setLoginEmail] = useState('');
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const history = useHistory();

  const handleEmailChange = (e) => {
    setEmailErrMsg('');
    setLoginEmail(validator.trim(e.target.value));
  };

  const handleForgotPassword = () => {
    if (loginEmail) {
      if (validator.isEmail(loginEmail)) {
        changePassword(loginEmail)
          .then(() => {
            history.push('/forgot-password-confirm');
          })
          .catch((err) => {
            setEmailErrMsg(err.error_description);
          });
      } else {
        setEmailErrMsg(msg.validEmail);
      }
    } else {
      setEmailErrMsg(msg.required);
    }
  };

  return (
    <>
      <Wrapper>
        <LayoutStyled>
          <Header
            headerMainClass='inter-body-15'
            text='Menu'
            icon={ArrowLeft}
          />
          <TitleStyled className='large-title'>
            {`Don’t worry, we’ll help `}
            <br />
            {`you to recover your password`}
          </TitleStyled>
          <ContentStyled>
            <Input
              placeholder='Email address'
              type='email'
              name='email'
              id='email'
              label='Email address'
              onChange={handleEmailChange}
              errMsg={emailErrMsg}
            />
          </ContentStyled>
          <Footer>
            <FooterButton
              icon={ArrowRight}
              textRight='Recover my password'
              actionRight={handleForgotPassword}
            />
          </Footer>
        </LayoutStyled>
      </Wrapper>
    </>
  );
};

export default ForgotPassword;
