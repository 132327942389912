import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { isLoggedIn } from '../auth0';
import { Link } from 'react-router-dom';
import { mq } from '../helpers/utils';
import { Facebook, Instagram, Linkedin, User } from '../assets/icons';
import BettrCloudBarLogo from '../assets/images/menu-logo.png';

const Container = styled.div`
  background-color: var(--au-lait-bgd-2);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  padding: 25px 30px;
  overflow: scroll;

  ${mq('lg')} {
    width: 404px;
    height: 614px;
    position: fixed;
    top: 22px;
    right: 34px;
  }

  a {
    color: var(--french);
    text-decoration: none;
  }
`;

const SubTitle = styled.div`
  text-transform: uppercase;
  line-height: 34px;
  margin: 10px 0;
  white-space: pre-line;
`;

const StyledBettr = styled.img`
  max-width: 202px;
  margin-bottom: 45px;

  ${mq('lg')} {
    margin-bottom: 20px;
  }
`;

const ProfileIcon = styled.img`
  vertical-align: bottom;
  margin-right: 10px;
`;

const LinksContainer = styled.div`
  margin: 35px 0 50px 0;

  p {
    margin: 10px 0;
  }

  ${mq('lg')} {
    margin: 30px 0;
  }
`;

const StyledIcon = styled.img`
  margin-right: 20px;
`;

const Menu = ({ action = () => console.log('menu') }, ref) => {
  const LANDING_PAGE_URL = process.env.REACT_APP_LANDING_PAGE_URL;
  const customer = useSelector((state) => state.orderDetail.customer);

  return (
    <Container ref={ref}>
      <div
        style={{ direction: 'rtl', cursor: 'pointer' }}
        className='inter-body-15-sb'
        onClick={action}
      >
        Close
      </div>
      <a href={LANDING_PAGE_URL}>
        <StyledBettr src={BettrCloudBarLogo} alt='Bettr Cloud Bar' />
      </a>
      <div>
        <Link
          to={isLoggedIn() ? (customer.id ? '/profile' : '/login') : '/login'}
        >
          <ProfileIcon src={User} alt='user' />
          <span className='inter-body-15-sb'>
            {isLoggedIn()
              ? customer.id
                ? 'My Profile'
                : 'Login / Register'
              : 'Login / Register'}
          </span>
        </Link>
      </div>
      <LinksContainer>
        <SubTitle className='large-title'>
          <Link to='/quiz-intro'>Customise My Plan</Link>
        </SubTitle>
        <SubTitle className='large-title'>
          <Link to='/feedback'>Get In Touch</Link>
        </SubTitle>
        <SubTitle className='large-title'>
          <a href={LANDING_PAGE_URL + '/about-us'}>About Us</a>
        </SubTitle>
        <a href={LANDING_PAGE_URL + '/our-impact'}>
          <p className='noto-body-15'>Our Impact</p>
        </a>
        <a href={LANDING_PAGE_URL + '/faq'}>
          <p className='noto-body-15'>FAQ</p>
        </a>
        <a href={LANDING_PAGE_URL + '/terms-of-service'}>
          <p className='noto-body-15'>Terms & Conditions</p>
        </a>
        <a href={LANDING_PAGE_URL + '/privacy-policy'}>
          <p className='noto-body-15'>Privacy Policy</p>
        </a>
      </LinksContainer>
      <div>
        <a href='https://www.facebook.com/bettrcoffee'>
          <StyledIcon src={Facebook} alt='facebook' />
        </a>
        <a href='https://www.instagram.com/bettrcoffee'>
          <StyledIcon src={Instagram} alt='instagram' />
        </a>
        <a href='https://www.linkedin.com/company/bettr-group'>
          <StyledIcon src={Linkedin} alt='linkedIn' />
        </a>
      </div>
    </Container>
  );
};

export default forwardRef(Menu);
