import React from 'react';
import styled from '@emotion/styled';

import { PlanBgd } from '../../assets/images/';

const SwirlStyled = styled('div')`
  background-image: url(${PlanBgd});
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => props.zIndex};
  width: 100%;
  height: 200px;
`;

const Swirl = ({ zIndex = 1 }) => {
  return <SwirlStyled zIndex={zIndex} />;
};

export default Swirl;
