import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import CoffeeList from './CoffeeList';
import { mq } from '../../helpers/utils';

const SectionItemContainer = styled.div`
  max-width: 464px;
  margin: 0 auto;
  padding: 40px 0px 33px 0;

  ${mq('lg')} {
    max-width: 100vw;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 23px;
  padding: 0 34px;

  ${mq('lg')} {
    max-width: 1039px;
    margin: 0 auto;
    padding: 0;
  }
`;

const Icon = styled.img`
  margin-right: 9px;
`;

const CoffeeSectionItem = ({ categoryName, items, icon, style }, ref) => {
  return (
    <SectionItemContainer ref={ref} style={style}>
      <TitleContainer>
        {icon && <Icon src={icon} alt='coffee' />}
        <div className='inter-body-15-sb'>{categoryName}</div>
      </TitleContainer>
      <CoffeeList list={items} />
    </SectionItemContainer>
  );
};

export default forwardRef(CoffeeSectionItem);
