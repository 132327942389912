import React from 'react';

import { Home } from '../../assets/icons';
import { PageWithImage } from '../../components';
import image from '../../assets/images/FeedbackThanksImage.svg';
import ReactPixel from "react-facebook-pixel";

const FeedbackThanks = () => {
  const goToLandingPage = () => {
    const LANDING_PAGE_URL = process.env.REACT_APP_LANDING_PAGE_URL;
    window.location.href = LANDING_PAGE_URL;
  }

  React.useEffect(() => {ReactPixel.pageView()}, [])

  return (
    <PageWithImage
      image={image}
      title="THANK YOU FOR GETTING IN TOUCH! WE'LL GET BACK TO YOU AS SOON AS POSSIBLE."
      footer1Text='Go to home page'
      footer1Icon={Home}
      footer1Action={goToLandingPage}
    />
  );
};

export default FeedbackThanks;
