import { createAction } from '@reduxjs/toolkit';

// export const changeStatus = createAction('USER/CHANGE_STATUS');
export const addressGotoNext = createAction(
  'ADDRESS/ADDRESS_CHANGE_SECTION_NEXT'
);
export const addressGotoPrevious = createAction(
  'ADDRESS/ADDRESS_CHANGE_SECTION_PREVIOUS'
);
export const addressResetGoto = createAction('ADDRESS/ADDRESS_RESET_SECTION');
export const setPostalCode = createAction('ADDRESS/ADDRESS_SET_POSTAL_CODE');
export const setUnitNumber = createAction('ADDRESS/ADDRESS_SET_UNIT_NUMBER');
export const setAddressString = createAction(
  'ADDRESS/ADDRESS_SET_ADDRESS_STRING'
);
export const setAddressDescription = createAction(
  'ADDRESS/ADDRESS_SET_ADDRESS_DESCRIPTION'
);
export const setStreetName = createAction('ADDRESS/ADDRESS_SET_STREET_NAME');
export const initAddress = createAction('ADDRESS/INIT_ADDRESS_DETAILS');
