import React from 'react';
import { useDispatch } from 'react-redux';

import { quizSetDrinkingFrequency, quizGotoNext } from '../../actions/Quiz';
import {
  StyledVerticalOption,
  StyledVerticalMenu,
  Title,
  Content
} from '../../components';
import {
  Calendar1,
  Calendar2,
  Calendar3,
  Calendar4,
  Calendar5
} from '../../assets/icons';
import ReactPixel from "react-facebook-pixel";

const DrinkingFrequency = (props) => {
  const dispatch = useDispatch();

  const selectHandler = (e) => {
    dispatch(quizSetDrinkingFrequency(e.target.value));
    dispatch(quizGotoNext());
  };

  React.useEffect(() => {ReactPixel.pageView()}, [])

  return (
    <>
      <Title title='HOW OFTEN DO YOU HAVE YOUR FAVOURITE DRINK?' />
      <Content style={{ marginTop: '20px' }}>
        <StyledVerticalMenu displayCheckbox={false} onChange={selectHandler}>
          <StyledVerticalOption value={'20-drinks'}>
            <img src={Calendar5} alt='selection-option' />
            Every day
          </StyledVerticalOption>
          <StyledVerticalOption value={'16-drinks'}>
            <img src={Calendar4} alt='selection-option' />4 days / week
          </StyledVerticalOption>
          <StyledVerticalOption value={'12-drinks'}>
            <img src={Calendar3} alt='selection-option' />3 days / week
          </StyledVerticalOption>
          <StyledVerticalOption value={'8-drinks'}>
            <img src={Calendar2} alt='selection-option' />2 days / week
          </StyledVerticalOption>
          <StyledVerticalOption value={'4-drinks'}>
            <img src={Calendar1} alt='selection-option' />1 day / week
          </StyledVerticalOption>
        </StyledVerticalMenu>
      </Content>
    </>
  );
};

export default DrinkingFrequency;
