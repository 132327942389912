import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import CoffeeItem from './CoffeeItem';
import { setFavouriteCoffee } from '../../actions/Coffee';
import { mq } from '../../helpers/utils';

const CoffeeListContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${mq('lg')} {
    overflow: initial;
    max-width: 1039px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const PaddingChild = styled.div`
  flex: 0 0 34px;

  ${mq('lg')} {
    display: none;
  }
`;

const CoffeeList = ({ list = [], style }, ref) => {
  const dispatch = useDispatch();

  return (
    <CoffeeListContainer ref={ref} style={style}>
      <PaddingChild />
      {list.map((coffee) => (
        <CoffeeItem
          key={coffee.productId}
          {...coffee}
          action={() => {
              dispatch(setFavouriteCoffee(coffee.productId));
              ReactPixel.trackCustom('coffee', {coffee: coffee.productId})
          }}
        />
      ))}
      <PaddingChild />
    </CoffeeListContainer>
  );
};

export default forwardRef(CoffeeList);
