import React, {useRef, useState} from 'react';
import styled from '@emotion/styled';
import {Radio as AntRadio} from 'antd';

const StyledRadio = styled.div`
  .ant-radio-wrapper {
    display: block;
    height: 95px;
    margin-top: 10px;
    background: #ffffff;
    font-family: Noto Serif;
    font-size: 15px;
    border-radius: 6px;
    white-space: normal;
  }

  .ant-radio-wrapper-checked {
    background: #ffc200;
  }

  .ant-radio-wrapper > span {
    &:last-child {
      margin-top: 37px;
      margin-left: 30px;
      position: absolute;
      display: flex;
      align-items: center;
    }
  }

  .ant-radio-wrapper > span > img {
    padding-right: 9px;
  }

  .ant-radio {
    position: relative;
    display: ${(props) => (props.displayCheckbox ? 'block' : 'none')};
    right: 89px;
    float: right;
  }

  .ant-radio-inner {
    margin-top: 37px;
    margin-left: 30px;
    position: absolute;
    display: flex;
    align-items: center;
    background: #f9f6e8;
    border: none;
    width: 26px;
    height: 26px;
    &:after {
      position: relative;
      top: 7px;
      left: 7px;
      background: #ffc200;
      width: 12px;
      height: 12px;
    }
  }
`;

const StyledVerticalMenu = (props, ref) => {
  const { placeholder, style, ...rest } = props;
  const node = useRef();
//eslint-disable-next-line
  const [value, setValue] = useState('');

  const onChange = (e) => {
    props.onChange(e);
    setValue(e.target.value);
  };

  return (
    <StyledRadio displayCheckbox={props.displayCheckbox} ref={node}>
      <AntRadio.Group
        defaultValue={props.defaultValue}
        onChange={onChange}
        {...rest}
        style={{ width: '100%', ...style }}
      >
        {props.children}
      </AntRadio.Group>
    </StyledRadio>
  );
};

export default StyledVerticalMenu;
