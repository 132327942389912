import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Footer,
  FooterButton,
  Input,
  Content,
  Title
} from '../../../components';
import { ArrowRight } from '../../../assets/icons';
import { addressGotoNext } from '../../../actions/Address';
import { setPostalCode } from '../../../actions/Address';
import { validatePostalCode } from '../../../helpers/utils';
import { _ErrorMessages as msg } from '../../../constants/ErrorMessages';

const PostalCodeSection = (props) => {
  const dispatch = useDispatch();

  const address = useSelector((state) => state.addressDetail.address);

  const [postalErrMsg, setPostalErrMsg] = useState('');

  const validationsHandler = async () => {
    if (!(await validatePostalCode(address.postalCode))) {
      setPostalErrMsg(msg.invalidPostalCode);
    } else {
      setPostalErrMsg(null);
      dispatch(addressGotoNext());
    }
  };

  return (
    <>
      <Title className='large-title' title={'What is your postal code?'} />
      <Content>
        <Input
          placeholder='Postal Code'
          label='Postal Code'
          onChange={(e) => {
            dispatch(setPostalCode(e.target.value));
          }}
          value={address.postalCode}
          errMsg={postalErrMsg}
          name={'Postal Code'}
          type='text'
        />
      </Content>
      <Footer>
        <FooterButton
          style={{ backgroundColor: 'var(--bettr-yellow' }}
          icon={ArrowRight}
          textRight='Check my address'
          actionRight={() => {
            validationsHandler();
          }}
          disabled={!address.postalCode}
        />
      </Footer>
    </>
  );
};

export default PostalCodeSection;
