import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import '../../assets/styles/styles.css';
import { DeliveryDay } from '../../components';
import { changeBestDeliveryDays } from '../../actions/Order';
import { validateDays } from '../../helpers/utils';
import ReactPixel from "react-facebook-pixel";

const ChangeDeliveryDay = () => {
  const order = useSelector((state) => state.orderDetail.order);

  const dispatch = useDispatch();
  const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const changeHandler = (values) => {
    validateDays(values, 'Plan frequency does not match days selected!', order, setDisabled, setErrorMessage);
    ReactPixel.trackCustom("ChangeDeliveryDays", {values})
    dispatch(changeBestDeliveryDays(values));
  };

  const handleContinueClick = () => {
    history.push('/manage/change-delivery-timeslot');
  };

  const handleBackAction = () => history.push('/manage/change-plan');

  useEffect(() => {
    validateDays(order.deliveryDaySelection, '', order, setDisabled, setErrorMessage);
    ReactPixel.pageView()
    //eslint-disable-next-line
  }, []);

  return (
    <DeliveryDay
      order={order}
      titleText="SELECT THE DAY(S) YOU'D LIKE YOUR DRINKS DELIVERED."
      onDaySelect={changeHandler}
      onContinueClick={handleContinueClick}
      backAction={handleBackAction}
      disabled={disabled}
      errorMessage={errorMessage}
    />
  );
};
export default ChangeDeliveryDay;
