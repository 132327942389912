import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {Content, Footer, FooterButton, Header, Notification, Title, Wrapper} from '../../components';
import CreditCardCard from './components/CreditCardCard';
import {CreditcardAdd} from '../../assets/icons';
import {deleteUserCreditCard, getUserCreditCards, setDefaultCreditCard} from '../../actions/Payments';

const PaymentMethod = () => {
  const [creditCards, setCreditCards] = useState([]);
  const [notification, setNotification] = useState(null);

  let history = useHistory();
  const dispatch = useDispatch();

  const updateMessage =
    'You have updated your payment method. This card will be billed from your next subscription cycle onwards.';
  const deleteMessage =
    'You have deleted one of your cards. You can always add a new card by clicking on the button below.';

  // TODO: fetch user jtoken once implemented
  const userToken = 'cus_IOlU5ka2UZxZGQ';
  
  const fetchUserCreditCards = async () => {
    try {
      const response = await dispatch(getUserCreditCards(userToken));
      if (response.length > 0) {
        setCreditCards(response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserCreditCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectHandler = async (card, index) => {
    if (!card.isDefault) {
      console.log('customerId', userToken);
      try {
        await dispatch(
          setDefaultCreditCard(userToken, card.id)
        );
        await fetchUserCreditCards();
        // if (response) {
          
        //   // const defaultCardIndex = creditCards.findIndex(
        //   //   (card) => card.isDefault === true
        //   // );
        //   // const updatedCard = { ...card, isDefault: true };
        //   // const updatedArr = [
        //   //   ...creditCards.slice(0, index),
        //   //   updatedCard,
        //   //   ...creditCards.slice(index + 1)
        //   // ];
        //   // updatedArr[defaultCardIndex].isDefault = false;
        //   // setCreditCards(updatedArr);
        //   // setNotification('update');
        // }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const deleteCard = async (id, index) => {
    try {
      const response = await dispatch(deleteUserCreditCard(userToken, id));
      if (response) {
        const updatedArr = [
          ...creditCards.slice(0, index),
          ...creditCards.slice(index + 1)
        ];
        setCreditCards(updatedArr);
        setNotification('delete');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addCardHandler = () => {
    history.push('/payment-methods/add-card?action=setCard');
  };

  return (
    <Wrapper>
      <Header />
      <Title title='My Cards' />
      <Content>
        {creditCards.map((card, index) => (
          <CreditCardCard
            key={card.id}
            card={card}
            index={index}
            selectHandler={selectHandler}
            deleteHandler={deleteCard}
          />
        ))}
      </Content>
      {notification && (
        <Notification
          text={notification !== 'delete' ? updateMessage : deleteMessage}
          type={notification}
          action={() => setNotification(null)}
        />
      )}
      <Footer>
        <FooterButton
          icon={CreditcardAdd}
          textRight='Add a new Card'
          actionRight={addCardHandler}
        />
      </Footer>
    </Wrapper>
  );
};

export default PaymentMethod;
