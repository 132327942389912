import {createReducer} from '@reduxjs/toolkit';
import {
  initQuiz,
  quizGotoNext,
  quizGotoPrevious,
  quizSetConsentGiven,
  quizSetDietaryRestriction,
  quizSetDrinkingFrequency,
  quizSetEmail,
  quizSetHasDietaryRestriction,
  quizSetHasSnackWithDrink,
  quizSetHotOrIced,
  quizSetName,
  quizSetPreferredDrink,
  quizSetSnack
} from '../actions/Quiz';

const initialState = {
  quiz: {
    name: '',
    quizStage: 1,
    preferredDrink: '',
    drinkingFrequency: '',
    hasDietaryRestriction: false,
    dietaryRestriction: '',
    hotOrIced: '',
    hasSnackWithDrink: false,
    snack: '',
    email: '',
    consentGiven: false
  }
};

export default createReducer(initialState, {
  [quizSetName](state, action) {
    state.quiz.name = action.payload;
  },
  [quizGotoNext](state, action) {
    state.quiz.quizStage = state.quiz.quizStage + 1;
  },
  [quizGotoPrevious](state, action) {
    state.quiz.quizStage = state.quiz.quizStage - 1;
  },
  [quizSetPreferredDrink](state, action) {
    state.quiz.preferredDrink = action.payload;
  },
  [quizSetDrinkingFrequency](state, action) {
    state.quiz.drinkingFrequency = action.payload;
  },
  [quizSetHasDietaryRestriction](state, action) {
    state.quiz.hasDietaryRestriction = action.payload;
  },
  [quizSetDietaryRestriction](state, action) {
    state.quiz.dietaryRestriction = action.payload;
  },
  [quizSetHotOrIced](state, action) {
    state.quiz.hotOrIced = action.payload;
  },
  [quizSetHasSnackWithDrink](state, action) {
    state.quiz.hasSnackWithDrink = action.payload;
  },
  [quizSetSnack](state, action) {
    state.quiz.snack = action.payload;
  },
  [quizSetEmail](state, action) {
    state.quiz.email = action.payload;
  },
  [quizSetConsentGiven](state, action) {
    state.quiz.consentGiven = action.payload;
  },
  [initQuiz](state, action) {
    state.quiz = {...initialState.quiz}
  }
});
