import { useDispatch } from 'react-redux';
import React from 'react';
import { Home, Laptop, Coffee, Presentation } from '../../../assets/icons';
import {
  StyledVerticalMenu,
  StyledVerticalOption,
  Title,
  Content
} from '../../../components';
import {
  addressGotoNext,
  setAddressDescription
} from '../../../actions/Address';

const DescribeAddressSection = () => {
  const dispatch = useDispatch();

  const changeHandler = (e) => {
    dispatch(setAddressDescription(e.target.value));
    dispatch(addressGotoNext());
  };

  return (
    <div>
      <Title className='large-title' title='Please describe this address.' />
      <Content>
        <StyledVerticalMenu displayCheckbox={false} onChange={changeHandler}>
          <StyledVerticalOption value={'Home'}>
            <img src={Home} alt='selection-option' />
            Home
          </StyledVerticalOption>
          <StyledVerticalOption value={'Work / Office'}>
            <img src={Laptop} alt='selection-option' />
            Work / Office
          </StyledVerticalOption>
          <StyledVerticalOption value={'My coffee spot'}>
            <img src={Coffee} alt='selection-option' />
            My coffee spot
          </StyledVerticalOption>
          <StyledVerticalOption value={'Study island'}>
            <img src={Presentation} alt='selection-option' />
            Study island
          </StyledVerticalOption>
        </StyledVerticalMenu>
      </Content>
    </div>
  );
};

export default DescribeAddressSection;
