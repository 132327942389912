import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, persistor, history } from './store';
import App from './App';
import './assets/styles/styles.css';
import 'antd/dist/antd.css';
import { PersistGate } from 'redux-persist/integration/react';

const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <div>
          <App />
        </div>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  target
);
