import { createReducer } from '@reduxjs/toolkit';

import {
  setFavouriteCoffee,
  setFavCoffeeChangePlan, initCoffeeDetails, setCoffeeTypes
} from '../actions/Coffee';

const initialState = {
  coffeeTypes: [],
  favouriteCoffee: '',
  favouriteCoffeeChangePlan:""
};

export default createReducer(initialState, {
  [setFavouriteCoffee](state, action) {
    state.coffeeTypes.forEach((category) => {
      category.items.forEach((item) => {
        if (item.productId === action.payload) {
          if (item.isSelected) {
            item.isSelected = false;
            state.favouriteCoffee = '';
          } else {
            item.isSelected = true;
            state.favouriteCoffee = action.payload;
          }
        } else {
          item.isSelected = false;
        }
      });
    });
  },
  [setCoffeeTypes](state, action) {
    state.coffeeTypes = action.payload;
  },
  [setFavCoffeeChangePlan](state, action) {
    state.favouriteCoffeeChangePlan = action.payload;
  },
  [initCoffeeDetails](state , action) {
    state.coffeeTypes = {...initialState.coffeeTypes}
    state.favouriteCoffee = {...initialState.favouriteCoffee}
  }
});
