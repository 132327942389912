import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userDetail from './UserDetail';
import addressDetail from './AddressDetail';
import coffee from './CoffeeDetail';
import orderDetail from './OrderDetail';
import quizInput from './QuizInput';
import loader from './LoaderDetail';
import feedbackDetail from './FeedbackDetail';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    addressDetail,
    userDetail,
    coffee,
    orderDetail,
    quizInput,
    loader,
    feedbackDetail
  });
export default createRootReducer;
