import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DrinksMenu } from '../components';
import { setCoffeesTypes } from '../actions/Order';
import { quizSetDrinkingFrequency } from '../actions/Quiz';

import ReactPixel from 'react-facebook-pixel';

const CoffeeType = (props) => {
  const dispatch = useDispatch();
  const { preferredDrink, hotOrIced } = useSelector(
    (state) => state.quizInput.quiz
  );
  const { coffeeTypes, favouriteCoffee } = useSelector((state) => state.coffee);
  const history = useHistory();
  const query = new URLSearchParams(props.location.search);
  const redirect = query.get('redirect');

  useEffect(() => {
    const getData = async () => {
      const index = history.location?.search?.lastIndexOf('=');
      const drinkFreq = history.location?.search?.slice(index + 1);
      if (drinkFreq) {
        await dispatch(quizSetDrinkingFrequency(drinkFreq));
      }
      await dispatch(
          setCoffeesTypes({
            drinkType:
                preferredDrink === '' || redirect
                    ? 'no-preference'
                    : preferredDrink,
            coffeeTemperature:
                hotOrIced === '' || redirect ? 'no-preference' : hotOrIced
          })
      );
    };
    getData();
    //eslint-disable-next-line
  }, [dispatch, hotOrIced, preferredDrink, props.location.search]);

  useEffect(() => {ReactPixel.pageView()}, [])

  const handleContinue = () => history.push('/plan');

  const showAll = () => {
    history.push('coffee-type?redirect=landing');
  };

  return (
    <DrinksMenu
      coffeeTypes={coffeeTypes}
      favouriteCoffee={favouriteCoffee}
      onContinueClick={handleContinue}
      showAll={showAll}
      redirectKey={redirect}
    />
  );
};

export default CoffeeType;
