import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { setAddOn } from '../../../actions/Order';
import { Option, Subscription } from './HorizontallyScrollableItem';
import { mq } from '../../../helpers/utils';

const Container = styled('div')`
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin: 26px 0 44px 0;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${mq('lg')} {
    flex-wrap: wrap;

    > div:nth-of-type(2n) {
      margin-right: 0;
    }
  }
`;

const HorizontallyScrollableList = ({
  list = [],
  productLine,
  firstMonth,
  style,
                                      nextMonth,
    removePromocodeAction
}) => {
  const dispatch = useDispatch();
  const handleOptionClick = (planId) => {
    removePromocodeAction(true)
    dispatch(setAddOn({ productLine, planId, firstMonth , nextMonth }));
  };

  return productLine === 'Subscription Plan' ? (
    <Container style={style}>
      {list.map((item) => (
        <Subscription
          key={item.planId}
          {...item}
          action={() => handleOptionClick(item.planId)}
        />
      ))}
    </Container>
  ) : (
    <Container style={style}>
      {list.map((item) => (
        <Option
          key={item.planId}
          {...item}
          action={() => handleOptionClick(item.planId)}
        />
      ))}
    </Container>
  );
};

export default HorizontallyScrollableList;
