import React from 'react';
import { useHistory } from 'react-router-dom';

import { Wrapper, PageWithImage } from '../components';
import { PersonWithKey } from '../assets/images/';
import { Lock } from '../assets/icons';

const NewPasswordConfirm = () => {
  const history = useHistory();

  const gotoHomepage = () => {
    history.push('/login');
  };

  return (
    <>
      <Wrapper style={{ backgroundColor: 'white' }}>
        <PageWithImage
          image={PersonWithKey}
          title='your password was re-set. Please wait until we redirect you to the login page.'
          footer1Icon={Lock}
          footer1Action={gotoHomepage}
          footer1Text='Click here to Login'
          noBack
        />
      </Wrapper>
    </>
  );
};

export default NewPasswordConfirm;
