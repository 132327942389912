import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { quizGotoPrevious } from '../../actions/Quiz';
import Flow from '../../components/onboarding/Flow';
import NamePrompt from './NamePrompt';
import PreferredDrink from './PreferredDrink';
import DrinkingFrequency from './DrinkingFrequency';
import DietaryRestrictions from './DietaryRestrictions';
import HotOrIced from './HotOrIced';
import Snack from './Snack';
import EmailPrompt from './EmailPrompt';
import { Wrapper } from '../../components';

const Quiz = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const currentSection = useSelector((state) => state.quizInput.quiz.quizStage);

  const handleBack = () =>
    currentSection > 1 ? dispatch(quizGotoPrevious()) : history.goBack();

  return (
    <Wrapper>
      <Flow
        currentSection={currentSection}
        onBackButtonClick={() => handleBack()}
      >
        <NamePrompt />
        <PreferredDrink />
        <DrinkingFrequency />
        <DietaryRestrictions />
        <HotOrIced />
        <Snack />
        <EmailPrompt />
      </Flow>
    </Wrapper>
  );
};

export default Quiz;
