import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { _ErrorMessages as msg } from '../../constants/ErrorMessages';
import { ArrowRight } from '../../assets/icons';
import { signupGotoNext, signupSetName } from '../../actions/User';
import { Input, FooterButton, Footer, Title, Content } from '../../components';

const NameSection = () => {
  const currentUser = useSelector((state) => state.userDetail.user);
  const quiz = useSelector((state) => state.quizInput.quiz);
  const [nameErrMsg, setNameErrMsg] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signupSetName(quiz.name));
    //eslint-disable-next-line
  }, []);

  const onChangeHandler = (e) => {
    dispatch(signupSetName(e.target.value.trim()));
  };

  const validationsHandler = () => {
    if (!currentUser.name) {
      setNameErrMsg(msg.required);
    } else {
      dispatch(signupGotoNext());
      setNameErrMsg(null);
    }
  };

  return (
    <div>
      <Title title='May we know your name?' />
      <Content style={{ marginTop: '20px' }}>
        <Input
          placeholder='Name'
          onChange={onChangeHandler}
          type='text'
          value={currentUser.name}
          errMsg={nameErrMsg}
          name='Name'
          label='Name'
        />
      </Content>

      <Footer>
        <FooterButton
          icon={ArrowRight}
          textRight='Continue'
          actionRight={() => {
            validationsHandler();
          }}
        />
      </Footer>
    </div>
  );
};

export default NameSection;
