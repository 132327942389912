import { axiosRequest } from '../helpers/request';

const getPaymentIntent = async () => {
  const url = `/api/payments/get-intent`;
  const response = await axiosRequest.get(url).exec();
  return response;
};

const bindCustomer = async (body) => {
  const url = `/api/payments/bind-customer`;
  const response = await axiosRequest.post(url, body).exec();
  return response;
};

const getDiscount = async (couponCode) => {
  const url = `/api/coffee-types/${couponCode}/coupon`;
  const response = await axiosRequest.get(url).exec();
  return response;
};

const getDeliveryFee = async (postalCode) => {
  const url = `/api/payments/${postalCode}/delivery-cost`;
  const response = await axiosRequest.get(url).exec();
  return response;
};

const getCreditCards = async (id) => {
  const url = '/api/payments/methods/' + id;
  const response = await axiosRequest.get(url).exec();
  return response;
};

const setDefaultCard = async (body) => {
  const url = `/api/payments/method/set-default`;
  const response = await axiosRequest.post(url, body).exec();
  return response;
};

const deleteCreditCard = async (body) => {
  const url = `/api/payments/methods/delete`;
  const response = await axiosRequest.post(url, body).exec();
  return response;
};

const subscription = async (body) => {
  const url = `/api/payments/subscribe`;
  const response = await axiosRequest.post(url, body).exec();
  return response;
};

const pauseSubscription = async (sub_id) => {
  const url = `/api/payments/${sub_id}/pause`;
  const response = await axiosRequest.post(url).exec();
  return response;
};

const cancelSubscription = async (sub_id) => {
  const url = `/api/payments/${sub_id}/cancel`;
  const response = await axiosRequest.post(url).exec();
  return response;
};

const resumeSubscription = async (sub_id) => {
  const url = `/api/payments/${sub_id}/resume`;
  const response = await axiosRequest.post(url).exec();
  return response;
};

const updateSubscription = async (body) => {
  const url = `/api/payments/subscription/update`;
  const response = await axiosRequest.post(url, body).exec();
  return response;
};

export const paymentService = {
  getPaymentIntent,
  getDiscount,
  bindCustomer,
  getDeliveryFee,
  getCreditCards,
  setDefaultCard,
  deleteCreditCard,
  subscription,
  pauseSubscription,
  updateSubscription,
  resumeSubscription,
  cancelSubscription
};
