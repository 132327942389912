import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import {
  Title,
  Content,
  Wrapper,
  Header,
  StyledVerticalMenu,
  StyledVerticalOption
} from '../../components';
import { ArrowLeft } from '../../assets/icons';
import { createFeedbackTopic } from '../../actions/Feedback';

const ContactTopic = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const changeHandler = (e) => {
    console.log('option checked', e.target.value);
    dispatch(createFeedbackTopic(e.target.value));
    // let goToNext = e.target.value;
    history.push('/feedback-info');

    // if (goToNext >= 1 && goToNext <= 9) {
    // }
  };

  React.useEffect(() => {ReactPixel.pageView()}, [])

  return (
    <>
      <Wrapper>
        <Header headerMainClass='inter-body-15' text='Menu' icon={ArrowLeft} />
        <div>
          <Title title='HOW CAN WE HELP?' />
          <Content style={{ paddingBottom: '13px' }}>
            <div className='noto-body-15'>
              Please select a topic below related to your enquiry.
            </div>
          </Content>
          <Content>
            <StyledVerticalMenu
              displayCheckbox={false}
              onChange={changeHandler}
            >
              <StyledVerticalOption value={'Question about plans'}>
                Question about plans
              </StyledVerticalOption>
              <StyledVerticalOption value={'Change plan/profile details'}>
                Change plan/profile details
              </StyledVerticalOption>
              <StyledVerticalOption value={'Question about menu'}>
                Question about menu
              </StyledVerticalOption>
              <StyledVerticalOption value={'Delivery matters'}>
                Delivery matters
              </StyledVerticalOption>
              <StyledVerticalOption value={'Gifting'}>
                Gifting
              </StyledVerticalOption>
              <StyledVerticalOption value={'Bulk/Corporate Plans'}>
                Bulk/Corporate Plans
              </StyledVerticalOption>
              <StyledVerticalOption value={'Collaborations'}>
                Collaborations
              </StyledVerticalOption>
              <StyledVerticalOption value={'Feedback'}>
                Feedback
              </StyledVerticalOption>
              <StyledVerticalOption value={'Other'}>Other</StyledVerticalOption>
            </StyledVerticalMenu>
          </Content>
        </div>
      </Wrapper>
    </>
  );
};

export default ContactTopic;
