import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {getCustomer, getRecommendation, removeDiscount, toggleLoading} from '../../actions/Order';
import {Plan} from '../../components';
import {getDeliveryFee, getDiscount} from '../../actions/Payments';
import {getFaqList} from "../../actions/User";
import {fetchFavCofe} from "../../actions/Coffee";
import ReactPixel from "react-facebook-pixel";

const ChangePlan = () => {
  const dispatch = useDispatch();
  const {loader} = useSelector((state) => state.loader);
  const {recommendation, summary, snacks, discount} = useSelector(
      (state) => state.orderDetail.order
  );
  const [loading , setLoading] = useState(false);
  const { favouriteCoffeeChangePlan , favouriteCoffee } = useSelector((state) => state.coffee);
  const { drinkingFrequency } = useSelector((state) => state.quizInput.quiz);
  const subscriptions = useSelector(
    (state) => state.orderDetail.customer.subscriptions
  );
  const {address } = useSelector(
    (state) => state.orderDetail.customer
  );
  const postalCode = address.postal_code;
  let subTotal;
  if (summary[summary.length - 1]?.raw) {
    subTotal = +summary[summary.length - 1]?.raw;
  }

  useEffect(() => {
    const getData = async () => {
      await dispatch(toggleLoading(true));
      await dispatch(getDeliveryFee(postalCode));
      await dispatch(getFaqList('planFAQList'));
      await dispatch(removeDiscount({firstMonth: false, nextMonth: true}));
      await dispatch(fetchFavCofe())


      await dispatch(toggleLoading(false));
    };
    getData();
    ReactPixel.pageView()
    //eslint-disable-next-line
  }, []);


  useEffect(() => {
    const getData = async () => {
      if (favouriteCoffeeChangePlan) {
        setLoading(true)
        if (!recommendation) {
          await dispatch(
              getRecommendation(
                  favouriteCoffeeChangePlan,
                  {frequency: drinkingFrequency || '16-drinks', subscription: subscriptions.data[0].id},
                  {firstMonth: false, delivery: true, nextMonth: true}
              )
          );
        } else {
          if (subscriptions.data.length === 0) {
            await dispatch(getCustomer(false, true, true));
          }
          if(favouriteCoffeeChangePlan !== favouriteCoffee) {
            await dispatch(
                getRecommendation(
                    favouriteCoffee,
                    {frequency: drinkingFrequency || '16-drinks', subscription: subscriptions.data[0].id},
                    {firstMonth: false, delivery: true, nextMonth: true}
                )
            );
          }

        }
        setLoading(false)
      }
    }
    getData();
    //eslint-disable-next-line
  }, [favouriteCoffeeChangePlan])

  const history = useHistory();
  const handleContinue = () => history.push('/manage/confirm-change');
  const handleCoffeeChange = () => history.push('/manage/change-coffee');
  const handleBackButton = () => history.goBack();

  const faqList = useSelector((state) => state.userDetail.user.faqList);

  return (
    <Plan
      snacks={snacks}
      recommendation={recommendation}
      summary={summary}
      planFAQList={faqList}
      onContinueClick={handleContinue}
      onCoffeeChange={handleCoffeeChange}
      promoCode
      promoCodeAction={getDiscount}
      promoCodeRemoveAction={removeDiscount}
      promoCodeError={discount.error}
      promoCodeMessage={
        discount.error? discount.error : (discount.percent || discount.amount) && 'Coupon code applied'
      }
      loading={loader || loading}
      backAction={handleBackButton}
      managePlan
      discount={(discount.percent || discount.amount)}
      firstMonth={false}
      totalMonthly={subTotal}
      nextMonth={true}
    />
  );
};

export default ChangePlan;
