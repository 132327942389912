import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { ArrowLeft } from '../assets/icons';
import { Button } from 'antd';

const BackButton = styled(Button)`
  margin-top: 10px;
  font-size: 20px;
`;
const StyledBackButton = (props, ref) => {
  return (
    <BackButton ref={ref} type='link' onClick={props.action}>
      <img alt='back-button' src={ArrowLeft} />
    </BackButton>
  );
};

export default forwardRef(StyledBackButton);
