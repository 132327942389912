import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  quizSetHasDietaryRestriction,
  quizSetDietaryRestriction,
  quizGotoNext
} from '../../actions/Quiz';
import {
  StyledVerticalOption,
  StyledVerticalMenu,
  StyledHorizontalMenu,
  Title,
  Content
} from '../../components';
import ReactPixel from "react-facebook-pixel";

const DietaryRestrictions = (props) => {
  const [hasDietaryRestriction, setHasDietaryRestriction] = useState(false);

  const dispatch = useDispatch();

  const hasRestrictionHandler = (e) => {
    dispatch(quizSetHasDietaryRestriction(e.target.value));
    if (e.target.value) {
      setHasDietaryRestriction(e.target.value);
    } else {
      dispatch(quizGotoNext());
    }
  };

  React.useEffect(() => {ReactPixel.pageView()}, [])

  const setRestrictionHandler = (e) => {
    dispatch(quizSetDietaryRestriction(e.target.value));
    dispatch(quizGotoNext());
  };

  return (
    <div>
      <Title
        title={
          hasDietaryRestriction
            ? 'What dietary restrictions do you have?'
            : 'Do you have any dietary restrictions?'
        }
      />
      <Content style={{ marginTop: '20px' }}>
        {hasDietaryRestriction ? (
          <StyledVerticalMenu
            displayCheckbox={false}
            onChange={setRestrictionHandler}
          >
            <StyledVerticalOption value={'Lactose-intolerant'}>
              Lactose-intolerant
            </StyledVerticalOption>
            <StyledVerticalOption value={'Vegetarian'}>
              Vegetarian
            </StyledVerticalOption>
            <StyledVerticalOption value={'None of the above'}>
              None of the above
            </StyledVerticalOption>
          </StyledVerticalMenu>
        ) : (
          <StyledHorizontalMenu
            displayCheckbox={false}
            onChange={hasRestrictionHandler}
          >
            <StyledVerticalOption value={true}>Yes</StyledVerticalOption>
            <StyledVerticalOption value={false}>No</StyledVerticalOption>
          </StyledHorizontalMenu>
        )}
      </Content>
    </div>
  );
};

export default DietaryRestrictions;
