import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';
import {useHistory} from 'react-router-dom';
import {CardElement, Elements, injectStripe, StripeProvider} from 'react-stripe-elements';

import '../../assets/styles/styles.css';
import {Content, Footer, FooterButton, Header, Title, Wrapper} from '../../components';
import {ArrowRight} from '../../assets/icons';
import {getStripeCustomer, toggleLoading} from './../../actions/Order';
import {getPaymentIntent, subscribeToPlan} from '../../actions/Payments';
import {changeCardOwnerName} from "../../actions/User";
import ReactPixel from "react-facebook-pixel";

const FooterPayment = styled(Footer)`
  left:0px; !important;
`;

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#c23d4b'
      }
    }
  };
};

const styles = {
  input: {
    display: 'block',
    margin: '10px 0 20px 0',
    maxWidth: '500px',
    padding: '10px 14px',
    border: 'none',
    backgroundColor: 'white',
    outline: 'none',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '16px',
    color: '#424770',
    letterSpacing: '0.025em',
    '::placeholder': {
      color: '#ff0019',
    }

  }
};

const _CardForm = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const order = useSelector((state) => state.orderDetail);

  const pathname =  useSelector((state) => state.router.location.pathname);

  const customer = useSelector((state) => state.orderDetail.customer);

  const cardOwnerName = useSelector((state) => state.userDetail.user.cardOwnerName);

  const processingPayment =
    useSelector((state) => state.loader.loader) || false;

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = ({ error }) => {
    console.log(error);
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage('');
    }
  };

  const handleSubmit = async (evt) => {
    // evt.preventDefault();
    const total = order.order.summary[order.order.summary.length - 1].value.replace('$', "");

    if (props.stripe && errorMessage === '') {
      await dispatch(toggleLoading(true));
      const cardElement = props.elements.getElement('card');
      await dispatch(getPaymentIntent(cardElement, props.stripe, customer.id, cardOwnerName));
      if (pathname !== "/payment-methods/add-card") {
        const subscriptionAttempt = await dispatch(
            subscribeToPlan(order.order)
        );
        await dispatch(toggleLoading(false));
        if (subscriptionAttempt) {
          history.push({
            pathname: '/payment-success', state: {
              total: total
            }
          });
        } else {
          setErrorMessage('Please check the credit card details!');
        }
      } else {
        await dispatch(toggleLoading(false));
        history.push('/payment-methods');
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };


  return (
    <Wrapper>
      <div className='CardDemo'>
        <form onSubmit={handleSubmit}>
          <label>
            Card details
            <CardElement
              hidePostalCode={true}
              onChange={handleChange}
              {...createOptions()}
            />
          </label>
          <label>
            Name
            <input name="name" type="text" placeholder="Name on card" required
                   style={styles.input}
                   onChange={(e) => {
                     dispatch(changeCardOwnerName(e.target.value));
                   }}
            />
          </label>
          <div style={{ color: 'red' }} className='error' role='alert'>
            {errorMessage}
          </div>
          <div style={{ position: 'relative', right: 34 }}>
            <FooterPayment>
              <FooterButton
                icon={ArrowRight}
                disabled={processingPayment}
                textRight={
                  pathname !== "/payment-methods/add-card" ? 'Complete order' : 'Save'
                }
                textLeft={
                  pathname !== "/payment-methods/add-card"
                    ? `Total ${
                        order.order.summary[order.order.summary.length - 1]
                          .value
                      }`
                    : null
                }
                actionRight={() => {
                  handleSubmit();
                }}
              />
            </FooterPayment>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

const CardForm = injectStripe(_CardForm);

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', () => {
      resolve({ successful: true });
    });
    script.addEventListener('error', (event) => {
      reject({ error: event });
    });
    document.head.appendChild(script);
  });

const CaptureCreditCard = (props) => {
  const [stripeLoaded, setStripeLoaded] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const pathname =  useSelector((state) => state.router.location.pathname);

  useEffect(() => {
    const fetchData = async () => {
      const result = await loadScript('https://js.stripe.com/v3/');
      setStripeLoaded(result);
      await dispatch(getStripeCustomer());
    };
    fetchData();
    ReactPixel.pageView()
    //eslint-disable-next-line
  }, []);

  const handleBackAction = () => {
    if (pathname !== "/payment-methods/add-card") {
      history.push('/purchase-review');
    } else {
      history.push('/profile');
    }
  };

  return stripeLoaded.successful ? (
    <div>
      <Wrapper style={{ backgroundColor: 'white', height: '100vh' }}>
        <Header backAction={handleBackAction} />
        <Title title='ENTER YOUR PREFERRED CREDIT CARD DETAILS' />
        <Content>
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
            <Elements>
              <CardForm handleResult={props.handleResult} />
            </Elements>
          </StripeProvider>
        </Content>
      </Wrapper>
    </div>
  ) : (
    <div></div>
  );
};

export default CaptureCreditCard;
