import React, { forwardRef } from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import styled from '@emotion/styled';

const Container = styled('div')`
  background-color: var(--aut-lait-bgd);
  border-radius: 6px;
  width: 100%;
  padding: 20px;

  .ant-checkbox-wrapper {
    display: grid;
    grid-template-columns: 24px auto;
    align-items: center;
  }

  .ant-checkbox + span {
    line-height: 20.43px;
    padding: 0 15px;
    font-family: Noto Serif;
    font-size: 15px;
  }

  .ant-checkbox .ant-checkbox-inner {
    width: 26px;
    height: 26px;
    border: #ffffff;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 26px;
    height: 26px;
    border: #ffffff;
    background-color: #ffffff;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: var(--bettr-yellow);
  }

  .ant-checkbox-inner::after {
    top: 43%;
    left: 24%;
    width: 7.714286px;
    height: 16.142857px;
  }
`;

const ConfirmationButton = (
  {
    text = '[Placeholder]',
    checked = false,
    action = () => console.log('CodeInput clicked'),
    disabled = false
  },
  ref
) => {
  return (
    <Container onChange={action} ref={ref}>
      <AntCheckbox checked={checked} disabled={disabled}>
        {text}
      </AntCheckbox>
    </Container>
  );
};

export default forwardRef(ConfirmationButton);
