import { paymentService } from '../services/';
import {
  setDeliveryFee,
  setDiscount,
  setDiscountError,
  getCustomer, clearOriginalPlanValues, setOriginalPlans
} from './Order';
import { message } from 'antd';
import { isAmOrPm } from '../helpers/utils';
import {setSubscription} from "./User";

export const getPaymentIntent = (cardElement, stripe, customerId, cardOwnerName) => async () => {
  try {
    const result = await paymentService.getPaymentIntent();
    const stripeConfCard = await stripe.confirmCardSetup(
        result.data.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {name: cardOwnerName}
          }
        }
    );

    const bindCustomerResult = await paymentService.bindCustomer({
      customerId: customerId,
      paymentId: stripeConfCard.setupIntent.payment_method
    });

    await paymentService.setDefaultCard({
      customerId: customerId,
      cardId: stripeConfCard.setupIntent.payment_method
    });

    if (bindCustomerResult) {
      console.log('bindCustomerResult', bindCustomerResult);
      return true;
    }

    //TODO: direct them to the right place
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getDiscount = (couponCode, firstMonth , total , nextMonth) => async (dispatch) => {
  try {
    const result = await paymentService.getDiscount(couponCode);
    if(result.data.minimum_amount !== null && (result.data.minimum_amount / 100) > total) {
      dispatch(setDiscountError({message:`Promo code has a minimal order value of $${result.data.minimum_amount / 100} to be applicable`, nextMonth
    }));
    } else if(!result.data.coupon.valid){
      dispatch(setDiscountError({message:`Enter a valid promo code`, nextMonth}));
    } else {
      dispatch(setDiscount({ data: result.data, firstMonth, couponCode , nextMonth }));
    }
  } catch (e) {
    console.log(e);
    dispatch(setDiscountError({message:"Enter a valid promo code", nextMonth}));
  }
};

export const getDeliveryFee = (postalCode) => async (dispatch) => {
  try {
    const result = await paymentService.getDeliveryFee(postalCode);
    dispatch(setDeliveryFee(result.data, postalCode));
  } catch (e) {
    console.log(e);
  }
};

export const subscribeToPlan = (order) => async (dispatch) => {
  try {
    const response = {
      priceList: [],
      coffeeRecieveMode: '',
      daysDrinksDelivered: '',
      serviceTime: '',
      coupon: order.discount.id !== '' ? order.discount.id : null
    };

    order.summary.forEach((item) => {
      if (item?.planId) {
        if (item.field !== 'Subscription Plan') {
          response.priceList.push({
            price: item?.planId,
            quantity: order.summary[0].value
          });
        } else {
          response.priceList.push({
            price: item?.planId
          });
        }
      }
    });

    if (order?.orderType === 'Delivery') {
      response.coffeeRecieveMode = 'Delivery';
    } else {
      response.coffeeRecieveMode = 'Pickup';
    }

    if (order.deliveryDaySelection) {
      response.daysDrinksDelivered = order.deliveryDaySelection.join(',');
    }

    if (order.orderType === 'Delivery' && order.deliveryTimeslot) {
      response.serviceTime = order.deliveryTimeslot === 1 ? '8:30AM' : '2:00PM';
    }

    if (order.orderType === 'Pickup' && order.pickupTimeslot) {
      let { hour, time } = order.pickupTimeslot;
      response.serviceTime = hour + ':' + time + isAmOrPm(hour);
    }
    response.priceList = response.priceList.reverse();

    const result = await paymentService.subscription(response);

    if (result) {
      console.log(result);
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e.message);
    return false;
  }
};

export const pauseSubscription = (subscriptionId) => async (dispatch) => {
  try {
    const response = await paymentService.pauseSubscription(subscriptionId);
    if (response.status === 200) {
      console.log('subscription paused');
      dispatch(getCustomer());
    }
  } catch (e) {
    console.log(e);
  }
};

export const cancelSubscription = (subscriptionId) => async (dispatch) => {
  try {
    const response = await paymentService.cancelSubscription(subscriptionId);
    if (response.status === 200) {
      await dispatch(setSubscription({data : response.data}))
      message.success('Subscription has been canceled.');
    }
  } catch (e) {
    console.log(e);
  }
};

export const resumeSubscription = (subscriptionId) => async (dispatch) => {
  try {
    const response = await paymentService.resumeSubscription(subscriptionId);
    if (response.status === 200) {
      console.log('subscription paused');
      dispatch(getCustomer());
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateSubscription = (subscriptionId, order) => async (
  dispatch,
  getState
) => {
  try {
    const {originalSummary , myPlanSummary , originalDeliveryPlan , deliveryPlan } = getState().orderDetail.order
    const request = {
      subscriptionId: subscriptionId,
      priceList: [],
      coffeeRecieveMode: '',
      daysDrinksDelivered: '',
      serviceTime: '',
      originalSummary: originalSummary,
      summary: myPlanSummary,
      originalDeliveryPlan: originalDeliveryPlan,
      deliveryPlan: deliveryPlan
    };
    order.summary.forEach((item) => {
      if (item?.planId) {
        if (item.field !== 'Subscription Plan') {
          request.priceList.push({
            price: item?.planId,
            quantity: order.summary[0].value
          });
        } else {
          request.priceList.push({
            price: item?.planId
          });
        }
      }
    });

    if (order?.orderType === 'Delivery') {
      request.coffeeRecieveMode = 'Delivery';
    } else {
      request.coffeeRecieveMode = 'Pickup';
    }

    if (order.deliveryDaySelection) {
      request.daysDrinksDelivered = order.deliveryDaySelection.join(',');
    }

    if (order.orderType === 'Delivery' && order.deliveryTimeslot) {
      request.serviceTime = order.deliveryTimeslot === 1 ? '8:30AM' : '2:00PM';
    }
    if (order.orderType === 'Pickup' && order.pickupTimeslot) {
      let { hour, time } = order.pickupTimeslot;
      request.serviceTime = hour + ':' + time + isAmOrPm(hour);
    }
    request.coupon = order.discount.id

    const result = await paymentService.updateSubscription(request);
    dispatch(setOriginalPlans())
    if (result) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
  }
};

export const getUserCreditCards = (userToken) => async () => {
  const creditCards = [];
  try {
    const response = await paymentService.getCreditCards(userToken);
    console.log(response);
    if (response.status === 200) {
      creditCards.push(...response.data.paymentMethods);
    }
    console.log('creditCards', creditCards);
  } catch (e) {
    console.log(e);
  } finally {
    return creditCards;
  }
};

export const setDefaultCreditCard = (userToken, id) => async () => {
  let defaultCardId = '';
  try {
    const response = await paymentService.setDefaultCard({
      customerId: userToken,
      cardId: id
    });
    if (response.status === 200) {
      defaultCardId = response.defaultCardId;
    }
  } catch (e) {
    console.log(e);
  } finally {
    return defaultCardId;
  }
};

export const deleteUserCreditCard = (userToken, id) => async () => {
  let deleted = false;
  try {
    const response = await paymentService.deleteCreditCard({
      customerId: userToken,
      cardId: id
    });
    if (response.status === 200) {
      deleted = true;
    }
  } catch (e) {
    console.log(e);
  } finally {
    return deleted;
  }
};
