import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import { mq } from '../../helpers/utils';

const ContentStyled = styled('div')`
  padding: 0 33px 33px 33px;
  position: relative;
  z-index: 2;

  ${mq('lg')} {
    padding: 0 0 33px 0;
  }
`;

const Content = ({ style, children }, ref) => {
  return (
    <ContentStyled style={style} ref={ref}>
      {children}
    </ContentStyled>
  );
};

export default forwardRef(Content);
