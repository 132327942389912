import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { _ErrorMessages as msg } from '../../constants/ErrorMessages';
import { ArrowRight } from '../../assets/icons';
import { quizGotoNext, quizSetName } from '../../actions/Quiz';
import { Input, FooterButton, Footer, Title, Content } from '../../components';

const NamePrompt = (props) => {
  const quizInputUsername = useSelector((state) => state.quizInput.quiz.name);
  const userDetailUsername = useSelector((state) => state.userDetail.user.name);
  const [nameErrMsg, setNameErrMsg] = useState('');
  const dispatch = useDispatch();

  const validationsHandler = () => {
    dispatch(quizSetName(quizInputUsername.trim()));
    if (quizInputUsername.length < 1) {
      setNameErrMsg(msg.required);
    } else {
      dispatch(quizGotoNext());
      setNameErrMsg('');
    }
  };

  const skipHandler = () => {
    dispatch(quizSetName(''));
    dispatch(quizGotoNext());
  };

  return (
    <>
      <Title title='BEFORE WE BEGIN, PLEASE TELL US YOUR NAME?' />
      <Content style={{ marginTop: '20px' }}>
        <Input
          placeholder='Name'
          onChange={(e) => {
            dispatch(quizSetName(e.target.value.trim()));
          }}
          type='text'
          value={quizInputUsername || userDetailUsername}
          errMsg={nameErrMsg}
          name='Name'
          label='Name'
        />
      </Content>

      <Footer>
        <FooterButton
          style={{ backgroundColor: 'var(--bettr-yellow' }}
          icon={ArrowRight}
          textRight='Continue'
          textLeft='Skip'
          actionRight={() => {
            validationsHandler();
          }}
          actionLeft={() => {
            skipHandler();
          }}
          disabled={quizInputUsername.trim().length < 1}
        />
      </Footer>
    </>
  );
};

export default NamePrompt;
