import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'antd';

import { ArrowRight } from '../../assets/icons';
import { _ErrorMessages as msg } from '../../constants/ErrorMessages';
import {
  signupSetPassword,
  signupSetConfirmPassword,
  signupUser
} from '../../actions/User';
import { Input, FooterButton, Footer, Title, Content } from '../../components';

const PasswordSection = (props) => {
  const currentUser = useSelector((state) => state.userDetail.user);
  const specialReq = useSelector((state) => state.orderDetail.order.specialInstructions);
  const address = useSelector((state) => state.addressDetail.address);
  const [passwordErrMsg, setPasswordErrMsg] = useState('');

  const validationsHandler = async () => {
    if (currentUser.confirmPassword !== currentUser.password) {
      setPasswordErrMsg(msg.needToMatch);
    } else {
      await dispatch(signupUser(currentUser, address, specialReq));
      setPasswordErrMsg(null);
    }
  };

  const dispatch = useDispatch();

  const tooltipMsg = (
    <div>
      <ul style={{ paddingInlineStart: '20px', marginBlockEnd: '0' }}>
        <li>At least 8 characters in length</li>
        <li>Contains at least 3 of the following:</li>
      </ul>
      <ul style={{ marginBlockEnd: '0.5em' }}>
        <li>lower case letters (a-z)</li>
        <li>upper case letters (A-Z)</li>
        <li>numbers (i.e. 0-9)</li>
        <li>special characters (e.g. !@#$%^&*)</li>
      </ul>
    </div>
  );

  return (
    <div>
      <Title title='Set a unique password' />
      <Content style={{ marginTop: '20px' }}>
        <Tooltip
          trigger={['hover', 'focus', 'click']}
          arrowPointAtCenter
          placement='bottom'
          title={tooltipMsg}
        >
          <Input
            onChange={(e) => {
              dispatch(signupSetPassword(e.target.value));
            }}
            placeholder='Password'
            type='password'
            value={currentUser.password}
            name='signup-password'
            label='Password'
          />
          <Input
            onChange={(e) => {
              dispatch(signupSetConfirmPassword(e.target.value));
            }}
            placeholder='Confirm your Password'
            type='password'
            errMsg={passwordErrMsg || currentUser.error}
            value={currentUser.confirmPassword}
            name='c-password'
            label='Confirm your Password'
          />
        </Tooltip>
      </Content>

      <Footer>
        <FooterButton
          icon={ArrowRight}
          textRight='Continue'
          actionRight={() => {
            validationsHandler();
          }}
        />
      </Footer>
    </div>
  );
};

export default PasswordSection;
