import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import * as History from 'history';
import createRootReducer from './reducers';
import {FLUSH, persistReducer, persistStore, PURGE} from 'redux-persist';
import storage from 'redux-persist/lib/storage';


export const history = History.createBrowserHistory();

const initialState = {};
const enhancers = [];
const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'develop' || process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension({ trace: true }));
  }
}

// we add this to handle persistor flush purge etc functions
const persistorEnhancer = rootReducer => (state, action) => {
  switch (action.type) {
    case FLUSH: {
      storage.removeItem('persist:root')
      return rootReducer(state, {});
    }
    case PURGE :{
      storage.removeItem('persist:root')
      return rootReducer(undefined, {});
    }
    default: {
      return rootReducer(state, action);
    }

  }
};

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export const store = createStore(
  persistorEnhancer(persistedReducer),
  initialState,
  composedEnhancers
);
export const persistor = persistStore(store);
