import React from 'react';
import { useHistory } from 'react-router-dom';

import { PageWithImage } from '../../components/';
import { PersonWithScroll } from '../../assets/images/';
import { useSelector } from 'react-redux';

const SavedPlanUpdates = () => {
  const history = useHistory();
  const handleGoToProfile = () => history.push('/profile');
  const handleGetInTouch = () => history.push('/feedback');

  const customerName = useSelector((state) => state.orderDetail.customer?.name);

  return (
    <PageWithImage
      CTAText='Get in touch'
      CTAAction={handleGetInTouch}
      fullWidthText
      image={PersonWithScroll}
      title={`Great Choices ${customerName}! We Have Saved Your Plan Updates.`}
      text="Any changes to your plan will commence from next month's subscription. All changes must be made before your next renewal. Please get in touch if your updates are urgent and we will try our best to assist you."
      footer1Text='Go to my profile page'
      footer1Action={handleGoToProfile}
    />
  );
};

export default SavedPlanUpdates;
