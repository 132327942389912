import React from 'react';
import { useDispatch } from 'react-redux';

import { quizSetPreferredDrink, quizGotoNext } from '../../actions/Quiz';
import {
  StyledVerticalOption,
  StyledVerticalMenu,
  Title,
  Content
} from '../../components';
import { Coffee, Tea, CC0, Heart } from '../../assets/icons';
import ReactPixel from "react-facebook-pixel";

const PreferredDrink = (props) => {
  const dispatch = useDispatch();

  const selectHandler = (e) => {
    dispatch(quizSetPreferredDrink(e.target.value));
    dispatch(quizGotoNext());
  };

  React.useEffect(() => {ReactPixel.pageView()}, [])

  return (
    <>
      <Title title='Do you prefer coffee or tea, or non-caffeinated drinks?' />
      <Content style={{ marginTop: '20px' }}>
        <StyledVerticalMenu displayCheckbox={false} onChange={selectHandler}>
          <StyledVerticalOption value={'coffee'}>
            <img src={Coffee} alt='selection-option' />
            Coffee
          </StyledVerticalOption>
          <StyledVerticalOption value={'tea'}>
            <img src={Tea} alt='selection-option' />
            Tea
          </StyledVerticalOption>
          <StyledVerticalOption value={'non-caffeinated'}>
            <img src={CC0} alt='selection-option' />
            Non-Caffeinated
          </StyledVerticalOption>
          <StyledVerticalOption value={'no-preference'}>
            <img src={Heart} alt='selection-option' />I like them all
          </StyledVerticalOption>
        </StyledVerticalMenu>
      </Content>
    </>
  );
};

export default PreferredDrink;
