import {createAction} from '@reduxjs/toolkit';
import {_ErrorMessages as msg} from '../constants/ErrorMessages';
import {signup} from '../auth0';
import {customerService} from '../services/';
import _ from "lodash";
import {initAddress} from "./Address";
import {initOrderDetails} from "./Order";
import {initCoffeeDetails} from "./Coffee";
import {initQuiz} from "./Quiz";
import { persistor } from '../store';



export const changeStatus = createAction('USER/CHANGE_STATUS');
export const changeCardOwnerName = createAction('USER/CHANGE_CARD_OWNER_NAME');
export const signupGotoNext = createAction('USER/SIGNUP_CHANGE_SECTION_NEXT');
export const signupGotoPrevious = createAction(
    'USER/SIGNUP_CHANGE_SECTION_PREVIOUS'
);
export const setFaqList = createAction('USER/SET_FAQ_LIST');

export const signupSetName = createAction('USER/SIGNUP_SET_NAME');
export const signupSetPhoneNumber = createAction(
  'USER/SIGNUP_SET_PHONE_NUMBER'
);
export const signupSetPassword = createAction('USER/SIGNUP_SET_PASSWORD');
export const signupSetConfirmPassword = createAction(
  'USER/SIGNUP_SET_CONFIRM_PASSWORD'
);
export const signupSetEmail = createAction('USER/SIGNUP_SET_EMAIL');
export const userSetError = createAction('USER/SET_ERROR');
export const signupChangeStage = createAction('USER/SIGNUP_CHANGE_STAGE');
export const setSubscription = createAction('USER/SET_SUBSCRIPTION')

export const signupUser = ({ email, password, name, phoneNumber }, address, specialRequest) => async (
  dispatch
) => {
  try {
    await signup(email, password, name, phoneNumber, address.streetname, 'Singapore', address.unitNo, address.postalCode, address.addressDescription, specialRequest);

    //TODO: direct them to the right place
  } catch (e) {
    switch (e.name) {
      case 'PasswordStrengthError':
        dispatch(userSetError(msg.weakPassword));
        break;
      case 'BadRequestError':
        dispatch(userSetError(msg.emailAlreadyExists));
        dispatch(signupChangeStage(2));
        break;
      default:
        break;
    }
  }
};

export const getFaqList = (faqListType) => async (dispatch) => {
  try {
    let faqList = await customerService.getFaqList(faqListType);
    const result = _.chain(faqList.data).groupBy("group").map((value, key) => ({ groupKey:key , value}))
        .value()
    await dispatch(setFaqList(result))
  } catch (e) {
    console.log(e);
  }
};

export const createEdmContact = ({ email, name }, address) => async (
  dispatch
) => {
  try {
    await customerService.createEdmContact({name, email});
    return true;
  } catch (e) {
   console.log(e);
  }
};

export const cleanQuizStore = () => async (dispatch , getState) => {
  try {
    await dispatch(initAddress())
    await dispatch(initOrderDetails())
    await dispatch(initCoffeeDetails())
    await dispatch(initQuiz())

    await persistor.flush()
  } catch (e) {
    console.log(e)
  }
}

export const cleanGlobalStore = () => async (dispatch) => {
  try {
    await persistor.purge()
  } catch (e) {
    console.log(e)
  }
}
