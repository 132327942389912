import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import FaqItem from './FaqItem';
import { mq } from '../../helpers/utils';

const FAQTitle = styled('h2')`
  line-height: 34px;
  padding-top: 39px;
  margin-bottom: 32px;

  ${mq('lg')} {
    max-width: 204px;
  }
`;

const FaqList = ({ list = [], title = '[Title]' }, ref) => {
  return (
    <div ref={ref}>
      <FAQTitle className='large-title'>{title}</FAQTitle>
      {list.map((e) => (
        <FaqItem key={e.question} question={e.question} answer={e.answer} />
      ))}
    </div>
  );
};

export default forwardRef(FaqList);
