import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { useDebounce } from '../helpers/hooks/useDebounce';

const InputGroup = styled('div')`
  display: grid;
  grid-template-columns: 10px 1fr;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

const InputLine = styled('div')`
  width: 2px;
  height: 50px;
  background-color: var(--mocha);
`;

const InputLabel = styled('label')`
  position: absolute;
  left: 11px;
  color: var(--mocha);
  opacity: 0;
  transition: all 0.2s;
  transform-origin: left bottom;
  pointer-events: none;
`;

const InputField = styled('input')`
  height: 100%;
  width: 90%;
  background-color: transparent;
  border: none;
  font-size: var(--body-17);
  font-family: var(--noto);
  color: var(--french);
  transition: all 0.2s;
  &::placeholder {
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }
  &${(props) => (!props.value ? ':focus ' : '')}+ label {
    opacity: 1;
    transform: translate(0, -24px) scale(0.7);
    touch-action: manipulation;
  }
  &:focus::placeholder {
    opacity: 0;
  }
`;

const ErrMsg = styled('span')`
  position: absolute;
  color: var(--wine);
  font-size: var(--inter-tagline);
  top: 39px;
  left: 12px;
`;

const Input = (props, ref) => {
  const {
    onChange = () => {},
    value: propValue,
    timeout = 300,
    placeholder,
    type,
    name,
    id,
    label,
    errMsg,
    ...rest
  } = props;
  const [value, handleOnChange] = useDebounce(
    propValue || '',
    onChange,
    timeout
  );
  const errorStyle = { backgroundColor: 'var(--wine)' };
  const valueStyle = { backgroundColor: 'var(--french)' };

  return (
    <InputGroup>
      <InputLine
        style={errMsg ? errorStyle : value ? valueStyle : {}}
      ></InputLine>
      <InputField
        ref={ref}
        onChange={handleOnChange}
        value={value}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        {...rest}
      />
      <InputLabel className='noto-body-17' htmlFor={name}>
        {label}
      </InputLabel>
      <ErrMsg>{errMsg}</ErrMsg>
    </InputGroup>
  );
};

export default forwardRef(Input);
