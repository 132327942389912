import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import '../../assets/styles/styles.css';
import {DeliveryDay} from '../../components';
import {changeBestDeliveryDays} from '../../actions/Order';
import {validateDays} from '../../helpers/utils';
import styled from '@emotion/styled';
import ReactPixel from "react-facebook-pixel";

styled('div')`
  color: #c23d4b;
`;
const SelectDeliveryWindow = () => {
  const order = useSelector((state) => state.orderDetail.order);

  const dispatch = useDispatch();
  const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const changeHandler = (values) => {
    validateDays(values, 'Plan frequency does not match days selected!', order, setDisabled, setErrorMessage);
    dispatch(changeBestDeliveryDays(values));
  };

  const handleContinueClick = () => history.push('/select-delivery-timeslot');

  useEffect(() => {
    if (order.numberOfDeliveryDaysAllowed === 5) {
      dispatch(changeBestDeliveryDays([1, 2, 3, 4, 5]));
      handleContinueClick();
    }
    validateDays(order.deliveryDaySelection, '', order, setDisabled, setErrorMessage);
    //eslint-disable-next-line
    ReactPixel.pageView()
  }, []);

  return (
    <DeliveryDay
      order={order}
      titleText="SELECT THE DAY(S) YOU'D LIKE YOUR DRINKS DELIVERED."
      onDaySelect={changeHandler}
      onContinueClick={handleContinueClick}
      disabled={disabled}
      errorMessage={errorMessage}
    />
  );
};
export default SelectDeliveryWindow;
