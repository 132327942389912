import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import SummaryTableRow from './SummaryTableRow';
import { OptionInfo } from '../options';

const TableBody = styled('tbody')`
  display: grid;
  grid-template-rows: repeat(${(props) => props.rows}, 1fr);
  row-gap: 20px;
  margin-bottom: 40px;
  & > tr:last-of-type {
    border-bottom: none;
  }
`;

const TableBodySummary = styled('tbody')`
  display: grid;
  grid-template-rows: repeat(${(props) => props.rows}, auto);
  row-gap: 20px;
  margin-bottom: 20px;
  & > tr:last-of-type {
    padding-bottom: 8px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    border-bottom: none;
  }
  & > tr:last-of-type > td:first-of-type {
    font-family: var(--inter);
    font-size: var(--body-15);
    font-weight: 600;
  }
  & > tr:last-of-type > td:last-of-type {
    font-family: var(--bebas);
    font-size: var(--large-title);
    font-weight: 400;
  }
`;

const SummaryTable = (
  { list = [], summary, firstMonth, edit, action, title, style , discount },
  ref
) => {
  return (
    <>
      {title ? <OptionInfo edit={edit} action={action} title={title} /> : null}
      <table ref={ref} style={{ width: '100%' }}>
        {summary ? (
          <TableBodySummary
            style={style}
            rows={list.length}
            className='noto-body-15'
          >
            {list.map((item) => (
              <SummaryTableRow
                key={item.field}
                {...item}
                firstMonth={firstMonth}
                discount={discount}
                summary={summary}
              />
            ))}
          </TableBodySummary>
        ) : (
          <TableBody style={style} rows={list.length} className='noto-body-15'>
            {list.map((item) => (
              <SummaryTableRow
                key={item.field}
                {...item}
                firstMonth={firstMonth}
                summary={summary}
              />
            ))}
          </TableBody>
        )}
      </table>
    </>
  );
};

export default forwardRef(SummaryTable);
