import { createAction } from '@reduxjs/toolkit';
import {customerService} from "../services";
import {setCoffeesTypes} from "./Order";

export const setFavouriteCoffee = createAction('COFFEE/SET_FAVOURITE_COFFEE');
export const initCoffeeDetails = createAction('COFFEE/INIT_COFFEE');
export const setFavCoffeeChangePlan = createAction(
  'COFFEE/SET_FAVOURITE_COFFEE_CHANGE_PLAN'
);

export const setCoffeeTypes = createAction('COFFEE/SET_COFFEE_TYPES');

export const fetchFavCofe = () => async (dispatch , getState) => {
    try {
        const result = await customerService.getCustomer();
        const cofee = result.data.subscriptions?.data[0]?.items?.data.find(item => item.quantity === 1);
        const favCoffee =
            cofee?.plan?.product;
        if (favCoffee) {
            await dispatch(
                setCoffeesTypes({
                    drinkType: 'no-preference',
                    coffeeTemperature: 'no-preference'
                })
            );
            await dispatch(setFavouriteCoffee(favCoffee))
            await dispatch(setFavCoffeeChangePlan(favCoffee));
        }
    } catch (e) {
        console.log(e);
    }
};
