import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Footer,
  FooterButton,
  Content,
  Header,
  Title,
  Wrapper
} from './../../components';
import { PinAdd } from '../../assets/icons';
import AddressCard from './components/AddressCard';
import { addressResetGoto } from '../../actions/Address';

const Address = () => {
  const customer = useSelector((state) => state.orderDetail.customer);
  const address = customer.shipping.address;
  const coffeeReceiveMode = useSelector(
    (state) =>
      state.orderDetail.customer.subscriptions.data[0]?.metadata
        .COFFEE_RECEIVE_MODE
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const editHandler = () => {
    dispatch(addressResetGoto());

    if (coffeeReceiveMode === 'Pickup') {
      history.push('/pickup');
    } else {
      history.push('/address/new-address');
    }
  };

  return (
    <Wrapper>
      <Header />
      <Title className='large-title' title='My Address' />
      <Content>
        <AddressCard
          addressString={
            address.line1 +
            ' ' +
            address.line2 +
            ' Singapore ' +
            address.postal_code
          }
          addressDescription={customer.metadata?.ADDRESS_DESCRIPTION}
          action={editHandler}
        />
      </Content>
      <Footer>
        <FooterButton
          style={{ backgroundColor: 'var(--bettr-yellow' }}
          icon={PinAdd}
          textRight='Add a new address'
          actionRight={() => {
            history.push('/wip');
            console.log('Going to redirect to the new address');
          }}
        />
      </Footer>
    </Wrapper>
  );
};

export default Address;
