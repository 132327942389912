import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import { PencilEdit } from '../../assets/icons';

const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;

const Icon = styled('img')`
  margin-bottom: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SectionTitle = styled('h2')`
  color: var(--molasses);
  line-height: 1;
`;

const Description = styled('p')`
  margin-bottom: 22px;
  white-space: pre-line;
`;

const OptionInfo = (
  {
    style,
    icon,
    edit,
    title = '[Title]',
    description,
    action = () => console.log('Edit icon clicked!')
  },
  ref
) => {
  return (
    <>
      <TitleContainer style={style} ref={ref}>
        {icon && (
          <Icon style={{ marginRight: '6px' }} src={icon} alt='section-icon' />
        )}
        <SectionTitle className='inter-body-15-sb'>{title}</SectionTitle>
        {edit && <Icon onClick={action} src={PencilEdit} alt='edit' />}
      </TitleContainer>
      {description && (
        <Description className='m-0 noto-body-15'>{description}</Description>
      )}
    </>
  );
};

export default forwardRef(OptionInfo);
