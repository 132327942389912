/* eslint-disable eqeqeq */
import { createReducer } from '@reduxjs/toolkit';
import {
  changeStatus,
  signupGotoNext,
  signupGotoPrevious,
  signupSetName,
  signupSetConfirmPassword,
  signupSetPassword,
  signupSetPhoneNumber,
  signupSetEmail,
  signupChangeStage,
  userSetError,
  changeCardOwnerName,
  setFaqList,
  setSubscription
} from '../actions/User';

const initialState = {
  user: {
    id: undefined,
    email: '',
    isLoggedIn: false,
    signupStage: 1,
    name: '',
    cardOwnerName: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    error: '',
    faqList: [],
    subscription: {
      cancel_at_period_end: false
    }
  }
};

export default createReducer(initialState, {
  [changeStatus](state, action) {
    state.user.isLoggedIn = true;
  },
  [changeCardOwnerName](state, action) {
    state.user.cardOwnerName = action.payload;
  },
  [setFaqList](state, action) {
    state.user.faqList = action.payload;
  },
  [signupGotoNext](state, action) {
    state.user.error = '';
    state.user.signupStage = state.user.signupStage + 1;
  },
  [signupGotoPrevious](state, action) {
    state.user.signupStage = state.user.signupStage - 1;
  },
  [signupChangeStage](state, action) {
    state.user.password = '';
    state.user.confirmPassword = '';
    state.user.signupStage = action.payload;
  },
  [userSetError](state, action) {
    state.user.error = action.payload;
  },
  [signupSetName](state, action) {
    state.user.name = action.payload;
  },
  [signupSetConfirmPassword](state, action) {
    state.user.confirmPassword = action.payload;
  },
  [signupSetPassword](state, action) {
    state.user.password = action.payload;
  },
  [signupSetEmail](state, action) {
    state.user.email = action.payload;
  },
  [signupSetPhoneNumber](state, action) {
    state.user.phoneNumber = action.payload;
  },
  [setSubscription](state, action) {
    state.user.subscription = action.payload?.data
      ? action.payload.data
      : initialState.subscription;
  }
});
