import React from 'react';
import styled from '@emotion/styled';
import { OptionInfo } from '../../../components';
import { TrashEmpty, CreditcardFace } from '../../../assets/icons';

const Container = styled.div`
  background-color: ${(props) =>
    props.isDefault ? 'var(--bettr-yellow)' : '#ffffff'};
  border-radius: 6px;
  padding: 30px;
  margin-bottom: 10px;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: auto 30px;
  column-gap: 7px;

  > span {
    > div {
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      > * {
        margin-bottom: 0;
      }
    }

    > p {
      margin-bottom: 0;
    }
  }
`;

const TrashIcon = styled.img`
  filter: invert(70%) sepia(22%) saturate(203%) hue-rotate(350deg)
    brightness(94%) contrast(91%);
  margin: 0 auto;
`;

const CheckedIcon = styled.span`
  background-color: var(--au-lait);
  width: 26px;
  height: 26px;
  display: block;
  boz-sizing: border-box;
  border-radius: 50%;

  &::after {
    position: relative;
    top: 7px;
    left: 7px;
    background: var(--bettr-yellow);
    width: 12px;
    height: 12px;
    opacity: 1;
    display: block;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    content: ' ';
    box-sizing: border-box;
  }
`;

const CreditCardCard = ({ card, index, selectHandler, deleteHandler }, ref) => {
  const cardType = card.type.charAt(0).toUpperCase() + card.type.slice(1);

  const trashHandler = (e) => {
    e.stopPropagation();
    deleteHandler(card.id, index);
  };

  return (
    <Container
      isDefault={card.isDefault}
      value={index}
      onClick={() => selectHandler(card, index)}
    >
      <ContentContainer>
        <span>
          <OptionInfo
            icon={CreditcardFace}
            title={`My ${cardType}`}
            description={`**** **** **** ${card.last}\n${card.exp_month}/${card.exp_year}`}
          ></OptionInfo>
        </span>
        <div>
          {card.isDefault ? (
            <CheckedIcon />
          ) : (
            <TrashIcon
              src={TrashEmpty}
              alt='delete card'
              onClick={(e) => trashHandler(e)}
            />
          )}
        </div>
      </ContentContainer>
    </Container>
  );
};

export default CreditCardCard;
