import { createReducer } from '@reduxjs/toolkit';

import {
  createFeedbackTopic,
  resetFeedbackTopic,
  submitFeedback
} from '../actions/Feedback.js';

const initialState = {
  feedback: {
    topic: ''
  }
};

export default createReducer(initialState, {
  [createFeedbackTopic](state, action) {
    state.feedback.topic = action.payload;
  },
  [submitFeedback](state, action) {
    console.log('submitted feedback');
  },
  [resetFeedbackTopic](state, action) {
    state.feedback.topic = '';
  }
});
