import React from 'react';
import styled from '@emotion/styled';

import { mq, keepTwoDecimals } from '../../../helpers/utils';

const Container = styled('div')`
  border: 3px solid
    ${(props) => (props.selected ? 'var(--bettr-yellow)' : 'var(--au-lait)')};
  border-radius: 6px;
  padding: 25px 15px;
  width: 149px;
  flex: 0 0 auto;
  height: 95px;
  margin-right: 11px;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  ${mq('lg')} {
    width: 192px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

const TitleText = styled('h3')`
  margin-bottom: 4px;
  line-height: 18.15px;
  min-width: 95px;
`;

const SubtitleText = styled('p')`
  line-height: 17.71px;
`;

export const Option = ({
  description = '[Description]',
  priceDesc = '[Price Desc]',
  isSelected,
  action
}) => {
  return (
    <Container selected={isSelected} onClick={action}>
      <TitleText className='inter-body-15-sb lh-1 m-0'>{description}</TitleText>
      <SubtitleText className='noto-caption-13 lh-1 m-0'>
        {priceDesc}
      </SubtitleText>
    </Container>
  );
};

const SubsContainer = styled('div')`
  border: 3px solid
    ${(props) => (props.selected ? 'var(--bettr-yellow)' : 'var(--au-lait)')};
  border-radius: 6px;
  padding: 18px 12px 17px 18px;
  width: 149px;
  flex: 0 0 auto;
  height: auto;
  margin-right: 11px;
  text-align: left;
  cursor: pointer;

  ${mq('lg')} {
    width: 192px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

const DiscountLabel = styled('div')`
  background-color: var(--bettr-yellow);
  padding: 7px 6px;
  border-radius: 2px;
  margin-bottom: 12px;
  width: 47px;
  height: 25px;
  font-size: 9px;
  text-align: center;
`;

const Price = styled('h3')`
  margin-bottom: 7px;
`;

const OriginalPrice = styled('span')`
  text-decoration: line-through;
  opacity: 50%;
`;

export const Subscription = ({
  price = '[Price]',
  description = '[Description]',
  discount = 0,
  isSelected,
  action
}) => {
  const discountPercent = +discount;
  const originalPlanPrice = keepTwoDecimals(
    parseFloat(price) / (1 - discountPercent)
  );
  const discountedPlanPrice = keepTwoDecimals(parseFloat(price));
  const formattedDescription = isNaN(description[0])
    ? `${description[0].toUpperCase()}${description.substr(1)}`
    : description;

  return (
    <SubsContainer selected={isSelected} onClick={action}>
      <DiscountLabel className='inter-tagline'>
        {`${Math.floor(parseFloat(discountPercent) * 100)}`}% off
      </DiscountLabel>
      <Price className='m-0 inter-body-15'>
        <OriginalPrice>${originalPlanPrice}</OriginalPrice> $
        <span className='inter-body-15-sb'>{discountedPlanPrice}</span>
      </Price>
      <p className='m-0 noto-caption-13 lh-1'>{formattedDescription}</p>
    </SubsContainer>
  );
};
