import React from 'react';
import { useHistory } from 'react-router-dom';

import { Wrapper, PageWithImage } from '../components';
import { ArrowRight } from '../assets/icons';
import { PersonWithPlane } from '../assets/images/';
import ReactPixel from "react-facebook-pixel";

const ForgotPasswordConfirm = () => {
  const history = useHistory();

  const gotoHomepage = () => {
    history.push('/login');
  };

  const retriggerPasswordReset = () => {
    history.push('/forgot-password');
  };

  React.useEffect(() => {ReactPixel.pageView()}, [])

  return (
    <>
      <Wrapper style={{ backgroundColor: 'white' }}>
        <PageWithImage
          image={PersonWithPlane}
          title='we have sent you an email with a link to reset your password.'
          CTAText='Didn’t receive the e-mail?'
          CTAAction={retriggerPasswordReset}
          footer1Icon={ArrowRight}
          footer1Action={gotoHomepage}
          footer1Text='Enter my new password'
          noBack
        />
      </Wrapper>
    </>
  );
};

export default ForgotPasswordConfirm;
