import React, { forwardRef, useState } from 'react';
import styled from '@emotion/styled';

import { ChevronTop, ChevronBottom } from '../../assets/icons';

const FaqContainer = styled('div')`
  padding: 28px 0;
  border-top: 1px solid var(--mocha);
  cursor: pointer;
`;

const QuestionContainer = styled('div')`
  display: grid;
  grid-template-columns: ${(props) =>
    props.iconLeft ? '30px auto 46px' : 'auto 46px'};
`;

const IconRight = styled('img')`
  justify-self: right;
  height: 24px;
  width: 24px;
`;

const IconLeft = styled('img')`
  height: 24px;
  width: 24px;
`;

const Answer = styled.div`
  white-space: pre-line;
`;

const Question = styled('p')`
  margin-bottom: 0;
`;

const FaqItem = (
  { iconLeft, question = '[Question]', answer = '[Answer]', style },
  ref
) => {
  const [faqItemOpen, setFaqItemOpen] = useState(false);

  const handleClick = () => {
    setFaqItemOpen(!faqItemOpen);
  };

  return (
    <FaqContainer ref={ref} style={style}>
      <QuestionContainer iconLeft={iconLeft} onClick={handleClick}>
        {iconLeft && <IconLeft src={iconLeft} alt='icon' />}
        <Question className='inter-body-15-sb'>{question}</Question>
        <IconRight
          src={faqItemOpen ? ChevronTop : ChevronBottom}
          alt='chevron'
        />
      </QuestionContainer>
      {faqItemOpen && <Answer className='noto-body-15'>{answer}</Answer>}
    </FaqContainer>
  );
};

export default forwardRef(FaqItem);
