import React, { forwardRef, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import { ArrowLeft } from '../assets/icons';
import Menu from './GlobalMenu';
import { mq } from '../helpers/utils';

const HeaderMain = styled('div')`
  grid-area: header;
  display: grid;
  grid-template-areas: 'headerItem1 headerItem2';
  grid-template-columns: 50px 1fr;
  align-items: center;
  padding: 25px 30px 0 30px;

  ${mq('lg')} {
    padding: 25px 0 0 0;
  }
`;

const HeaderItem1 = styled('div')`
  grid-area: headerItem1;
  cursor: pointer;
`;

const HeaderItem2 = styled('div')`
  grid-area: headerItem2;
  justify-self: end;
  cursor: pointer;
  display: ${(props) => (props.open ? 'none' : 'block')};
`;

const Header = (
  {
    style,
    headerMainClass,
    text = 'Menu',
    icon = ArrowLeft,
    backAction,
    noBack
  },
  ref
) => {
  const [open, setOpen] = useState(false);

  const history = useHistory();

  const handleBackAction = () => (backAction ? backAction() : history.goBack());

  return (
    <HeaderMain style={style} className={headerMainClass} ref={ref}>
      {noBack || (
        <HeaderItem1 onClick={handleBackAction}>
          <img src={icon} alt={text} />
        </HeaderItem1>
      )}
      <HeaderItem2
        open={open}
        onClick={() => setOpen(true)}
        className='inter-body-15-sb'
      >
        {text}
      </HeaderItem2>
      {open && <Menu action={() => setOpen(false)} />}
    </HeaderMain>
  );
};

export default forwardRef(Header);
