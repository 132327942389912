import { createAction } from '@reduxjs/toolkit';

export const quizSetName = createAction('QUIZ/QUIZ_SET_NAME');
export const quizGotoNext = createAction('QUIZ/QUIZ_CHANGE_SECTION_NEXT');
export const quizGotoPrevious = createAction(
  'QUIZ/QUIZ_CHANGE_SECTION_PREVIOUS'
);
export const quizSetPreferredDrink = createAction(
  'USER/QUIZ_SET_PREFERRED_DRINK'
);
export const quizSetDrinkingFrequency = createAction(
  'USER/QUIZ_SET_DRINKING_FREQUENCY'
);
export const quizSetHasDietaryRestriction = createAction(
  'USER/QUIZ_SET_HAS_DIETARY_RESTRICTION'
);
export const quizSetDietaryRestriction = createAction(
  'USER/QUIZ_SET_DIETARY_RESTRICTION'
);
export const quizSetHotOrIced = createAction('USER/QUIZ_SET_HOT_OR_ICED');
export const quizSetHasSnackWithDrink = createAction(
  'USER/QUIZ_SET_HAS_SNACK_WITH_DRINK'
);
export const quizSetSnack = createAction('QUIZ/QUIZ_SET_SNACK');
export const quizSetEmail = createAction('QUIZ/QUIZ_SET_EMAIL');
export const quizSetConsentGiven = createAction('QUIZ/QUIZ_SET_CONSENT_GIVEN');
export const initQuiz = createAction('QUIZ/INIT_QUIZ');
