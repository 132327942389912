import Header from '../Header';
import {
  Footer,
  FooterButton,
  StyledVerticalMultiSelect,
  StyledVerticalMultiSelectOption,
  Title
} from '../index';
import { ArrowRight } from '../../assets/icons';
import React from 'react';
import styled from '@emotion/styled';
import { mq } from '../../helpers/utils';

const CustomContent = styled('div')`
  padding: 0 33px 33px 33px;
  position: relative;
  z-index: 2;
  > div {
    text-align: center;
  }
  > div > div {
    text-align: left;
  }
  ${mq('lg')} {
    padding: 0 0 33px 0;
  }
`;

const CustomWrapper = styled('div')`
  padding-bottom: 100px;
  ${mq('lg')} {
    height: auto;
    overflow: visible;
    max-width: 404px;
    margin: 0 auto;
    padding-bottom: 150px;
  }
`;

const Error = styled('div')`
  color: #c23d4b;
`;

const PUCKUP_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

const PickupDay = ({
  order,
  titleText,
  pickupStore,
  onDaySelect,
  onContinueClick,
  backAction,
  disabled,
  errorMessage
}) => {
  return (
    <CustomWrapper>
      <Header backAction={backAction} />
      <Title title={titleText} />
      <CustomContent>
        <Error>{errorMessage}</Error>
        <StyledVerticalMultiSelect
          defaultValue={order.deliveryDaySelection}
          onChange={onDaySelect}
        >
          {PUCKUP_DAYS.map((operatingDay, index) => {
            return (
              <StyledVerticalMultiSelectOption
                value={index + 1}
                key={operatingDay}
                disabled={!pickupStore.operatingDays.includes(operatingDay)}
              >
                {operatingDay}
              </StyledVerticalMultiSelectOption>
            );
          })}
        </StyledVerticalMultiSelect>
      </CustomContent>
      <Footer>
        <FooterButton
          disabled={disabled}
          icon={ArrowRight}
          textRight='Continue'
          actionRight={onContinueClick}
        />
      </Footer>
    </CustomWrapper>
  );
};

export default PickupDay;
