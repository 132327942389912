import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PickupTimeslot from '../../components/delivery-options/PickupTimeslot';
import { changePickupTimeSlot } from '../../actions/Order';
import ReactPixel from "react-facebook-pixel";

const ChangePickupTimeslot = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pickupStore = useSelector(
    (state) =>
    {
      return state.addressDetail.address.addressDescription ? state.addressDetail.pickupStores.filter(
              ({ addressDescription }) =>
                  addressDescription === state.addressDetail.address.addressDescription)[0] : state.addressDetail.pickupStores[0]
    }
  );
  React.useEffect(() => {ReactPixel.pageView()}, [])
  const handleChange = (hour, minutes) => {
    ReactPixel.trackCustom("ChangePickupTimeSlots", {value: hour + ":" + minutes})
    dispatch(changePickupTimeSlot({ hour: hour, time: minutes }));
    history.push('/manage/confirm-change');
  };
  return (
    <PickupTimeslot
      titleText={'Select your in-store pick up time'}
      pickupStore={pickupStore}
      onContinueClick={handleChange}
    />
  );
};

export default ChangePickupTimeslot;
