import React from 'react';
import styled from '@emotion/styled';

import '../../assets/styles/styles.css';
import {
  StyledVerticalMenu,
  StyledVerticalOption,
  Header,
  FooterButton,
  Footer,
  Wrapper,
  Title
} from '../../components';
import { ArrowRight, ArrowLeft } from '../../assets/icons';
import { mq } from '../../helpers/utils';

const CustomContent = styled('div')`
  padding: 0 33px 33px 33px;
  position: relative;
  z-index: 2;
  > div {
    text-align: center;
  }
  > div > div {
    text-align: left;
  }
  ${mq('lg')} {
    padding: 0 0 33px 0;
  }
`;

const DeliveryTimeslot = ({
  titleText,
  onContinueClick,
  onTimeslotChange,
  defaultValue,
  disabled = false
}) => {
  return (
    <Wrapper>
      <Header headerMainClass='inter-body-15' text='Menu' icon={ArrowLeft} />
      <Title title={titleText} />
      <CustomContent>
        <StyledVerticalMenu
          defaultValue={defaultValue}
          onChange={onTimeslotChange}
        >
          <StyledVerticalOption value={1}>
            AM (8:30am to 11:30am)
          </StyledVerticalOption>
          <StyledVerticalOption value={2}>
            PM (2:00pm to 4:30pm)
          </StyledVerticalOption>
        </StyledVerticalMenu>
      </CustomContent>
      <Footer>
        <FooterButton
          icon={ArrowRight}
          disabled={disabled}
          textRight='Continue'
          actionRight={onContinueClick}
        />
      </Footer>
    </Wrapper>
  );
};

export default DeliveryTimeslot;
