import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import {
  Header,
  Title,
  Content,
  SummaryTable,
  Footer,
  FooterButton,
  Disclaimer,
  InputTextArea,
  OptionInfo,
  Loader
} from './../components';
import AddressCard from './address-flow/components/AddressCard';
import { ArrowRight } from './../assets/icons';
import {
  toggleLoading,
  setCoffeesTypes,
  setPurchaseReview,
  setSpecialInstructions
} from './../actions/Order';
import { getDeliveryFee } from './../actions/Payments';
import { TextBox } from './plan-recommendation';
import { mq } from '../helpers/utils';
import { addressResetGoto } from '../actions/Address';
import ReactPixel from "react-facebook-pixel";

const CustomWrapper = styled('div')`
  margin: 0 auto;

  ${mq('lg')} {
    overflow: visible;
    max-width: 404px;
    margin: 0 auto;
  }
`;

const WhiteSpace = styled('div')`
  display: none;

  ${mq('lg')} {
    display: block;
  }
`;

const BottomWrapper = styled('div')`
  background-color: var(--aut-lait-bgd);
  padding-bottom: 100px;

  ${mq('lg')} {
    padding-bottom: 35px;
  }
`;

const PurchaseReview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader } = useSelector((state) => state.loader);
  const { favouriteCoffee } = useSelector((state) => state.coffee);
  const { preferredDrink, hotOrIced, name } = useSelector(
    (state) => state.quizInput.quiz
  );
  const { summary, myPlanSummary, deliveryPlan, orderType } = useSelector(
    (state) => state.orderDetail.order
  );
  const customer = useSelector((state) => state.orderDetail.customer);
  const { isLoggedIn } = useSelector((state) => state.userDetail.user);
  const address = useSelector((state) => state.addressDetail.address);
  const postalCode = address.postalCode;

  const handleContinue = () => {
    if (isLoggedIn) {
      // TODO: Where does this go to? What to check?
      console.log(
        'Goes to EmailPrompt to create new account? What page is it?'
      );
    } else {
      if (customer.id) {
        history.push('/capture-cc');
      } else {
        history.push('/signup');
        console.log('Goes to Enter Credit card? What page is it?');
      }
    }
  };
  const handlePlanEdit = () => history.push('/plan');
  const handleDeliveryEdit = () => history.push('/coffee-handle');
  const handleInputChange = (e) =>
    dispatch(setSpecialInstructions(e.target.value.trim()));

  const editHandler = () => {
    dispatch(addressResetGoto());
    history.push('/coffee-handle');
  };

  useEffect(() => {
    const getData = async () => {
      await dispatch(toggleLoading(true));
      if (!favouriteCoffee) {
        await dispatch(
          setCoffeesTypes({
            drinkType: preferredDrink || 'no-preference',
            coffeeTemperature: hotOrIced || 'no-preference'
          })
        );
      }
      await dispatch(getDeliveryFee(postalCode));
      await dispatch(setPurchaseReview());
      await dispatch(toggleLoading(false));
    };
    getData();
  }, [dispatch , favouriteCoffee , hotOrIced , postalCode , preferredDrink]);

  React.useEffect(() => {ReactPixel.pageView()}, [])

  return loader ? (
    <Loader iconText='Just a minute! We are brewing a plan for you based on your preferences.' />
  ) : (
    <>
      <div style={{ backgroundColor: 'white' }}>
        <CustomWrapper>
          <Header />
          <Title
            style={{ paddingBottom: '33px' }}
            title={`${
              name ? `${name}, ` : ''
            }REVIEW THE DETAILS OF YOUR SUBSCRIPTION PLAN BEFORE WE PROCEED`}
          />
        </CustomWrapper>
        <Content style={{ paddingBottom: '0', marginBottom: '33px' }}>
          <CustomWrapper>
            <SummaryTable
              title='My Plan Summary'
              list={myPlanSummary}
              edit
              action={handlePlanEdit}
            />
            <SummaryTable
              style={{ marginBottom: '0' }}
              title={`${orderType} Plan`}
              list={deliveryPlan}
              edit
              action={handleDeliveryEdit}
            />
          </CustomWrapper>
        </Content>
        <Content
          style={{
            backgroundColor: 'var(--au-lait)',
            paddingTop: '43px'
          }}
        >
          <CustomWrapper>
            <SummaryTable
              title='Order Summary'
              list={summary}
              summary
              firstMonth
            />
            <TextBox
              style={{ marginTop: '0', padding: '13px 29px 12px 29px' }}
              text='In support of reforestation projects with Conservation Action Network Borneo planting trees in the Kelay River bufferzone, we will contribute the equivalent of planting and maintaining one tree for every subscription. This helps to sequester carbon emissions from Cloud Bar deliveries. Thank you for your contribution!'
            />
            <OptionInfo title='Special Instructions' />
            <InputTextArea
              onChange={handleInputChange}
              placeholder='Any special requirements?'
              type='text'
              name='special_requirements'
              id='special_requirements'
              label='Any special requirements?'
              area
            />
          </CustomWrapper>
        </Content>
      </div>
      <BottomWrapper>
        <Content style={{ paddingTop: '43px' }}>
          <CustomWrapper>
            <OptionInfo title='Address' />
            <AddressCard
              addressDescription={address.addressDescription}
              addressString={address.addressString}
              action={editHandler}
            />
            <Disclaimer
              title='Disclaimer'
              text={`By clicking "Complete Order", you are accepting our Terms of Service. You agree that your subscription will be auto-renewed every month. The recurring charge amount may change if you change details of your subscription (drink, frequency, address). Cancel your subscription anytime by visiting your profile page. All prices are in SGD and inclusive of GST.`}
            />
          </CustomWrapper>
        </Content>
      </BottomWrapper>
      <WhiteSpace
        style={{ backgroundColor: 'white', paddingBottom: '180px' }}
      ></WhiteSpace>
      <CustomWrapper>
        <Footer>
          <FooterButton
            icon={ArrowRight}
            textRight='Continue'
            actionRight={handleContinue}
          />
        </Footer>
      </CustomWrapper>
    </>
  );
};

export default PurchaseReview;
