import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addressGotoPrevious } from '../../../actions/Address';
import PostalCodeSection from './PostalCodeSection';
import UnitNumberSection from './UnitNumberSection';
import DescribeAddressSection from './DescribeAddressSection';
import ConfirmAddressSection from './ConfirmAddressSection';
import Flow from '../../../components/onboarding/Flow';
import { Wrapper } from '../../../components';

const NewAddress = () => {
  const dispatch = useDispatch();

  const currentSection = useSelector(
    (state) => state.addressDetail.address.address_stage
  );

  const postalCode = useSelector(
    (state) => state.addressDetail.address.postalCode || ''
  );

  const address = useSelector((state) => state.addressDetail.address);

  return (
    <Wrapper>
      <Flow
        currentSection={currentSection}
        onBackButtonClick={() => {
          dispatch(addressGotoPrevious());
        }}
      >
        <PostalCodeSection />
        <UnitNumberSection postalCode={postalCode} />
        <DescribeAddressSection />
        <ConfirmAddressSection address={address} />
      </Flow>
    </Wrapper>
  );
};

export default NewAddress;
