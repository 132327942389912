import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import { Coffee } from '../../assets/images';
import { mq } from '../../helpers/utils';

const ImageContainer = styled('div')`
  text-align: center;
`;

const InfoGroup = styled('div')`
  display: grid;
  grid-template-rows: 1fr auto;
  justify-items: center;
  > p {
    margin-bottom: 0;
  }

  ${mq('lg')} {
    > p {
      margin-bottom: 13px;
    }
  }
`;

const NameContainer = styled('div')`
  cursor: pointer;
  text-align: center;
  margin-bottom: 23px;
  & > p {
    color: var(--molasses) !important;
    margin-bottom: 0px;
  }
`;

const Description = styled('p')`
  margin-bottom: 34px !important;
  text-align: center;
`;

const SelectedProduct = (
  {
    product = {
      name: '[Product name]',
      description: '[Product description]',
      imgSrc: Coffee
    },
    action = () => console.log('Change my Coffee clicked!')
  },
  ref
) => {
  return (
    <>
      <ImageContainer>
        <img ref={ref} src={product.imgSrc} alt={product.name} />
      </ImageContainer>
      <InfoGroup>
        <NameContainer>
          <h1 className='large-title m-0 lh-1'>{product.name}</h1>
          <p className='noto-caption-11' onClick={action}>
            Change my drink
          </p>
        </NameContainer>
        <Description className='noto-body-15'>
          {product.description}
        </Description>
      </InfoGroup>
    </>
  );
};

export default forwardRef(SelectedProduct);
