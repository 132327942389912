import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Wrapper, PageWithImage } from '../components';
import { ArrowRight } from '../assets/icons';

import ReactPixel from 'react-facebook-pixel';

const CompletedSignup = () => {
  const history = useHistory();
  const { name } = useSelector((state) => state.quizInput.quiz);

  const goToQuizIntro = () => history.push('/quiz-intro');
  const goToProfile = () => history.push('/profile');

  React.useEffect(() => {ReactPixel.pageView(); ReactPixel.track('Subscribe');}, [])

  return (
    <>
      <Wrapper style={{ backgroundColor: 'white' }}>
        <PageWithImage
          title={`${
            name ? `HI ${name}!` : 'HELLO THERE!'
          } WE'RE LOOKING FORWARD TO MAKING YOUR DAY A LITTLE BIT BETTR.`}
          text='Answer a few questions to start building your subscription, or take a look at our plans.'
          CTAText='See all plans'
          footer1Action={goToQuizIntro}
          footer1Icon={ArrowRight}
          footer1Text='Take the quiz'
          footer2Text='Go to my profile'
          footer2Style={{ backgroundColor: 'var(--au-lait)' }}
          footer2Action={goToProfile}
          noBack
        />
      </Wrapper>
    </>
  );
};

export default CompletedSignup;
