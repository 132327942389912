import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';

import validator from 'validator';
import { ArrowRight } from '../../assets/icons';
import { quizSetEmail, quizSetConsentGiven } from '../../actions/Quiz';
import { createEdmContact } from '../../actions/User';
import { Input, FooterButton, Footer, Title, Content } from '../../components';
import { ConfirmationButton } from '../../components';
import { _ErrorMessages as msg } from '../../constants/ErrorMessages';
import { mq } from '../../helpers/utils';

const MobileConfirmation = styled('div')`
  display: block;
  ${mq('lg')} {
    display: none;
  }
`;
const DesktopConfirmation = styled('div')`
  display: none;
  ${mq('lg')} {
    display: block;
  }
`;

const EmailPrompt = () => {
  const quizInputEmail = useSelector((state) => state.quizInput.quiz.email);
  const quizInputName = useSelector((state) => state.quizInput.quiz.name);
  const userDetailEmail = useSelector((state) => state.userDetail.user.email);
  const consentGiven = useSelector(
    (state) => state.quizInput.quiz.consentGiven
  );

  const [emailErrMsg, setEmailErrMsg] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  const toggleConsent = () => {
    dispatch(quizSetConsentGiven(!consentGiven));
  };

  const continueHandler = () => {
    if (!validator.isEmail(quizInputEmail.trim())) {
      setEmailErrMsg(msg.validEmail);
    } else {
      dispatch(quizSetEmail(quizInputEmail.trim()));
      setEmailErrMsg('');
      if(consentGiven){
        dispatch(createEdmContact({ email: quizInputEmail.trim(), name: quizInputName}));
      }
      history.push('/coffee-type');
    }
  };

  const skipHandler = () => {
    dispatch(quizSetEmail(''));
    history.push('/coffee-type');
  };

  return (
    <>
      <Title
        title={`LET'S CREATE AN ACCOUNT FOR YOU SO YOU CAN SAVE YOUR DRINK PREFERENCES. WHAT'S YOUR EMAIL ADDRESS?`}
      />
      <Content style={{ marginTop: '20px', position: 'relative' }}>
        <Input
          onChange={(e) => {
            dispatch(quizSetEmail(e.target.value));
          }}
          placeholder='Email Address'
          type='email'
          value={quizInputEmail || userDetailEmail}
          errMsg={emailErrMsg}
          name='email'
          label='Email Address'
        />
        <MobileConfirmation>
          <ConfirmationButton
            action={() => toggleConsent()}
            checked={consentGiven}
            text={`I'd like to be kept up to date on news and exclusive offers.`}
          />
        </MobileConfirmation>
      </Content>
      <Footer>
        <DesktopConfirmation>
          <ConfirmationButton
            action={() => toggleConsent()}
            checked={consentGiven}
            text={`I'd like to be kept up to date on news and exclusive offers.`}
          />
        </DesktopConfirmation>
        <FooterButton
          style={{ backgroundColor: 'var(--bettr-yellow' }}
          icon={ArrowRight}
          textRight='Continue'
          textLeft='Skip'
          actionRight={() => {
            continueHandler();
          }}
          actionLeft={() => {
            skipHandler();
          }}
          disabled={quizInputEmail.trim().length < 1}
        />
      </Footer>
    </>
  );
};

export default EmailPrompt;
