import { createAction } from '@reduxjs/toolkit';
import { customerService } from '../services/customer';
import { setCoffeeTypes } from './Coffee';
import { setSubscription } from './User';
import moment from 'moment';

// selectedCoffee
export const setSelectedCoffee = createAction('ORDER/SET_SELECTED_COFFEE');

// recommendation
export const setRecommendation = createAction('ORDER/SET_RECOMMENDATION');
export const setOriginalPlans = createAction('ORDER/SET_ORIGINALS_PLANS');
export const clearOriginalPlanValues = createAction(
  'ORDER/CLEAR_ORIGINALS_PLANS'
);

// defaults
export const setDefaults = createAction('ORDER/SET_DEFAULTS');

// add-ons
export const setAddOn = createAction('ORDER/SET_ADDON');

// discount
export const setDiscount = createAction('ORDER/SET_DISCOUNT');
export const setDiscountError = createAction('ORDER/SET_DISCOUNT_ERROR');
export const removeDiscount = createAction('ORDER/REMOVE_DISCOUNT');

// delivery
export const changeBestDeliveryDays = createAction(
  'ORDER/CHANGE_BEST_DELIVERY_DATES'
);
export const changeDeliveryTimeSlot = createAction(
  'ORDER/CHANGE_DELIVERY_TIMESLOT'
);
export const setDeliveryFee = createAction('ORDER/SET_DELIVERY_FEE');

// loading
export const toggleLoading = createAction('ORDER/TOGGLE_LOADING');

// purchase review
export const setPurchaseReview = createAction('ORDER/SET_PURCHASE_REVIEW');

// confirmChange
export const setConfirmChange = createAction('ORDER/SET_CONFIRM_CHANGE');

export const setNextDelivery = createAction('ORDER/SET_NEXT_DELIVERY');
// special instructions
export const setSpecialInstructions = createAction(
  'ORDER/SET_SPECIAL_INSTRUCTIONS'
);

// APIs
export const setCustomer = createAction('ORDER/SET_CUSTOMER');

export const getCustomer = (firstMonth, delivery, nextMonth) => async (
  dispatch,
  getState
) => {
  try {
    const result = await customerService.getCustomer();

    await dispatch(
      setSubscription({ data: result.data.subscriptions.data[0] })
    );
    await dispatch(
      setCustomer({ data: result.data, firstMonth, delivery, nextMonth })
    );
  } catch (e) {
    console.log(e);
  }
};

// TODO: Consolidate setCustomer and setStripeCustomer
export const setStripeCustomer = createAction('ORDER/SET_STRIPE_CUSTOMER');

export const getStripeCustomer = () => async (dispatch) => {
  try {
    const result = await customerService.getCustomer();
    await dispatch(setStripeCustomer({ data: result.data }));
  } catch (e) {
    console.log(e);
  }
};

export const editCustomer = ({
  customerId,
  addressDescription,
  streetName,
  unitNo,
  postalCode
}) => async (dispatch) => {
  try {
    const result = await customerService.editCustomer({
      customerId: customerId,
      data: {
        shipping: {
          address: {
            city: 'Singapore',
            country: 'Singapore',
            line1: streetName,
            line2: unitNo,
            postal_code: postalCode,
            state: ''
          },
          name: '',
          phone: ''
        },
        metadata: {
          ADDRESS_DESCRIPTION: addressDescription
        }
      }
    });
    await dispatch(setCustomer({ data: result.data }));
  } catch (e) {
    console.log(e);
  }
};

export const getNextDelivery = () => async (dispatch) => {
  try {
    const result = await customerService.getNextDelivery();
    await dispatch(setNextDelivery(result.data));
  } catch (e) {
    console.log(e);
  }
};

export const getRecommendation = (
  productId,
  body,
  { firstMonth, delivery, nextMonth }
) => async (dispatch, getState) => {
  const subscription = getState().userDetail.user.subscription;
  try {
    let prod = productId;
    if (!productId) {
      prod = getState().coffee.favouriteCoffeeChangePlan;
    }
    const result = await customerService.getRecommendation(prod, body);
    if (subscription?.metadata) {
      if (
        subscription.metadata.COFFEE_RECEIVE_MODE.toLowerCase() ===
        'Pickup'.toLowerCase()
      ) {
        const time = moment(subscription.metadata.SERVICE_TIME, 'h:mmA');
        const hours = time.hour();
        dispatch(
          changePickupTimeSlot({
            hour: `${hours > 12 ? hours - 12 : hours}`,
            time: `${time.minutes() ? time.minutes() : '00'}`
          })
        );
      } else {
        dispatch(changeDeliveryTimeSlot(subscription.metadata.SERVICE_TIME));
      }
    }
    dispatch(
      setRecommendation({
        data: result.data,
        firstMonth,
        delivery,
        nextMonth
      })
    );
  } catch (e) {
    console.log(e);
  }
};

export const setCoffeesTypes = (body) => async (dispatch) => {
  try {
    const result = await customerService.getFavouriteCoffee(body);
    dispatch(setCoffeeTypes(result.data));
  } catch (e) {
    console.log(e);
  }
};
export const changePickupTimeSlot = createAction(
  'ORDER/CHANGE_PICKUP_TIMESLOT'
);

export const setOrderType = createAction('ORDER/SET_ORDER_TYPE');
export const initOrderDetails = createAction('ORDER/INIT_ORDERS');
