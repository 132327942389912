import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { _ErrorMessages as msg } from '../../constants/ErrorMessages';
import { ArrowRight } from '../../assets/icons';
import { signupGotoNext, signupSetPhoneNumber } from '../../actions/User';
import { Input, FooterButton, Footer, Title, Content } from '../../components';
import { validateMobileNumber } from '../../helpers/utils';

const PhoneNumberSection = (props) => {
  const [phoneErrMsg, setPhoneErrMsg] = useState('');
  const currentUser = useSelector((state) => state.userDetail.user);
  const dispatch = useDispatch();

  const validationsHandler = () => {
    if (!validateMobileNumber(currentUser.phoneNumber.replace(/ /g, ''))) {
      setPhoneErrMsg(msg.invalidPhoneNumber);
    } else {
      setPhoneErrMsg(null);
      dispatch(signupGotoNext());
    }
  };

  return (
    <div>
      <Title title='What is your mobile number?' />
      <Content style={{ marginTop: '20px' }}>
        <Input
          placeholder='Mobile Number'
          onChange={(e) => {
            dispatch(signupSetPhoneNumber(e.target.value));
          }}
          value={currentUser.phoneNumber}
          errMsg={phoneErrMsg}
          type='phone'
          name='phone'
          label='Mobile Number'
        />
      </Content>

      <Footer>
        <FooterButton
          icon={ArrowRight}
          textRight='Continue'
          actionRight={() => {
            validationsHandler();
          }}
        />
      </Footer>
    </div>
  );
};

export default PhoneNumberSection;
