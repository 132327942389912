import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Popconfirm } from 'antd';

import { ChevronRight } from '../assets/icons';

const Container = styled.div`
  height: 70px;
  padding: 0 34px;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  max-width: 404px;
  margin: 0 auto;

  > span {
    display: grid;
    align-items: center;
    grid-template-columns: auto 8fr 1fr;
    cursor: pointer;
  }
`;

const LeftIcon = styled.img`
  margin-right: 30px;
`;

const RightIcon = styled.img`
  opacity: 50%;
  margin-left: 17px;
`;

const ProfileFooter = (
  {
    text = '[Text]',
    leftIcon = ChevronRight,
    color = 'var(--bettr-yellow)',
    action = () => console.log(text + ' button clicked'),
    popAction,
    popText,
    disactive
  },
  ref
) => {
  return (
    <Container backgroundColor={color} ref={ref}>
      <span onClick={action} className={`inter-body-15-sb ${disactive ? 'disactive' : ''}`} >
        <LeftIcon src={leftIcon} alt={`${text} icon `} />
        {popAction ? (
          <Popconfirm
            title={popText}
            onConfirm={popAction}
            placement="top"
            okText='Yes'
            cancelText='No'

          >
            <a style={{ color: "#3F4444" }}>
              <div className='inter-body-15-sb'>{text}</div>
            </a>
          </Popconfirm>
        ) : (
          <div className='inter-body-15-sb'>{text}</div>
        )}

        <RightIcon src={ChevronRight} alt={`go to ${text}`} />
      </span>
    </Container>
  );
};

export default forwardRef(ProfileFooter);
