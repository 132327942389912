import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Content, Header, Title, Wrapper } from '../../../components';
import { ArrowLeft } from '../../../assets/icons';
import AddressCard from '../components/AddressCard';
import {
  setAddressDescription,
  setAddressString,
  setStreetName,
  setUnitNumber
} from '../../../actions/Address';
import { editCustomer } from '../../../actions/Order';

const OrderAndPickUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const pickupStores = useSelector((state) => state.addressDetail.pickupStores);
  const customer = useSelector((state) => state.orderDetail.customer);

  const clickHandler = async (
    { addressString, addressDescription },
    postalCode,
    unitNo,
    streetName
  ) => {
    dispatch(setAddressString(addressString));
    dispatch(setAddressDescription(addressDescription));
    dispatch(setUnitNumber(unitNo));
    dispatch(setStreetName(streetName));

    if (customer.id && customer.subscriptions.data.length !== 0) {
      await dispatch(
        editCustomer({
          customerId: customer.id,
          addressDescription: addressDescription,
          streetName: streetName,
          unitNo: unitNo,
          postalCode: postalCode
        })
      );
      history.push('/manage/saved-plan-updates');
    } else {
      history.push('/select-pickup-day');
    }
  };

  return (
    <Wrapper>
      <Header headerMainClass='inter-body-15' text='Menu' icon={ArrowLeft} />
      <Title className='large-title' title='SELECT A STORE FOR PICK UP' />
      <Content>
        {pickupStores.map(
          (
            {
              addressDescription,
              addressString,
              postalCode,
              unitNo,
              streetName
            },
            index
          ) => {
            return (
              <AddressCard
                style={{ cursor: 'pointer' }}
                addressString={addressString + `\nSingapore - ${postalCode}`}
                addressDescription={addressDescription}
                noEditPencil={true}
                key={index}
                onClick={(prop) =>
                  clickHandler(prop, postalCode, unitNo, streetName)
                }
              />
            );
          }
        )}
      </Content>
    </Wrapper>
  );
};

export default OrderAndPickUp;

//change address to adressLine1 and adressLine2
