import React from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import '../../assets/styles/styles.css';
import {DeliveryTimeslot} from '../../components';
import {changeDeliveryTimeSlot} from '../../actions/Order';
import ReactPixel from "react-facebook-pixel";

const SelectDeliveryTimeSlot = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const timeSlot = useSelector(
    (state) => state.orderDetail.order.deliveryTimeslot
  );

  React.useEffect(() => {ReactPixel.pageView()}, [])

  const changeHandler = (e) => {
    dispatch(changeDeliveryTimeSlot(e.target.value));
  };

  const handleContinueClick = () => history.push('/purchase-review');

  return (
    <DeliveryTimeslot
      titleText='CHOOSE BETWEEN A MORNING OR AFTERNOON SLOT FOR DELIVERY.'
      onContinueClick={handleContinueClick}
      onTimeslotChange={changeHandler}
      defaultValue={timeSlot}
      disabled={timeSlot === undefined}
    />
  );
};

export default SelectDeliveryTimeSlot;
