import { axiosRequest } from '../helpers/request';

const getCustomer = async () => {
  const url = `/api/customer/cus_IOlU5ka2UZxZGQ`;
  const response = await axiosRequest.get(url).exec();
  return response;
};

const editCustomer = async (body) => {
  const url = `/api/customer/edit`;
  const response = await axiosRequest.post(url, body).exec();
  return response;
};

const getRecommendation = async (productId, body) => {
  const url = `/api/coffee-types/${productId}/recommendation`;
  const response = await axiosRequest.post(url, body).exec();
  return response;
};

const getFavouriteCoffee = async (body) => {
  const url = `/api/coffee-types/favorite/`;
  if(body.drinkType === 'non-caffeinated') {
    body.drinkType = 'decaf'
  }
  const response = await axiosRequest.post(url, body).exec();
  return response;
};

const getNextDelivery = async () => {
  const url = `/api/orders/next-delivery`;
  const response = await axiosRequest.get(url).exec();
  return response;
};

const postFeedback = async (body) => {
  const url = `/api/customer/feedback`;
  const response = await axiosRequest.post(url, body).exec();
  return response;
};

const createEdmContact = async (body) => {
  const url = `/api/customer/create-edm`;
  const response = await axiosRequest.post(url, body).exec();
  return response;
};

const getFaqList = async (body) => {
  const url = `/api/faq-list/${body}`;
  const response = await axiosRequest.get(url).exec();
  return response;
};

export const customerService = {
  getCustomer,
  editCustomer,
  getRecommendation,
  getFavouriteCoffee,
  getNextDelivery,
  postFeedback,
  createEdmContact,
  getFaqList
};
