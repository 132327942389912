import React from 'react';
import TextArea from 'react-autosize-textarea';
import styled from '@emotion/styled';
import { useDebounce } from '../helpers/hooks/useDebounce';

const InputLine = styled('div')`
  width: 2px;
  min-height: 50px;
  height: 100%;
  background-color: var(--mocha);
`;

const InputLabel = styled('label')`
  position: absolute;
  left: 11px;
  top: 13px;
  color: var(--mocha);
  opacity: ${(props) => (props.value ? '1' : '0')};
  transition: all 0.2s;
  transform-origin: left bottom;
  pointer-events: none;
`;

const TextAreaContainer = styled('div')`
  display: grid;
  grid-template-columns: 10px 1fr;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  > textarea {
    border: none;
    width: 90%;
    background-color: transparent;
    font-size: var(--body-17);
    font-family: var(--noto);
    color: var(--french);
    transition: all 0.2s;
    padding: 11px 0px;
    &::placeholder {
      opacity: 1;
    }
    &:focus {
      outline: 0;
    }
    &${(props) => (!props.value ? ':focus ' : '')}+ label {
      opacity: 1;
      transform: translate(0, -24px) scale(0.7);
      touch-action: manipulation;
    }
    &:focus::placeholder {
      opacity: 0;
    }
    &:not(:placeholder-shown) + label {
      opacity: 1;
      transform: translate(0, -24px) scale(0.7);
      touch-action: manipulation;
    }
  }
`;

const InputTextArea = (props) => {
  const {
    onChange = () => {},
    value: propValue,
    timeout = 300,
    placeholder = '[Placeholder]',
    name = '[textarea]',
    id,
    label = '[Label]',
    errMsg
  } = props;
  const [value, handleOnChange] = useDebounce(
    propValue || '',
    onChange,
    timeout
  );
  const errorStyle = { backgroundColor: 'var(--wine)' };
  const valueStyle = { backgroundColor: 'var(--french)' };

  return (
    <TextAreaContainer>
      <InputLine
        style={errMsg ? errorStyle : value ? valueStyle : {}}
      ></InputLine>
      <TextArea
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
        type='text'
        name={name}
        id={id}
      />
      <InputLabel className='noto-body-17' htmlFor={name}>
        {label}
      </InputLabel>
    </TextAreaContainer>
  );
};

export default InputTextArea;
