import React, {useEffect, useState} from 'react';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordConfirm from './pages/ForgotPasswordConfirm';
import NewPassword from './pages/NewPassword';
import NewPasswordConfirm from './pages/NewPasswordConfirm';
import Signup from './pages/signup-flow';
import NewAddress from './pages/address-flow/new-address-flow';
import {useDispatch, useSelector} from 'react-redux';
import {
    Address,
    OrderAndPickUp,
    SeeYouAtStore,
    SelectPickupDay,
    SelectPickupTime,
    WorkInProgress
} from './pages/address-flow';
import {SelectDeliveryTimeSlot, SelectDeliveryWindow} from './pages/delivery-flow';
import CoffeeHandle from './pages/onboarding-flow/CoffeeHandle';
import QuizIntro from './pages/quiz-flow/QuizIntro';
import QuizIndex from './pages/quiz-flow/QuizIndex';
import PlanRecommendation from './pages/plan-recommendation/PlanRecommendation';
import {Redirect, Route, Switch} from 'react-router-dom';
import CoffeeType from './pages/CoffeeType';
import Profile from './pages/member/ProfilePage';
import PaymentMethod from './pages/payment-methods';
import {handleAuthentication, logout} from './auth0';
import CaptureCreditCard from './pages/purchase-flow/CaptureCreditCard';
import FeedbackTopic from './pages/feedback/FeedbackTopic';
import FeedbackInfo from './pages/feedback/FeedbackInfo';
import FeedbackThanks from './pages/feedback/FeedbackThanks';
import {
    CannotChange,
    ChangeCoffee,
    ChangeDeliveryDay,
    ChangeDeliveryTimeslot,
    ChangePlan,
    ConfirmChange,
    SavedPlanUpdates
} from './pages/manage-subscription/';
import PurchaseReview from './pages/PurchaseReview';
import CompletedSignup from './pages/CompletedSignup';
import * as ReactGA from 'react-ga';
import ChangePickupDay from './pages/manage-subscription/ChangePickupDay';
import ChangePickupTimeslot from './pages/manage-subscription/ChangePickupTime';
import PaymentSuccess from './pages/purchase-flow/PaymentSuccess';
import TagManager from "react-gtm-module";
import {cleanGlobalStore} from "./actions/User";
import createActivityDetector from "activity-detector";
import ReactPixel from 'react-facebook-pixel';


const minLogoutTime = 1800000

const useIdle = (timeout) => {
    const [isIdle, setIsIdle] = useState(false)
    useEffect(() => {
        let activityDetector = createActivityDetector({timeToIdle:timeout , activityEvents:['click', 'keydown'] , inactivityEvents:[]});
        activityDetector.on('idle', e => {
            setIsIdle(true)
        })
        activityDetector.on('active', () => setIsIdle(false))
        return () => activityDetector.stop()
        //eslint-disable-next-line
    }, [])
    return isIdle
}
const PrivateRoute = ({children, ...rest}) => (
    <Route
        {...rest}
        render={({location}) =>
            (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: location}
                    }}
                />
            )
    }
  />
);

const SignupRoute = ({ component: Component, address, ...props }) => (
  <Route
    {...props}
    render={(props) =>
      address.addressString ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/quiz-intro',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const App = () => {
    const trackingId = 'UA-185644909-1';
    const pixelId = '111582101034350';

    let debug = true;
    if (process.env.FACEBOOK_PIXEL_DEBUG === 'false') {
        debug = false;
    }
    ReactPixel.init(pixelId, undefined, {autoConfig: true, debug: debug});
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
    const recommendation = useSelector(
        (state) => state.orderDetail.order.recommendation
    );
    const address = useSelector((state) => state.addressDetail.address);
    const dispatch = useDispatch()
    const isIdle = useIdle(minLogoutTime)


    const authTrigger = (nextState) => {
        console.log(nextState);
        if (/access_token|id_token|error/.test(nextState.location.hash)) {
            handleAuthentication(recommendation);
        }
    };


    useEffect(() => {
        const tagManagerArgs = {
            gtmId: "GTM-NWC3R8M",
            dataLayerName: "PageDataLayer"
        };
        TagManager.initialize(tagManagerArgs);
    }, [])

    useEffect(() => {
        if(isIdle){
            dispatch(cleanGlobalStore())
            logout()
            window.location.replace(process.env.REACT_APP_LANDING_PAGE_URL)
        }
    } , [isIdle , dispatch])


    return (
        <Switch>
            {/* Login, Signup Forget Password */}
            <PrivateRoute exact path='/'>
                <Redirect
                    to={{
                        pathname: '/login'
                    }}
                />
            </PrivateRoute>
      <Route exact path='/login' component={Login} />
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route
        exact
        path='/forgot-password-confirm'
        component={ForgotPasswordConfirm}
      />
      <Route exact path='/new-password' component={NewPassword} />
      <Route
        exact
        path='/new-password-confirm'
        component={NewPasswordConfirm}
      />
      <Route
        exact
        path='/callback'
        render={(props) => {
          authTrigger(props);
        }}
      />
      <SignupRoute exact path='/signup' component={Signup} address={address} />
      <Route exact path='/completed-signup' component={CompletedSignup} />

      {/* Quiz Select */}
      <Route exact path='/quiz-intro' component={QuizIntro} />
      <Route exact path='/quiz' component={QuizIndex} />
      <Route exact path='/coffee-type' component={CoffeeType} />

      <Route exact path='/plan' component={PlanRecommendation} />

      <Route exact path='/coffee-handle' component={CoffeeHandle} />

      {/* Purchase Review */}
      <Route exact path='/purchase-review' component={PurchaseReview} />

      {/* Credit Card & Address */}
      <Route exact path='/capture-cc' component={CaptureCreditCard} />
      <Route exact path='/payment-success' component={PaymentSuccess} />
      <Route
        exact
        path='/payment-methods/add-card'
        component={CaptureCreditCard}
      />
      <Route exact path='/payment-methods' component={PaymentMethod} />
      <Route exact path='/address/new-address' component={NewAddress} />
      <Route exact path='/address' component={Address} />

      {/* Manage Subscription */}
      <Route exact path='/profile' component={Profile} />
      <Route exact path='/manage/cannot-change' component={CannotChange} />
      <Route
        exact
        path='/manage/saved-plan-updates'
        component={SavedPlanUpdates}
      />
      <Route exact path='/manage/change-plan' component={ChangePlan} />
      <Route exact path='/manage/confirm-change' component={ConfirmChange} />
      <Route exact path='/manage/change-coffee' component={ChangeCoffee} />
      <Route
        exact
        path='/manage/change-delivery-day'
        component={ChangeDeliveryDay}
      />
      <Route
        exact
        path='/manage/change-delivery-timeslot'
        component={ChangeDeliveryTimeslot}
      />
      <Route
        exact
        path='/manage/change-pickup-day'
        component={ChangePickupDay}
      />
      <Route
        exact
        path='/manage/change-pickup-timeslot'
        component={ChangePickupTimeslot}
      />

      {/* Feedback Gathering */}
      <Route exact path='/feedback' component={FeedbackTopic} />
      <Route exact path='/feedback-info' component={FeedbackInfo} />
      <Route exact path='/feedback-thanks' component={FeedbackThanks} />

      {/* Other */}
      <Route exact path='/wip' component={WorkInProgress} />
      <Route exact path='/pickup' component={OrderAndPickUp} />
      <Route exact path='/select-pickup-day' component={SelectPickupDay} />
      <Route exact path='/select-pickup-time' component={SelectPickupTime} />
      <Route exact path='/see-you-at-store' component={SeeYouAtStore} />

      <Route
        exact
        path='/select-delivery-timeslot'
        component={SelectDeliveryTimeSlot}
      />
      <Route
        exact
        path='/select-delivery-day'
        component={SelectDeliveryWindow}
      />
      <Route component={Error} />
    </Switch>
  );
};

export default App;
