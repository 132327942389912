import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Checkbox as AntCheckbox } from 'antd';

import { mq } from '../../helpers/utils';

const StyledRadio = styled.div`
  .ant-checkbox-wrapper {
    display: block;
    width: 85vw;
    height: 95px;
    margin-top: 10px;
    background: #ffffff;
    font-family: Noto Serif;
    font-size: 15px;
    line-height: 20px;
    border-radius: 6px;
    color: #3F4444;

    ${mq('lg')} {
      max-width: 404px;
    }
  }

  .ant-checkbox + span {
      margin-top: 37px;
      margin-left: 30px;
      position: absolute;
    }
  }

  .ant-checkbox-wrapper span img {
    &:last-child {
      padding-right: 9px;
    }
  }

  .ant-checkbox {
    position: relative;
    right: 30px;
    top: 35px;
    padding: 10px;
    border-radius: 20px;
    float: right;
    background-color: #F9F6E8;
  }
  .ant-checkbox-inner {
    border-radius: 12px !important;
    width: 12px;
    height: 12px;
    border-color: transparent;
    background-color: transparent;
    &:after {
      opacity: 0;
      background-color: #ffc200;
    }
  }

  .ant-checkbox-checked {
    &:after {
      border: none;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ffc200;
    border-color: transparent;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: transparent;
    
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper{
    margin-left: 0px;
  }

  .ant-checkbox-wrapper-checked {
    background-color: #ffc200;
  }
`;

const StyledVerticalMultiSelect = (props, ref) => {
  const { placeholder, ...rest } = props;
  const node = useRef();

    //eslint-disable-next-line
  const [value, setValue] = useState('');

  const onChange = (e) => {
    props.onChange(e);
    setValue(e.target.value);
  };

  return (
    <StyledRadio ref={node}>
      <AntCheckbox.Group
        defaultValue={props.defaultValue}
        onChange={onChange}
        {...rest}
      >
        {props.children}
      </AntCheckbox.Group>
    </StyledRadio>
  );
};

export default StyledVerticalMultiSelect;
