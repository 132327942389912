import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import { Wrapper, Content, Footer, FooterButton, Header, Title } from './index';
import { ArrowLeft, User, ArrowRight } from '../assets/icons';
import { mq } from '../helpers/utils';

const Text = styled('p')`
  margin-right: ${(props) => (props.fullWidthText ? '0' : '51px')};
  white-space: pre-line;
`;

const Container = styled('div')`
  display: grid;
  grid-template-rows: auto 100px;
  position: absolute;
  bottom: 0;
  width: 100%;

  ${mq('lg')} {
    display: flex;
    position: relative;
    top: 50px;
    justify-content: center;
  }
`;

const Image = styled('img')`
  justify-self: right;
  margin: 0 20px 20px 0;

  ${mq('lg')} {
    margin: 0;
    transform: scale(1.1);
  }
`;

const CTAContainer = styled('div')`
  position: relative;
  display: flex;
  top: 28px;
`;

const CTATextBlock = styled('p')`
  margin-right: 7px;
  cursor: pointer;
`;

const PageWithImage = (
  {
    image,
    title,
    text,
    footer1Text,
    footer1TextLeft,
    footer1Icon,
    footer1Action = () => console.log('Going to redirect to the new address'),
    footer1ActionLeft = () =>
      console.log('Going to redirect to the new address'),
    footer1Style,
    footer2Text,
    footer2Icon,
    footer2Action = () => console.log('Going to redirect to the new address'),
    footer2Style,
    fullWidthText,
    CTAText,
    CTAAction = () => console.log('CTA Action clicked!'),
    noBack
  },
  ref
) => {
  return (
    <Wrapper style={{ backgroundColor: 'white', height: '100vh' }} ref={ref}>
      <Header
        headerMainClass='inter-body-15'
        text='Menu'
        icon={ArrowLeft}
        noBack={noBack}
      />
      <Title title={title} />
      <Content>
        <Text fullWidthText={fullWidthText} className='m-0 noto-body-15'>
          {text}
        </Text>
        {CTAText && (
          <CTAContainer>
            <CTATextBlock onClick={CTAAction} className='m-0 inter-body-15-sb'>
              {CTAText}
            </CTATextBlock>
            <img src={ArrowRight} alt='arrow-right' />
          </CTAContainer>
        )}
      </Content>
      <Container>{image && <Image src={image} alt='corner-img' />}</Container>
      <Footer>
        <FooterButton
          style={footer1Style || { backgroundColor: 'var(--bettr-yellow)' }}
          icon={footer1Icon || User}
          textRight={footer1Text}
          actionRight={footer1Action}
          textLeft={footer1TextLeft}
          actionLeft={footer1ActionLeft}
        />
        {footer2Text && (
          <FooterButton
            style={footer2Style || { backgroundColor: 'var(--bettr-yellow)' }}
            icon={footer2Icon || User}
            textRight={footer2Text}
            actionRight={footer2Action}
          />
        )}
      </Footer>
    </Wrapper>
  );
};

export default forwardRef(PageWithImage);
