import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import { mq } from '../helpers/utils';

const Section = styled('div')`
  display: grid;
  padding: 30px;
  background-color: var(--bettr-yellow);
  z-index: 10;
  bottom: 0;
  width: 100%;
  height: 100px;

  ${mq('lg')} {
    width: 404px;
    border-radius: 6px;
    height: 80px;
  }
`;

const Content = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Action = styled('div')`
  justify-items: end;
  align-self: center;
  display: grid;
  column-gap: 7px;
  grid-template-columns: ${(props) =>
    props.textLeft !== '' ? 'auto auto' : 'auto'};
`;

const Text = styled('span')`
  font-family: var(--inter);
  font-size: var(--body-15);
  color: var(--french);
  margin: 0 5px;
`;

const FooterButton = (
  {
    style,
    className,
    icon,
    textRight = '[textRight]',
    textLeft,
    disabled = false,
    disabledLeft = false,
    actionLeft = () => console.log('Left action FooterButton option clicked'),
    actionRight = () => console.log('Right action FooterButton option clicked')
  },
  ref
) => {
  return (
    <Section style={style} className={className}>
      <Action textLeft={textLeft || ''}>
        {textLeft && (
          <Content
            style={{ opacity: disabledLeft ? '0.3' : '1' }}
          >
            <div
              style={{ justifySelf: 'left', cursor: 'pointer' }}
              onClick={disabledLeft ? () => {} : actionLeft}
            >
              <Text className='inter-body-15-sb' ref={ref}>
                {textLeft}
              </Text>
            </div>
          </Content>
        )}
        <Content
          onClick={disabled ? () => {} : actionRight}
          style={{ opacity: disabled ? '0.3' : '1' }}
        >
          <Text className='inter-body-15-sb' ref={ref}>
            {textRight}
          </Text>
          <img style={{ verticalAlign: 'bottom' }} src={icon} alt={textRight} />
        </Content>
      </Action>
    </Section>
  );
};

export default forwardRef(FooterButton);
