import React from 'react';
import styled from '@emotion/styled';

import { Delivery, Coffee } from '../../assets/icons';
import '../../assets/styles/styles.css';
import {
  Header,
  Footer,
  StyledVerticalOption,
  StyledVerticalMenu,
  Title,
  Content,
  Wrapper
} from '../../components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOrderType } from '../../actions/Order';
import { mq } from '../../helpers/utils';
import ReactPixel from "react-facebook-pixel";

const VerticalOptionOverride = styled.div`
  box-sizing: border-box;
  height: 95px;
  width: 230px;
  position: absolute;
  top: -37px;
  left: 0;
  display: flex;
  align-items: center;
  white-space: pre-wrap;

  ${mq('sm')} {
    width: 330px;
  }

  > img {
    padding-right: 9px;
  }
`;

const METHOD = [
  {
    type: 'Delivery',
    path: 'address/new-address',
    id: 1
  },
  {
    type: 'Pickup',
    path: 'pickup',
    id: 2
  }
]

const CoffeeHandle = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {ReactPixel.pageView()}, [])

  const changeHandler = (e) => {
    console.log('option checked', e.target.value);
    let method = METHOD[e.target.value-1];
    dispatch(setOrderType(method.type));
    history.push(method.path);
  };

  return (
    <Wrapper>
      <Header />
      <Title title='HOW WOULD YOU LIKE TO RECEIVE YOUR COFFEE?' />
      <Content>
        <StyledVerticalMenu displayCheckbox={false} onChange={changeHandler}>
          <StyledVerticalOption value={1}>
            <img src={Delivery} alt='selection-option' />
            I’d like it delivered
          </StyledVerticalOption>
          <StyledVerticalOption value={2}>
            <VerticalOptionOverride>
              <img src={Coffee} alt='selection-option' />
              I’d like to pick up at one of your stores
            </VerticalOptionOverride>
          </StyledVerticalOption>
        </StyledVerticalMenu>
      </Content>
      <Footer></Footer>
    </Wrapper>
  );
};

export default CoffeeHandle;
