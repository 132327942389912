import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Popconfirm } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer, getNextDelivery } from '../../actions/Order';
import { cancelSubscription, pauseSubscription, resumeSubscription } from '../../actions/Payments';
import { logout } from '../../auth0';
import { Header, ProfileFooter, TagItem, Wrapper } from '../../components';
import { mq } from '../../helpers/utils';
import { ArrowRight, CircleX, CreditcardAdd, Logout, PinAdd, Question } from '../../assets/icons';
import moment from "moment";
import { cleanGlobalStore } from "../../actions/User";

const pauseText = "All future delivery / pickup scheduled 1 working day after today will be paused. To resume the subscription, a lead time of 3 working days is required. Are you sure?"
const resumeText = "To resume the subscription, a lead time of 3 working days is required. Are you sure?"

const Container = styled.div`
  background-color: #ffffff;
`;

const TitleStyled = styled.div`
  padding: 132px 34px 30px 34px;
  font-size: 50px;
  line-height: 50px;
  white-space: pre-line;

  > br {
    display: none;
  }

  ${mq('lg')} {
    white-space: nowrap;
    padding: 132px 0 30px 0;
    font-size: 34px;
    line-height: 34px;

    > br {
      display: block;
    }
  }
`;

const Name = styled.span`
  overflow: wrap;
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: ${(props) => (props.suspended ? '1fr' : '1fr 1fr')};

  .next-delivery {
    background-color: var(--au-lait);
  }

  .plan-status {
    background-color: var(--aut-lait-bgd);
  }
`;

const Card = styled.div`
  height: 100%;
  > div > div{
    height: 100%;
    padding: 46px 34px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div:not(#cancelItem){
      padding-top: 70px;
    }
  }
  
  #cancelItem {
    width:50%
  }

  .large-title {
    line-height: 34px;
    white-space: pre-line;
  }

  ${mq('lg')} {
    > div > div {
      padding: 36px 0;
    }
  }
`;

const CTA = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;

  .cta-text {
    margin-right: 7px;
  }
`;

const ProfileMenu = styled.div`
  background-color: var(--bettr-yellow);
  padding: 45px 0 56px 0;
`;

const ProfilePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [planSuspended, setPlanSuspended] = useState(true);
  const [planProcessing, setPlanProcessing] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const baseLoginUrl = process.env.REACT_APP_QUIZ_URL ? process.env.REACT_APP_QUIZ_URL : "https://app.bettrcloudbar.com/quiz-intro"

  const userStore = useSelector((state) => state.userDetail)
  const userName = useSelector((state) => state.orderDetail.customer?.name);
  const planDetails = useSelector(
    (state) => state.orderDetail.order.nextDeliveryObj
  );
  const currentSubscription = useSelector(
    (state) => state.orderDetail.customer.subscriptions
  );
  const checkPlanSuspended = (subscription) => {
    let suspended = true;
    if (subscription.length > 0) {
      if (subscription[0].pause_collection === null) {
        suspended = false;
      } else {
        suspended =
          subscription[0].pause_collection.behavior.length > 0;
      }
    }
    return suspended;
  };

  const checkPlanProcessing = (suspended, nextDelivery) => {
    let processing = true;
    if (!suspended && nextDelivery.streetAddress !== undefined) {
      processing = false;
    }
    return processing;
  };

  const getCanceledMessage = () => {
    const cancelAt = userStore.user?.subscription?.cancel_at || currentSubscription?.data[0]?.cancel_at
    return `Cancelling subscription on ${moment.unix(cancelAt).format("DD MMM")}`
  }

  useEffect(() => {
    const getData = () => {
      dispatch(getCustomer());
      dispatch(getNextDelivery());
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    const suspended = checkPlanSuspended(currentSubscription.data);
    setPlanSuspended(suspended);
    setPlanProcessing(checkPlanProcessing(suspended, planDetails.nextDelivery));
  }, [currentSubscription, planDetails]);

  const pauseDeliveryHandler = async (suspended) => {
    setIsLoading(true)
    if (suspended) {
      await dispatch(resumeSubscription(currentSubscription.data[0].id));
      await dispatch(getNextDelivery());
      // history.push('/quiz-intro');
    } else {
      await dispatch(pauseSubscription(currentSubscription.data[0].id));
    }
    setIsLoading(false)
  };

  const manageSubscriptionHandler = () => {
    history.push('/manage/change-plan');
  };

  const { nextDelivery, remainingDeliveryCount } = planDetails;

  return (
    <Container>
      <Wrapper>
        <Header noBack />
        <TitleStyled className='large-title'>
          <Name>{`HEY ${userName}! `}</Name>
          <br />
          {`IT IS \nTIME FOR YOUR \nNEXT DRINK!`}
        </TitleStyled>
      </Wrapper>
      <Content suspended={planSuspended || planProcessing}>
        <Card className='next-delivery'>
          <Wrapper>
            <span>
              <p className='noto-body-15'>Your next drink</p>
              <div className='large-title'>
                {planSuspended
                  ? 'SUBSCRIPTION HAS BEEN PAUSED.'
                  : planProcessing
                    ? 'We are processing your next drink'
                    : `AT ${nextDelivery.streetAddress} \n${nextDelivery.day} ${nextDelivery.date
                    } \n${nextDelivery.time}`}
              </div>
              {
                userStore.user.subscription?.cancel_at_period_end && moment().isAfter(moment.unix(userStore.user.subscription?.current_period_end)) ?
                  <>
                    <span className='inter-body-16-sb cta-text'>
                      Subscribe now:
                    </span>
                    <a className='inter-body-16-sb cta-text' href={baseLoginUrl}>
                      {baseLoginUrl}
                    </a>
                  </>
                  : ""
              }
            </span>
            <CTA>
              {!planSuspended && planProcessing ? (
                <span className='inter-body-15-sb cta-text'>
                  Check back here in a minute!
                </span>
              ) : isLoading ?
                <span>
                  <span className='inter-body-15-sb cta-text'>
                    Loading&nbsp;
                  </span>
                  <img
                    src={planSuspended ? ArrowRight : CircleX}
                    alt='pause delivery'
                  />
                </span> : (
                  <span
                    style={{ cursor: 'pointer' }}
                  >
                    {(
                      <Popconfirm
                        title={planSuspended ? resumeText : pauseText}
                        onConfirm={async () => {
                          await pauseDeliveryHandler(planSuspended)
                        }
                        }
                        placement="top"
                        okText='Yes'
                        cancelText='No'
                      >
                        <span className='inter-body-15-sb cta-text'>
                          {planSuspended ? 'Resume my Subscription' : 'Pause my Subscription'}
                        </span>
                        <img
                          src={planSuspended ? ArrowRight : CircleX}
                          alt='pause delivery'
                        />
                      </Popconfirm>
                    )
                    }

                  </span>
                )}
            </CTA>
          </Wrapper>
        </Card>
        {!planSuspended && !planProcessing && (
          <Card className='plan-status'>
            <Wrapper style={{ height: '100%' }}>
              <span>
                <p className='noto-body-15'>Current plan status</p>
                <div className='large-title'>{`YOU STILL HAVE ${remainingDeliveryCount} \nDRINKS LEFT`}</div>
              </span>
              {
                userStore.user?.subscription?.cancel_at ?
                  <TagItem
                    id="cancelItem"
                    name={getCanceledMessage()}
                  /> : ""
              }
              <CTA>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={manageSubscriptionHandler}
                >
                  <span className='inter-body-15-sb cta-text'>
                    Manage my Subscription
                  </span>
                  <img src={ArrowRight} alt='manage subscription' />
                </span>
              </CTA>
            </Wrapper>
          </Card>
        )}
      </Content>
      <ProfileMenu>

        <ProfileFooter
          text='Cancel my Subscription'
          leftIcon={CircleX}
          popAction={!userStore.user?.subscription?.cancel_at ? () => {
            dispatch(
              cancelSubscription(currentSubscription.data[0].id)
            )
          } : null}
          popText={
            'The subscription will be canceled at the end of the current billing period. Are you sure?'}
          disactive={(userStore.user?.subscription?.cancel_at || currentSubscription.data.length === 0) ? true : false
          }
        />

        <ProfileFooter
          action={() => history.push('/address')}
          text='Manage my Address'
          leftIcon={PinAdd}
          disactive={false}
        />
        <ProfileFooter
          action={() => history.push('/payment-methods')}
          text='Payment Method'
          leftIcon={CreditcardAdd}
          disactive={false}

        />
        <ProfileFooter
          action={() => history.push('/feedback')}
          text='Leave Feedback'
          leftIcon={Question}
          disactive={false}

        />
        <ProfileFooter
          action={(e) => {
            dispatch(cleanGlobalStore())
            logout(e)
          }}
          text='Log Out'
          leftIcon={Logout}
          disactive={false}

        />
      </ProfileMenu>
    </Container >
  );
};

export default ProfilePage;
