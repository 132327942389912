import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DrinksMenu } from '../../components';
import { setCoffeesTypes } from '../../actions/Order';
import ReactPixel from "react-facebook-pixel";

const ChangeCoffee = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { coffeeTypes, favouriteCoffee } = useSelector((state) => state.coffee);
  const { preferredDrink, hotOrIced } = useSelector(
    (state) => state.quizInput.quiz
  );

  const handleContinue = () => history.push('/manage/change-plan');

  useEffect(() => {
    const getData = async () => {
      if (!favouriteCoffee || coffeeTypes.length < 1) {
        await dispatch(
          setCoffeesTypes({
            drinkType: preferredDrink || 'no-preference',
            coffeeTemperature: hotOrIced || 'no-preference'
          })
        );
      }
    };
    getData();
    //eslint-disable-next-line
  }, [dispatch, favouriteCoffee, preferredDrink, hotOrIced]);

  useEffect(() => {ReactPixel.pageView()}, [])

  return (
    <DrinksMenu
      coffeeTypes={coffeeTypes}
      favouriteCoffee={favouriteCoffee}
      onContinueClick={handleContinue}
    />
  );
};

export default ChangeCoffee;
