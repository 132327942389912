import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import validator from 'validator';
import { useHistory } from 'react-router-dom';

import '../assets/styles/styles.css';
import { Input, Header, FooterButton, Footer, Wrapper } from '../components';
import { login } from '../auth0';
import { _ErrorMessages as msg } from '../constants/ErrorMessages';
import { User, ArrowRight, ArrowLeft } from '../assets/icons';
import { mq } from '../helpers/utils';
import { useSelector } from "react-redux";

const LayoutStyled = styled('div')`
  display: grid;
  grid-template-areas:
    'header'
    'title'
    'content'
    'footer';
  grid-template-rows: auto auto 1fr auto;
  background-color: var(--au-lait);
  height: 100vh;
  width: 100%;
  row-gap: 40px;
`;

const ContentStyled = styled('div')`
  grid-area: content;
  padding: 0 33px;

  ${mq('lg')} {
    padding: 0;
  }
`;

const TitleStyled = styled('div')`
  grid-area: title;
  padding: 0 33px;
  line-height: 1;
  max-width: 360px;
  > br {
    display: block;
  }

  ${mq('lg')} {
    padding: 0;
    > br {
      display: none;
    }
  }
`;

const ForgotPasswordLink = styled('button')`
  border: none;
  color: #3f4444;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
`;

const Login = () => {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const [pwErrMsg, setPwErrMsg] = useState('');
  const history = useHistory();
  const user = useSelector(state => state.userDetail.user)

  const handleEmailChange = (e) => {
    setEmailErrMsg('');
    setLoginEmail(validator.trim(e.target.value));
  };
  const handlePasswordChange = (e) => {
    setPwErrMsg('');
    setLoginPassword(validator.trim(e.target.value));
  };

  useEffect(() => {
    if (user.subscription?.id) {
      history.push('/profile')
    }
    //eslint-disable-next-line
  }, [])

  const handleLogin = () => {
    setEmailErrMsg('');
    setPwErrMsg('');
    if (!loginEmail) {
      setEmailErrMsg(msg.required);
    }
    if (!loginPassword) {
      setPwErrMsg(msg.required);
    }
    if (loginEmail) {
      if (!validator.isEmail(loginEmail)) {
        setEmailErrMsg(msg.validEmail);
      } else {
        if (loginPassword) {
          login(loginEmail, loginPassword).catch((err) => {
            setEmailErrMsg(err.error_description);
            setPwErrMsg(err.error_description);
          });
        }
      }
    }
  };

  const handleCreateAccount = () => {
    console.log(history);
    history.push('/signup');
  };

  const handleForgotPasswordClick = () => {
    history.push('/forgot-password');
  };

  return (
    <>
      <Wrapper>
        <LayoutStyled>
          <Header
            headerMainClass='inter-body-15'
            text='Menu'
            icon={ArrowLeft}
          />
          <TitleStyled className='large-title'>
            {`Hello! please `}
            <br />
            {`enter your information `}
            <br />
            {`to login.`}
          </TitleStyled>
          <ContentStyled>
            <Input
              placeholder='Email address'
              type='email'
              name='email'
              id='email'
              label='Email address'
              onChange={handleEmailChange}
              errMsg={emailErrMsg}
            />
            <Input
              placeholder='Password'
              type='password'
              name='password'
              id='password'
              label='Password'
              onChange={handlePasswordChange}
              errMsg={pwErrMsg}
            />
            <ForgotPasswordLink onClick={handleForgotPasswordClick}>
              Forgot your password?
            </ForgotPasswordLink>
          </ContentStyled>
          <Footer>
            <FooterButton
              style={{ bottom: '100px' }}
              icon={ArrowRight}
              textRight='Login'
              actionRight={handleLogin}
            />
            <FooterButton
              style={{ backgroundColor: 'var(--aut-lait-bgd)' }}
              icon={User}
              textRight='Create an account'
              actionRight={handleCreateAccount}
            />
          </Footer>
        </LayoutStyled>
      </Wrapper>
    </>
  );
};

export default Login;
