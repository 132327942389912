import React from 'react';
import styled from '@emotion/styled';

import '../../assets/styles/styles.css';
import {
  StyledVerticalMultiSelect,
  StyledVerticalMultiSelectOption,
  Header,
  FooterButton,
  Footer,
  Title
} from '../../components';
import { ArrowRight } from '../../assets/icons';
import { mq } from '../../helpers/utils';

const CustomContent = styled('div')`
  padding: 0 33px 33px 33px;
  position: relative;
  z-index: 2;
  > div {
    text-align: center;
  }
  > div > div {
    text-align: left;
  }
  ${mq('lg')} {
    padding: 0 0 33px 0;
  }
`;

const CustomWrapper = styled('div')`
  padding-bottom: 100px;
  ${mq('lg')} {
    height: auto;
    overflow: visible;
    max-width: 404px;
    margin: 0 auto;
    padding-bottom: 150px;
  }
`;

const Error = styled('div')`
  color: #c23d4b;
`;

const DeliveryDay = ({
  order,
  titleText,
  onDaySelect,
  onContinueClick,
  disabled,
  backAction,
  errorMessage
}) => {
  return (
    <CustomWrapper>
      <Header backAction={backAction} />
      <Title title={titleText} />
      <CustomContent>
        <Error>{errorMessage}</Error>
        <StyledVerticalMultiSelect
          defaultValue={order.deliveryDaySelection}
          onChange={onDaySelect}
        >
          <StyledVerticalMultiSelectOption value={1}>
            Monday
          </StyledVerticalMultiSelectOption>
          <StyledVerticalMultiSelectOption value={2}>
            Tuesday
          </StyledVerticalMultiSelectOption>
          <StyledVerticalMultiSelectOption value={3}>
            Wednesday
          </StyledVerticalMultiSelectOption>
          <StyledVerticalMultiSelectOption value={4}>
            Thursday
          </StyledVerticalMultiSelectOption>
          <StyledVerticalMultiSelectOption value={5}>
            Friday
          </StyledVerticalMultiSelectOption>
        </StyledVerticalMultiSelect>
      </CustomContent>
      <Footer>
        <FooterButton
          disabled={disabled}
          icon={ArrowRight}
          textRight='Continue'
          actionRight={onContinueClick}
        />
      </Footer>
    </CustomWrapper>
  );
};

export default DeliveryDay;
