import React from 'react';

import { PageWithImage } from '../../components/';
import { PersonWithWarning } from '../../assets/images/';

const CannotChange = () => {
  return (
    <PageWithImage
      image={PersonWithWarning}
      title='Sorry, but you won’t be able to make changes to your subscription plans.'
      text='It might take up to 2 weeks until you start receiving the updates you made to your plan.'
      footer1Text='Go to my profile page'
    />
  );
};

export default CannotChange;
