import React from 'react';
import styled from '@emotion/styled';

import { mq } from '../../helpers/utils';

const Container = styled('div')`
  ${mq('lg')} {
    height: auto;
    overflow: visible;
    max-width: 404px;
    margin: 0 auto;
  }
`;

const Wrapper = ({ style, children }) => {
  return (
    <div style={style}>
      <Container>{children}</Container>
    </div>
  );
};

export default Wrapper;
