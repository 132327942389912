import React, {useState} from 'react';
import styled from '@emotion/styled';

import { mq } from '../helpers/utils';
import {
  Header,
  Footer,
  FooterButton,
  CodeInput,
  FaqList,
  Loader,
  OptionInfo,
  SummaryTable,
  Content,
  Wrapper,
  TagList
} from './';
import {
  Background,
  DrinkInfo,
  HorizontallyScrollableList,
  SelectedProduct,
  TextBox
} from '../pages/plan-recommendation';
import { ArrowRight } from '../assets/icons';

const CustomWrapper = styled('div')`
  max-width: 464px;
  margin: 0 auto;

  ${mq('lg')} {
    overflow: visible;
    max-width: 404px;
    margin: 0 auto;
  }
`;

const Plan = ({
  recommendation,
  summary,
  planFAQList,
  snacks,
  onContinueClick,
  onCoffeeChange,
  loading,
  promoTitle,
  promoText,
  promoCode,
  promoCodeAction,
  promoCodeRemoveAction,
  promoCodeError,
  promoCodeMessage,
  managePlan,
  firstMonth,
  totalMonthly,
                discount,
    nextMonth
}) => {


  const [isCoffeeChanges , toggleCoffeeChange] = useState(false);
  return loading ? (
    <Loader iconText='Just a minute! We are brewing a plan for you based on your preferences.' />
  ) : (
    <>
      <div style={{ backgroundColor: 'white', paddingBottom: '100px' }}>
        <CustomWrapper>
          <div style={{ position: 'relative', zIndex: '30' }}>
            <Header />
          </div>
          <Background />
        </CustomWrapper>
        <Content style={{ padding: '0 33px 0 33px' }}>
          <Wrapper
            style={{ height: 'auto', maxWidth: '404px', margin: '0 auto' }}
          >
            <SelectedProduct
              product={recommendation?.selectedCoffee}
              action={onCoffeeChange}
            />
            {recommendation?.selectedCoffee?.drinkInfo && (
              <DrinkInfo
                style={{ borderTop: 'none', padding: '39px 0 49px 0' }}
                drinkInfo={recommendation?.selectedCoffee?.drinkInfo}
                list={recommendation?.selectedCoffee?.flavors.split(', ')}
              />
            )}

            {recommendation?.products?.map((p) => (
              <div key={p?.productLine}>
                <OptionInfo
                  title={p?.productLine}
                  description={p?.description}
                />
                <HorizontallyScrollableList
                  productLine={p?.productLine}
                  list={p?.items}
                  firstMonth={firstMonth}
                  nextMonth={nextMonth}
                  removePromocodeAction={toggleCoffeeChange}
                />
              </div>
            ))}
            {snacks.list.length > 0 && (
              <>
                <div className='noto-body-15'>{snacks.type} of the Month</div>
                <TagList style={{ marginBottom: '55px' }} list={snacks.list} />
              </>
            )}
          </Wrapper>
        </Content>
        <Content
          style={{ backgroundColor: 'var(--au-lait)', paddingTop: '43px' }}
        >
          <Wrapper
            style={{ height: 'auto', maxWidth: '404px', margin: '0 auto' }}
          >
            <SummaryTable
              title='Order Summary'
              list={summary}
              summary
              firstMonth={firstMonth}
              discount={discount}
            />
            {promoCode && (
              <CodeInput
                button='Redeem'
                removeButton='Remove'
                removeAction={promoCodeRemoveAction}
                placeholder='Promo Code'
                action={promoCodeAction}
                error={promoCodeError}
                message={promoCodeMessage}
                firstMonth={firstMonth}
                total={totalMonthly}
                nextMonth={nextMonth}
                toggleCoffee={isCoffeeChanges}
                setCoffeeChanged={toggleCoffeeChange}
              />
            )}
            {promoText && <TextBox title={promoTitle} text={promoText} />}
          </Wrapper>
        </Content>
        <Content>
          <Wrapper
            style={{ height: 'auto', maxWidth: '404px', margin: '0 auto' }}
          >
            {
              planFAQList.map(faq => {
                    return <FaqList list={faq.value} title={faq.groupKey} />
                  }
              )
            }
          </Wrapper>
        </Content>
      </div>
      <Wrapper>
        <Footer>
          <FooterButton
            textLeft={managePlan ?? `$${totalMonthly} / month`}
            textRight='Continue'
            icon={ArrowRight}
            actionRight={onContinueClick}
          />
        </Footer>
      </Wrapper>
    </>
  );
};

export default Plan;
