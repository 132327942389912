import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRight } from '../../../assets/icons';
import {
  addressGotoNext,
  setAddressString,
  setStreetName,
  setUnitNumber
} from '../../../actions/Address';
import {
  Input,
  FooterButton,
  Footer,
  Title,
  Content
} from '../../../components';
import { mq } from '../../../helpers/utils';

const OverrideInput = styled.div`
  > div > input {
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      white-space: pre-line;
      position: relative;
      top: -14px;

      ${mq('sm')} {
        top: 0;
      }
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      white-space: pre-line;
      position: relative;
      top: -14px;

      ${mq('sm')} {
        top: 0;
      }
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      white-space: pre-line;
      position: relative;
      top: -14px;

      ${mq('sm')} {
        top: 0;
      }
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      white-space: pre-line;
      position: relative;
      top: -14px;

      ${mq('sm')} {
        top: 0;
      }
    }
  }
`;

const UnitNumberSection = (props) => {
  const dispatch = useDispatch();

  const address = useSelector((state) => state.addressDetail.address);

  useEffect(() => {
    fetch(
      `https://developers.onemap.sg/commonapi/search?searchVal=${address.postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log('result', result);
        let { ADDRESS, BLK_NO, ROAD_NAME } = result.results[0];
        dispatch(setAddressString(ADDRESS));
        dispatch(setStreetName(BLK_NO + ' ' + ROAD_NAME));
      });
  });

  const continueHandler = () => {
    let unitNo = address.unitNo.toUpperCase();
    let concatAddress = address.addressString;
    if (
      unitNo.length > 0 &&
      unitNo.charAt(0) !== '#' &&
      unitNo.toUpperCase() !== 'NA'
    ) {
      unitNo = '#' + unitNo;
    } else if (unitNo.toUpperCase() === 'NA') {
      unitNo = '';
    }
    concatAddress =
      unitNo.length > 0
        ? unitNo + ', ' + address.addressString
        : address.addressString;
    dispatch(setUnitNumber(unitNo));
    dispatch(setAddressString(concatAddress));
    dispatch(addressGotoNext());
  };

  return (
    <div>
      <Title
        className='large-title'
        title={`We see that you are currently at: ${address.addressString}.`}
      />
      <Title className='large-title' title={'Please enter your unit number'} />
      <Content>
        <OverrideInput>
          <Input
            label='Unit Number'
            placeholder='Unit Number (Input NA if not applicable)'
            onChange={(e) => {
              dispatch(setUnitNumber(e.target.value.trim()));
            }}
            value={address.unitNo}
            name={'Unit Number'}
            type='text'
          />
        </OverrideInput>
      </Content>

      <Footer>
        <FooterButton
          style={{ backgroundColor: 'var(--bettr-yellow' }}
          icon={ArrowRight}
          textRight='Continue'
          actionRight={continueHandler}
          disabled={!address.unitNo}
        />
      </Footer>
    </div>
  );
};

export default UnitNumberSection;
