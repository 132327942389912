import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { X } from '../assets/icons';
import { mq } from '../helpers/utils';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  bottom: 100px;
  background-color: ${(props) =>
    props.type === 'update' ? 'var(--green)' : 'var(--lightwine)'};
  padding: 30px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mq('lg')} {
    width: 404px !important;
  }
`;

const Text = styled.p`
  margin: 0 30px 0 10px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const Notification = ({ text, type = 'update', action }, ref) => {
  return (
    <Container ref={ref} type={type}>
      <Text className='noto-body-15'>{text}</Text>
      <Icon src={X} alt='close notification' onClick={action} />
    </Container>
  );
};

export default forwardRef(Notification);
