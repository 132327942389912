import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

const Tag = styled('div')`
  height: 25px;
  width: auto;
  padding: 6px 5.82px;
  background-color: var(--bettr-yellow);
  border-radius: 2px;
  margin-right: 7px;
`;

const TagItem = ({ name , id }, ref) => {
  return (
    <>
      <Tag id={id} className='inter-caption-11' ref={ref}>
        {name}
      </Tag>
    </>
  );
};

export default forwardRef(TagItem);
