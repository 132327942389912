import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import {
  quizSetHasSnackWithDrink,
  quizSetSnack,
  quizGotoNext
} from '../../actions/Quiz';
import {
  StyledVerticalOption,
  StyledVerticalMenu,
  StyledHorizontalMenu,
  Title,
  Content
} from '../../components';
import { Bread, Muffin, Cookies, Laughing } from '../../assets/icons';
import ReactPixel from "react-facebook-pixel";

const Snack = (props) => {
  const [hasSnack, setHasSnack] = useState(false);

  const dispatch = useDispatch();

  const hasRestrictionHandler = (e) => {
    dispatch(quizSetHasSnackWithDrink(e.target.value));
    if (e.target.value) {
      setHasSnack(e.target.value);
    } else {
      dispatch(quizGotoNext());
    }
  };

  React.useEffect(() => {ReactPixel.pageView()}, [])

  const setRestrictionHandler = (e) => {
    dispatch(quizSetSnack(e.target.value));
    dispatch(quizGotoNext());
  };

  return (
    <div>
      <Title
        title={
          hasSnack
            ? 'What kind of snack do you prefer?'
            : 'Would you like to enjoy your drink with a snack?'
        }
      />
      <Content style={{ marginTop: '20px' }}>
        {hasSnack ? (
          <StyledVerticalMenu
            displayCheckbox={false}
            onChange={setRestrictionHandler}
          >
            <StyledVerticalOption value={'Pastries'}>
              <img src={Bread} alt='selection-option' />
              Pastries
            </StyledVerticalOption>
            <StyledVerticalOption value={'Muffins'}>
              <img src={Muffin} alt='selection-option' />
              Muffins
            </StyledVerticalOption>
            <StyledVerticalOption value={'Cookies'}>
              <img src={Cookies} alt='selection-option' />
              Cookies
            </StyledVerticalOption>
            <StyledVerticalOption value={'Surprise me!'}>
              <img src={Laughing} alt='selection-option' />
              Surprise me!
            </StyledVerticalOption>
          </StyledVerticalMenu>
        ) : (
          <StyledHorizontalMenu
            displayCheckbox={false}
            onChange={hasRestrictionHandler}
          >
            <StyledVerticalOption value={true}>Yes</StyledVerticalOption>
            <StyledVerticalOption value={false}>No</StyledVerticalOption>
          </StyledHorizontalMenu>
        )}
      </Content>
    </div>
  );
};

export default Snack;
