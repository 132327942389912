import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import TagItem from './TagItem';

const Container = styled('div')`
  margin: 18px 0;
  display: flex;
  flex-wrap: wrap;
`;

const TagList = ({ list = ['tag1', 'tag2', 'tag3'], style }, ref) => {
  return (
    <Container style={style} ref={ref}>
      {list.map((i) => (
        <TagItem key={i} name={i} />
      ))}
    </Container>
  );
};

export default forwardRef(TagList);
