import { createReducer } from '@reduxjs/toolkit';

import { toggleLoading } from '../actions/Order';

const initialState = {
  loader: undefined
};

export default createReducer(initialState, {
  [toggleLoading](state, action) {
    state.loader = action.payload;
  }
});
