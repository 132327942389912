import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  margin: 20px 0;
  padding: 30px 39px 39px 39px;
  border-radius: 6px;
  background-color: white;
`;

const Text = styled('p')`
  line-height: 1.4;
`;

const Title = styled('h3')`
  line-height: 1;
  margin-bottom: 19px;
`;

const TextBox = ({ text = '[Text]', title, style }, ref) => {
  return (
    <Container style={style}>
      {title && <Title className='large-title'>{title}</Title>}
      <Text ref={ref} className='m-0 noto-caption-13'>
        {text}
      </Text>
    </Container>
  );
};

export default forwardRef(TextBox);
