import {Content, Footer, FooterButton, Header, Title, Wrapper} from '../index';
import {Select} from 'antd';
import {ArrowRight} from '../../assets/icons';
import React, {useState} from 'react';
import styled from '@emotion/styled';
import {isAmOrPm} from '../../helpers/utils';

const PickupTimeslot = ({titleText, pickupStore, onContinueClick}) => {
  const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 33% 33% 33%;
  `;

  const {Option} = Select;

  const operatingHours = () => {
    let listOfHours = {};
    let { start, end, breakTime } = pickupStore.operatingTimeSummary;
    for (let i = start; i <= end - 1; ++i) {
      listOfHours[convertToTwoDigits(i)] = ['00', '15', '30', '45'];
    }
    // refactor to use moment here
    listOfHours[breakTime.substring(0, 2)] = listOfHours[
      breakTime.substring(0, 2)
    ].filter(
        (el) =>
            el !== breakTime.substring(2) &&
            el !== parseInt(breakTime.substring(2)) + 15
    );
    return listOfHours;
  };

  //refactor - using moment.js
  const convertToTwoDigits = (value) => {
    if (value > 12) {
      value = value - 12;
    }

    value = value.toString();
    return value.length === 1 && parseInt(value) >= 8 ? '0' + value : value;
  };

  operatingHours();

  const [hour, setHour] = useState('08');
  const [minutesList] = useState(['00', '15', '30', '45']);
  const [minutes, setMinutes] = useState('00');
  const [amOrPm, setAmOrPm] = useState('AM');

  const handleHour = (value) => {
    console.log(value);
    setHour(value);
    setAmOrPm(isAmOrPm(value));
  };

  const handleMinutes = (value) => {
    setMinutes(value);
  };

  return (
    <Wrapper>
      <Header />
      <Title title={titleText} />
      <Content>
        <StyledContainer>
          <Select
            style={{ borderLeft: '2px solid black' }}
            size={'large'}
            defaultValue='8'
            bordered={false}
            value={hour}
            onChange={handleHour}
          >
            {['8','9','10','11','12','1', '2', '3', '4'].map((hour) => (
              <Option value={hour} key={hour}>
                {hour}
              </Option>
            ))}
          </Select>
          <Select
            style={{ borderLeft: '2px solid black' }}
            size={'large'}
            defaultValue='00'
            bordered={false}
            value={minutes}
            onChange={handleMinutes}
          >
            {minutesList.map((minutes) => (
              <Option value={minutes} key={minutes}>
                {minutes}
              </Option>
            ))}
          </Select>
          <Select
            style={{ borderLeft: '2px solid black' }}
            size={'large'}
            defaultValue='AM'
            bordered={false}
            value={amOrPm}
          >
            <Option value='amOrPm'>{amOrPm}</Option>
          </Select>
        </StyledContainer>
      </Content>
      <Footer>
        <FooterButton
          icon={ArrowRight}
          textRight='Continue'
          actionRight={() => {
            onContinueClick(hour, minutes);
          }}
        />
      </Footer>
    </Wrapper>
  );
};

export default PickupTimeslot;
