import React, { useState } from 'react';
import validator from 'validator';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  Title,
  Content,
  Wrapper,
  Header,
  Input,
  InputTextArea,
  Footer,
  FooterButton
} from '../../components';
import { Send } from '../../assets/icons';
import { _ErrorMessages as msg } from '../../constants/ErrorMessages';
import {validateMobileNumber, validatePostalCode} from '../../helpers/utils';
import { resetFeedbackTopic, submitFeedback } from '../../actions/Feedback';
import ReactPixel from "react-facebook-pixel";

const FeedbackDetails = (props) => {
  const dispatch = useDispatch();
  const { feedback } = useSelector((state) => state.feedbackDetail);
  console.log('FeedbackDetails ~ feedback', feedback);

  const [name, setName] = useState('');
  const [nameErrMsg, setNameErrMsg] = useState('');

  const [email, setEmail] = useState('');
  const [emailErrMsg, setEmailErrMsg] = useState('');

  const [postCode, setPostCode] = useState('');
  const [postErrMsg, setPostErrMsg] = useState('');

  const [mobileNumber , setMobileNumber] = useState('')
  const [mobileNumberErrMsg , setMobileNumberErrMsg] = useState('')

  const [feedBackMsg, setFeedBackMsg] = useState('');
  const [feedBackErrMsg, setFeedBackErrMsg] = useState('');

  const history = useHistory();

  let nameValid = false;
  let emailValid = false;
  let postValid = false;
  let messageValid = false;
  let phoneNumberValid = false;

  React.useEffect(() => {ReactPixel.pageView()}, [])

  const handleNameChange = (e) => {
    setNameErrMsg('');
    setName(e.target.value.trim());
  };

  const handleEmailChange = (e) => {
    setEmailErrMsg('');
    setEmail(validator.trim(e.target.value));
  };

  const handlePostCodeChange = (e) => {
    setPostErrMsg('');
    setPostCode(validator.trim(e.target.value));
  };
  const handleMessageChange = (e) => {
    setFeedBackErrMsg('');
    setFeedBackMsg(e.target.value.trim());
  };

  const handleMobilePhoneChange = (e) => {
    setMobileNumber(validator.trim(e.target.value))
  }

  const validationsHandler = async () => {
    setNameErrMsg('');
    setEmailErrMsg('');
    setPostErrMsg('');
    setFeedBackErrMsg('');

    name? nameValid = true : setNameErrMsg(msg.required)
    email? validator.isEmail(email) ?  emailValid = true :setEmailErrMsg(msg.validEmail) : setEmailErrMsg(msg.required)
    postCode? postValid = true : setPostErrMsg(msg.required)
    feedBackMsg? messageValid = true : setFeedBackErrMsg(msg.required)

    if (!validateMobileNumber(mobileNumber.replace(/ /g, ''))) {
      setMobileNumberErrMsg(msg.invalidPhoneNumber);
    } else {
      setMobileNumberErrMsg(null);
      phoneNumberValid = true
    }
    if (postCode) {
      if (postCode.length !== 6) {
        setPostErrMsg(msg.invalidPostalCode);
      } else {
        if (!(await validatePostalCode(postCode))) {
          setPostErrMsg(msg.invalidPostalCode);
        } else {
          setPostErrMsg(null);
          postValid = true;
        }
      }
    }

    if (nameValid && emailValid && postValid && messageValid && phoneNumberValid) {
      dispatch(
        submitFeedback({
          topic: feedback.topic,
          name,
          email,
          postalCode: postCode,
          message: feedBackMsg,
          phone: mobileNumber
        })
      );
      dispatch(resetFeedbackTopic());
      history.push('/feedback-thanks');
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: 'white',
          paddingBottom: '100px',
          height: '100vh'
        }}
      >
        <Wrapper>
          <Header />
          <Title
            title={`COULDN'T FIND THE PERFECT PLAN? LET US KNOW WHAT'S MISSING.`}
          />
          <Content>
            <Input
              placeholder='Your name'
              type='name'
              name='name'
              id='name'
              label='Your name'
              onChange={handleNameChange}
              errMsg={nameErrMsg}
            />
            <Input
              placeholder='Email'
              type='email'
              name='email'
              id='email'
              label='Email'
              onChange={handleEmailChange}
              errMsg={emailErrMsg}
            />
            <Input
                placeholder='Mobile phone'
                type='number'
                name='mobile'
                id='mobile'
                label='Mobile phone'
                onChange={handleMobilePhoneChange}
                errMsg={mobileNumberErrMsg}
            />
            <Input
              placeholder='Postal Code'
              type='postcode'
              name='postcode'
              id='postcode'
              label='Postal Code'
              onChange={handlePostCodeChange}
              errMsg={postErrMsg}
            />
            <InputTextArea
              placeholder='Your Message'
              type='yourmessage'
              name='yourmessage'
              id='yourmessage'
              label='Your Message'
              onChange={handleMessageChange}
              errMsg={feedBackErrMsg}
            />
          </Content>
        </Wrapper>
      </div>
      <Wrapper>
        <Footer>
          <FooterButton
            style={{ bottom: '0px', backgroundColor: 'var(--bettr-yellow )' }}
            icon={Send}
            textRight='Submit'
            actionRight={() => {
              validationsHandler();
            }}
            disabled={!feedBackMsg}
          />
        </Footer>
      </Wrapper>
    </>
  );
};

export default FeedbackDetails;
