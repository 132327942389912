import React, { forwardRef } from 'react';
import Header from './Header';

const Flow = (props, ref) => {
  const selectedSection = props.children[props.currentSection - 1];

  return (
    <div>
      <Header
        currentSection={props.currentSection}
        totalSections={props.children.length}
        backButtonAction={props.onBackButtonClick}
      />
      {selectedSection}
    </div>
  );
};

export default forwardRef(Flow);
