import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { signupGotoPrevious } from '../../actions/User';
import EmailSection from './EmailSection';
import NameSection from './NameSection';
import PasswordSection from './PasswordSection';
import PhoneNumberSection from './PhoneNumberSection';
import Flow from '../../components/onboarding/Flow';
import { Wrapper } from '../../components';

const Signup = () => {
  const dispatch = useDispatch();

  const currentSection = useSelector(
    (state) => state.userDetail.user.signupStage
  );

  return (
    <Wrapper>
      <Flow
        currentSection={currentSection}
        onBackButtonClick={() => {
          dispatch(signupGotoPrevious());
        }}
      >
        <NameSection />
        <EmailSection />
        <PhoneNumberSection />
        <PasswordSection />
      </Flow>
    </Wrapper>
  );
};

export default Signup;
