import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { OptionInfo } from '../../../components';
import {
  Coffee,
  Home,
  Pin,
  Laptop,
  PencilEdit,
  Presentation
} from '../../../assets/icons';

const getIcon = (addressDescription) => {
  switch (addressDescription) {
    case 'Home':
      return Home;
    case 'Work / Office':
      return Laptop;
    case 'My coffee spot':
      return Coffee;
    case 'Study island':
      return Presentation;
    default:
      return Pin;
  }
};

const StyledCard = styled.div`
  background-color: white;
  padding: 30px;
  display: ${(props) => (props.noEditPencil ? 'block' : 'grid')};
  grid-template-columns: 80% 20%;
  column-gap: 30px;
  margin-bottom: 10px;

  > div > div {
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  > div > p {
    margin-bottom: 0;
  }
`;

const AddressCard = (
  {
    addressDescription,
    addressString,
    noEditPencil = false,
    onClick = () => {},
    action = () => console.log('edit'),
    style
  },
  ref
) => {
  return (
    <StyledCard style={style} ref={ref} noEditPencil={noEditPencil}>
      <div onClick={() => onClick({ addressDescription, addressString })}>
        <OptionInfo
          icon={getIcon(addressDescription)}
          title={addressDescription}
          description={addressString}
        ></OptionInfo>
      </div>
      {noEditPencil ? (
        ''
      ) : (
        <img
          src={PencilEdit}
          alt='selection-option'
          onClick={action}
          style={{ cursor: 'pointer' }}
        />
      )}
    </StyledCard>
  );
};

export default forwardRef(AddressCard);
