import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePickupTimeSlot } from '../../../actions/Order';
import PickupTimeslot from '../../../components/delivery-options/PickupTimeslot';
import { useHistory } from 'react-router';

const SelectPickupTime = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pickupStore = useSelector(
    (state) =>
      state.addressDetail.pickupStores.filter(
        ({ addressDescription }) =>
          addressDescription === state.addressDetail.address.addressDescription
      )[0]
  );

  const handleChange = (hour, minutes) => {
    dispatch(changePickupTimeSlot({ hour: hour, time: minutes }));
    history.push('see-you-at-store');
  };
  return (
    <PickupTimeslot
      titleText={'Select your in-store pick up time'}
      pickupStore={pickupStore}
      onContinueClick={handleChange}
    />
  );
};

export default SelectPickupTime;
