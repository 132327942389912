import React from 'react';
import styled from '@emotion/styled';

import { Hot } from '../../assets/icons';
import { HotBgd } from '../../assets/images/';

const MainContainer = styled('div')`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: grid;
  align-content: center;
`;

const Container = styled('div')`
  margin: 0 auto;
  text-align: center;
`;

const Paragraph = styled('p')`
  max-width: 214px;
`;

const Icon = styled('img')`
  margin-bottom: 15px;
  height: 40px;
  width: 40px;
`;

const BgdImgContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  z-index: -1;
`;

const BgdImg = styled('img')`
  width: 324px;
  height: 324px;
  opacity: 1;
`;

const Loader = ({ bgdImg, bgdImgText, icon, iconText }) => {
  return (
    <MainContainer>
      <Container>
        <Icon src={icon || Hot} alt='hot' />
        <Paragraph className='inter-body-15-sb m-0'>{iconText}</Paragraph>
      </Container>
      <BgdImgContainer>
        <BgdImg src={bgdImg || HotBgd} alt={bgdImgText} />
      </BgdImgContainer>
    </MainContainer>
  );
};

export default Loader;
