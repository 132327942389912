import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
  margin-top: 30px;
`;

const IconContainer = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
`;

const Icon = styled('img')`
  margin-right: 6px;
  margin-bottom: 12px;
`;

const Text = styled('p')`
  line-height: 14.98px;
  margin-left: 13px;
  margin-right: 10px;
`;

const Disclaimer = ({ title = '[Title]', text = '[Text]', icon }, ref) => {
  return (
    <Container ref={ref}>
      <IconContainer>
        {icon && <Icon src={icon.img} alt={icon.alt || 'icon'} />}
        <h4 className='inter-body-15-sb'>{title}</h4>
      </IconContainer>
      <Text className='m-0 noto-caption-11'>{text}</Text>
    </Container>
  );
};

export default forwardRef(Disclaimer);
