import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import { mq } from '../helpers/utils';

const StyledTitle = styled('div')`
  grid-area: title;
  margin: 28px 35px 23px 34px;
  max-width: 306px;

  ${mq('lg')} {
    margin: 28px 0 23px 0;
    max-width: 404px;
  }
`;

const Title = ({ title, className, style }, ref) => {
  return (
    <StyledTitle
      style={style}
      className={`${className} large-title lh-1`}
      ref={ref}
    >
      {title}
    </StyledTitle>
  );
};

export default forwardRef(Title);
