/* eslint-disable eqeqeq */
import { createReducer } from '@reduxjs/toolkit';
import {
  addressGotoNext,
  addressGotoPrevious,
  addressResetGoto,
  setPostalCode,
  setUnitNumber,
  setAddressString,
  setAddressDescription,
  setStreetName,
  initAddress
} from '../actions/Address';

const initialState = {
  address: {
    postalCode: undefined,
    unitNo: undefined,
    addressString: undefined,
    addressDescription: undefined,
    address_stage: 1,
    streetName: undefined
  },
  pickupDays: [],
  pickupTime: '',
  pickupStores: [
    {
      addressDescription: 'Khoo Teck Puat Hopsital',
      addressString:
        'Tower C, Level 2\n(Above Information Counter)\n90 Yishun Central',
      postalCode: '768828',
      unitNo: '',
      streetName: '90 Yishun Central',
      operatingDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      operatingTimeSummary: {
        start: '08',
        end: '17',
        breakTime: '1100'
      }
    },
    // {
    //   addressDescription: 'Eu Yan Sang Head Office',
    //   addressString: 'Level 1 (Behind Reception Area)\n21 Tai Seng Drive',
    //   postalCode: '535223',
    //   unitNo: '',
    //   streetName: '21 Tai Seng Drive',
    //   operatingDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    //   operatingTimeSummary: {
    //     start: '08',
    //     end: '17',
    //     breakTime: '1100'
    //   }
    // }
    {
      addressDescription: 'Ministry of Social & Family Development',
      addressString: 'SLF Podium\n512 Thomson Road',
      postalCode: '298137',
      unitNo: '',
      streetName: '512 Thomson Road',
      operatingDays: ['Tuesday', 'Wednesday', 'Thursday'],
      operatingTimeSummary: {
        start: '08',
        end: '17',
        breakTime: '1100'
      }
    }
  ]
};

export default createReducer(initialState, {
  [addressGotoNext](state) {
    state.address.address_stage = state.address.address_stage + 1;
  },
  [addressGotoPrevious](state) {
    state.address.address_stage = state.address.address_stage - 1;
  },
  [addressResetGoto](state) {
    state.address.address_stage = initialState.address.address_stage;
  },
  [setPostalCode](state, action) {
    state.address.postalCode = action.payload;
    console.log('printing the postal code', state.address.postalCode);
  },
  [setAddressString](state, action) {
    state.address.addressString = action.payload;
    console.log('printing address string', state.address.addressString);
  },
  [setUnitNumber](state, action) {
    state.address.unitNo = action.payload;
    console.log('unit no', state.address.unitNo);
  },
  [setAddressDescription](state, action) {
    state.address.addressDescription = action.payload;
    console.log(
      'setting description to this ',
      state.address.addressDescription
    );
  },
  [setStreetName](state, action) {
    state.address.streetname = action.payload;
  },
  [initAddress](state, action) {
    state.address = { ...initialState.address };
    state.pickupDays = { ...initialState.pickupDays };
    state.pickupTime = { ...initialState.pickupTime };
  }
});
