import React from 'react';
import styled from '@emotion/styled';

import { FaqItem, TagList } from '../../components';

const MainContainer = styled('div')`
  margin-top: 28px;
`;

const ExpandedInfo = ({ drinkInfo, list }) => {
  return (
    <MainContainer>
      <div>{drinkInfo}</div>
      <br />
      <div>Tasting notes</div>
      <TagList list={list} />
    </MainContainer>
  );
};

const DrinkInfo = ({ drinkInfo, list, style }) => {
  return (
    <>
      <FaqItem
        style={style}
        iconLeft={null}
        question='Drink Information'
        answer={<ExpandedInfo drinkInfo={drinkInfo} list={list} />}
      />
    </>
  );
};

export default DrinkInfo;
