import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Plan } from '../../components';
import { getRecommendation, removeDiscount, setDefaults, toggleLoading } from '../../actions/Order';
import { getDiscount } from '../../actions/Payments';
import { getFaqList } from "../../actions/User";
import ReactPixel from "react-facebook-pixel";

const PlanRecommendation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader } = useSelector((state) => state.loader);
  const { favouriteCoffee } = useSelector((state) => state.coffee);
  const {
    drinkingFrequency,
    dietaryRestriction,
    snack: snackType
  } = useSelector((state) => state.quizInput.quiz);
  const { recommendation, summary, discount, snacks } = useSelector(
    (state) => state.orderDetail.order
  );
  const customerData = useSelector(
    (state) => state.orderDetail.customer.subscriptions.data
  );
  const discountAmount = discount.amount / 100;
  const discountPercent = discount.percent / 100;
  let subTotal;
  if (summary[summary.length - 1]?.raw) {
    subTotal = +summary[summary.length - 1]?.raw;
  }

  useEffect(() => {
    const getData = async () => {
      await dispatch(toggleLoading(true));
      await dispatch(getFaqList('planFAQList'));
      await dispatch(
        getRecommendation(
          favouriteCoffee,
          { frequency: drinkingFrequency || '20-drinks' },
          { firstMonth: true, delivery: false }
        )
      );

      await dispatch(
        setDefaults({ dietaryRestriction, snackType, firstMonth: true })
      );
      await dispatch(toggleLoading(false));
    };
    getData();
    ReactPixel.pageView()
    //eslint-disable-next-line
  }, []);

  const handleContinue = () => history.push('/coffee-handle');
  const handleCoffeeChange = () => history.push('/coffee-type');

  const faqList = useSelector((state) => state.userDetail.user.faqList);

  let total = subTotal;

  if (discountPercent === 0 && discountAmount === 0) {
    let price = summary.find(a => a.field === 'First Month' || a.field === 'Total Monthly');
    if (price !== undefined) {
      total = summary.find(a => a.field === 'First Month' || a.field === 'Total Monthly').value.replace('$', '');
    }
  } else {
    let find = summary.find(a => a.field === 'First Month' || a.field === 'Total Monthly');
    if (find !== undefined) {
      total = find.otherValue.replace('$', '');
    }
  }
  return (
    <Plan
      snacks={snacks}
      recommendation={recommendation}
      summary={summary}
      onCoffeeChange={handleCoffeeChange}
      planFAQList={faqList}
      onContinueClick={handleContinue}
      promoCode
      promoCodeAction={getDiscount}
      promoCodeRemoveAction={removeDiscount}
      promoTitle='enjoy 40% off'
      promoText={
        <>
          Subscribe now and enjoy 40% off your first month as part of our
          introductory offer. Enter <b>NEW40OFF</b> to redeem the offer.
        </>
      }
      promoCodeError={discount.error}
      promoCodeMessage={
        (discount.percent || discount.amount) && 'Coupon code applied'
      }
      loading={loader}
      backAction={handleCoffeeChange}
      firstMonth={!customerData.length}
      totalMonthly={total}
    />
  );
};

export default PlanRecommendation;
