import React from 'react';
import styled from '@emotion/styled';

import { Header, Content, FooterButton, Title, Footer } from '..';
import { Loader } from '..';
import { ArrowRight } from '../../assets/icons';
import CoffeeSectionItem from './CoffeeSectionItem';
import { mq } from '../../helpers/utils';

const CustomWrapper = styled('div')`
  max-width: 464px;
  margin: 0 auto;

  ${mq('lg')} {
    overflow: visible;
    max-width: 1039px;
    margin: 0 auto;
  }
`;

const DrinksMenu = ({
  coffeeTypes = [],
  favouriteCoffee,
  onContinueClick,
  redirectKey,
  showAll
}) => {
  return coffeeTypes.length ? (
    <div
      style={{ height: '100vh', overflowY: 'scroll', paddingBottom: '100px' }}
    >
      <Content
        style={{
          backgroundColor: 'white',
          padding: '0 0 48px 0'
        }}
      >
        <CustomWrapper>
          <Header />
          <Title
            title={'SELECT YOUR \nFAVOURITE DRINK'}
            style={{ marginBottom: '0', whiteSpace: 'pre-line' }}
          />
        </CustomWrapper>
      </Content>
      {coffeeTypes.map((cat, index) => (
        <CoffeeSectionItem
          key={cat.categoryName}
          {...cat}
          style={{
            backgroundColor:
              index % 2 === 0 ? 'var(--au-lait)' : 'var(--aut-lait-bgd)',
            zIndex: '0',
            padding: '30px 0'
          }}
        />
      ))}
      <Footer floatRight>
        <FooterButton
          disabled={!favouriteCoffee}
          textRight='Continue'
          textLeft={'Show Full Menu'}
          icon={ArrowRight}
          actionRight={onContinueClick}
          actionLeft={showAll}
          disabledLeft={redirectKey !== '' && redirectKey !== null}
        />
      </Footer>
    </div>
  ) : (
    <Loader iconText='Just a minute! We are brewing a plan for you based on your preferences.' />
  );
};

export default DrinksMenu;
