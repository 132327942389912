import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import { mq } from '../helpers/utils';

const FooterStyle = styled('div')`
  grid-area: footer;
  align-self: end;
  display: grid;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  z-index: 20;
  bottom: 0px;

  ${mq('lg')} {
    row-gap: 10px;
    margin-bottom: 40px;
    ${(props) => (props.floatRight ? 'max-width: 298px' : '')};
    ${(props) => (props.floatRight ? 'position: fixed;' : '')}
    ${(props) => (props.floatRight ? 'right: calc((100vw - 1039px)/2);' : '')}
    > div {
      ${(props) => (props.floatRight ? 'width: 298px' : 'width: 404px')}
    }
  }
`;

const Footer = (props, ref) => {
  return (
    <FooterStyle floatRight={props.floatRight} ref={ref}>
      {props.children}
    </FooterStyle>
  );
};

export default forwardRef(Footer);
