import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Input,
  Header,
  FooterButton,
  Footer,
  Title,
  Content,
  Wrapper
} from '../components';
import { _ErrorMessages as msg } from '../constants/ErrorMessages';
import { ArrowRight } from '../assets/icons';

const NewPassword = () => {
  const [loginPassword1, setNewPassword1] = useState('');
  const [loginPassword2, setNewPassword2] = useState('');
  const [emailErrMsg1, setEmailErrMsg1] = useState('');
  const [emailErrMsg2, setEmailErrMsg2] = useState('');
  const history = useHistory();

  const handlePassword1Change = (e) => {
    setEmailErrMsg1('');
    setNewPassword1(e.target.value);
  };

  const handlePassword2Change = (e) => {
    setEmailErrMsg2('');
    setNewPassword2(e.target.value);
  };

  const handleChangePasswordClick = (e) => {
    if (!loginPassword1) {
      setEmailErrMsg1(msg.required);
    } else if (!loginPassword2) {
      setEmailErrMsg2(msg.required);
    } else if (loginPassword1 !== loginPassword2) {
      setEmailErrMsg2(msg.needToMatch);
    } else {
      //TODO Webservice call
      history.push('/new-password-confirm');
    }
  };

  return (
    <>
      <Wrapper>
        <Header />
        <Title title='please enter and confirm your new password.' />
        <Content>
          <Input
            placeholder='Type your new password'
            type='password'
            name='password'
            id='password'
            label='Type your new password'
            onChange={handlePassword1Change}
            errMsg={emailErrMsg1}
          />
          <Input
            placeholder='Confirm your password'
            type='password'
            name='password'
            id='password'
            label='Confirm your password'
            onChange={handlePassword2Change}
            errMsg={emailErrMsg2}
          />
        </Content>
        <Footer>
          <FooterButton
            icon={ArrowRight}
            textRight='Confirm new password'
            actionRight={handleChangePasswordClick}
          />
        </Footer>
      </Wrapper>
    </>
  );
};

export default NewPassword;
