import React, { forwardRef } from 'react';
import { ArrowLeft } from '../../assets/icons';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import { Button } from 'antd';
import { mq } from '../../helpers/utils';

const PrimaryContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 25px 30px 0 30px;
  align-items: center;

  ${mq('lg')} {
    padding: 25px 0 0 0;
  }
`;

const StyledBackButton = styled(Button)`
  padding: 0;
  border: 0;
`;

const CurrentPageIndicator = styled.p`
  margin: auto 0;
  font-family: var(--inter);
  font-size: var(--body-15);
  user-select: none;
`;

const ProgressBar = styled.div`
  height: 3px;
  background-color: white;
  margin: auto 31px;

  &:after {
    content: '';
    display: block;
    background: #ffc200;
    width: ${(props) => props.progress}%;
    height: 100%;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }
`;

const Header = (props, ref) => {
  const history = useHistory();
  const { ...rest } = props;

  const handleBackAction = () =>
    props.currentSection === 1 ? history.goBack() : props.backButtonAction();

  return (
    <PrimaryContainer {...rest}>
      <div>
        <StyledBackButton
          type='link'
          onClick={
            props.currentSection === 1
              ? handleBackAction
              : props.backButtonAction
          }
        >
          <img alt='back-button' src={ArrowLeft} />
        </StyledBackButton>
      </div>
      <div>
        <ProgressBar
          progress={(props.currentSection / props.totalSections) * 100}
        />
      </div>
      <div>
        <CurrentPageIndicator>
          {props.currentSection}/{props.totalSections}
        </CurrentPageIndicator>
      </div>
    </PrimaryContainer>
  );
};

export default forwardRef(Header);
